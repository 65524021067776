import { Select, Alert, Form, Table, Input, InputRef, FormInstance, Button, Row, Col, Tooltip, Tag, Empty, notification } from "antd";
import { useState, useContext, useEffect, useRef } from "react";
import React from "react";
import { Approval, ApproverRole, BaseCategory, Category, CustomOptionProcessingStatus, CustomOptionType, Quote, QuoteStatus, Revision, RevisionApprovalStatus, RevisionStatus, RevisionType } from "../../api/models"
import { ConfiguratorContext } from "../../context";
import { ApprovalStatus } from "../../api/models";
import "./CustomOptionApproval.css";
import styles from "./CustomOptionApproval.module.css";
import Utils from "../../util/util";
import MobileCustomOptionApproval from "./MobileCustomOptionApproval";
import useCheckMobileScreen from "../../hook/useCheckMobileScreen";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import {CustomOptionRequest} from "../../api";

interface CustomOptionProps {
  approval: Approval | undefined,
  onSetErrorMessage: (message: string | null) => void,
  onSetCustomOptions: (customOptions: CustomOptionType[]) => void,
  isNonCustomOptionChangeOrderSteps: boolean
  disabled?: boolean;
  quote?: Quote;
}

interface EditableRowProps {
  index: number;
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof CustomOptionType;
  record: CustomOptionType;
  onSave: (record: CustomOptionType) => void;
}
  
const CustomOptionApproval = (props: CustomOptionProps) => {

  const {approval, onSetCustomOptions, onSetErrorMessage, disabled, quote, isNonCustomOptionChangeOrderSteps} = props;
  const [allCustomOptions, setAllCustomOptions] = useState<CustomOptionType[]>([]);
  const CustomOptionApprovalContext = React.createContext<FormInstance<any> | null>(null);
  const configurator = useContext(ConfiguratorContext);
  const isMobile = useCheckMobileScreen();
  const [maxKey, setMaxKey] = useState<number>(-1);
  const [categories, setCategories] = useState<BaseCategory[]>();
  const [invalidCategories, setInvalidCategories] = useState<Category[]>();

  const isEngineeringStep = ((approval?.approvalType === RevisionType.QUOTE_APPROVAL || approval?.approvalType === RevisionType.CHANGE_ORDER || approval?.approvalType === RevisionType.ORDER) 
  && approval.approverRole === ApproverRole.ENGINEERING) || (quote?.pendingSplitChange != undefined && quote.status === QuoteStatus.ORDER && approval?.approverRole === ApproverRole.ENGINEERING);

  const showCustomOptionContent = (isEngineeringStep || isNonCustomOptionChangeOrderSteps);

  const showCustomOptionTalbe = (isEngineeringStep || isNonCustomOptionChangeOrderSteps) && !!allCustomOptions.length;

  const canEditCustomOption = isEngineeringStep;

  useEffect(() => {
    if (approval && quote) {
      loadModelCategories();
      (isEngineeringStep || isNonCustomOptionChangeOrderSteps) ?
        loadCustomOptionsFromRevision() :
        loadCustomOptionsFromApproval(approval?.id);
    }
  }, [approval, quote]);


  useEffect(() => {
    if (quote) {
      getInvalidCat();
    }
  }, [allCustomOptions]);

  const sortAndResetCustomOptions = (customOptions: CustomOptionType[]) => {
    let coMap = {};
    allCustomOptions.forEach(co => {
      if (co.id) {
        coMap[co.id] = co;
      }
    })
    if (customOptions.length) {
      return customOptions.sort((a: CustomOptionType, b: CustomOptionType) => (b.customOptionOrder! - a.customOptionOrder!))
        .filter(co => ((isNonCustomOptionChangeOrderSteps && co.processingStatus !== CustomOptionProcessingStatus.PROCESS_COMPLETED) 
                       || isEngineeringStep))
        .map((c, i) => {
          // Do not refresh under review custom option when engineering custom option is submitted 
          const updatedCustomOption = (c.id && c.id in coMap && c.processingStatus === CustomOptionProcessingStatus.UNDER_REVIEW) ? coMap[c.id] : c;
          return {...updatedCustomOption, key: i}
        })
        .map(co => {
          return (!('approved' in co) || co.approved) ? {...co, approved: co.approved } : {...co, alreadyRejected: co.approved === false && !isEngineeringStep};
        });
    }
    else {
      return [];
    }
  }

  const loadModelCategories = async () : Promise<BaseCategory[] | undefined> => {

    if (!quote) return;

    try {
      const configuratorCategories = (await configurator.api.getConfiguratorCategories(quote.model.id, { quoteRevisionId: quote.displayRevisionId })).data;

      var categories = configuratorCategories
      .filter(cat => cat.name != "Default")
      .sort((a,b) => a.name.localeCompare(b.name));

      setCategories( categories );
      return categories;

    }
    catch(e:any) {

    }

  };

  const loadCustomOptionsFromApproval = async (approvalId: number) => {
    try {
      const resp = await configurator.api.getCustomOptionForApproval(approvalId);
      if (resp) {
        setAllCustomOptions(sortAndResetCustomOptions(resp.data));
        onSetCustomOptions(sortAndResetCustomOptions(resp.data));
        if (resp.data.length > 0) {
          onSetErrorMessage(null);
        }
      }
    }
    catch(e) {
      console.log(e);
      onSetErrorMessage("Failed to load custom options.");
    }
  }

  const loadCustomOptionsFromRevision = async () => {

    // Always find the latest revision
    const rev = quote?.revisions.filter(rev => rev.approvalStatus !== RevisionApprovalStatus.REJECTED).sort((a: Revision, b: Revision) => b.id - a.id )[0];

    if (!rev) return;

    try {
      const resp = await configurator.api.getCustomOptions(rev.id);
      setAllCustomOptions(sortAndResetCustomOptions(resp.data));
      onSetCustomOptions(sortAndResetCustomOptions(resp.data));
      if (resp.data.length > 0) {
        onSetErrorMessage(null);
      }
    }
    catch (e) {
      console.log(e);
      onSetErrorMessage("Failed to load custom options.");
    }
  };

  const onCreateApprovedCustomOptions = async () => {

    const quoteRevisionId = quote?.revisions.filter(rev => rev.approvalStatus !== RevisionApprovalStatus.REJECTED).sort((a: Revision, b: Revision) => b.id - a.id )[0].id;

    if (!quote || !approval || !quoteRevisionId) return;

    const newCustomOptions:CustomOptionRequest[] = allCustomOptions.filter(co => co.id === 0)
    .map( co => ({
      ...co,
      quoteRevisionId,
      categoryId: co.category?.id
    }));

    try {
      await Promise.all(
        newCustomOptions.map( co => configurator.api.createCustomOption(co, approval.id))
        );
      notification.success({ message: "New custom options are created and approved." });
      loadCustomOptionsFromRevision();
    }
    catch (e) {
      console.log(e);
    }
  }

  const getInvalidCat = async () => {
    try {
      if (quote) {
        const resp = await configurator.api.getInvalidCategoriesInQuoteRevision(quote.displayRevisionId);
        const effectiveCustomOptions = allCustomOptions.filter(co => co.processingStatus !== CustomOptionProcessingStatus.PROCESS_COMPLETED || (co.approved !== false));
        const invalidCategories = resp.data.filter(cat => !effectiveCustomOptions.map(co => co.category?.id).includes(cat.id));
        setInvalidCategories(invalidCategories);
      }
    }
    catch (e) {
      console.log(e);
    }
  }


  const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    onSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(CustomOptionApprovalContext)!;
  
    useEffect(() => {
      if (editing) {
        inputRef.current!.focus();
      }
    }, [editing]);
  
    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const convertTextInChild = (val: string): string => {
      let parts = val.split(',');
      if (parts.length > 1) {
        return parts.slice(1).join(',').trim();
      }
      else return parts[1];
    }

    let childNode = children;
  
    const save = async () => {
      try {
        const values = await form.validateFields();
        if (typeof record[dataIndex] === 'number') {
          values['price'] = parseFloat(values['price'])
        }
        toggleEdit();
        onSave({ ...record, ...values });
        const newCustomOptions = allCustomOptions.map(co => 
          co.key === record.key? { ...record, ...values } : co
        )
        setAllCustomOptions(newCustomOptions)
        onSetCustomOptions(newCustomOptions);
      } catch (errInfo) {
        console.log('Save failed:', errInfo);
      }
    };
  
    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            (typeof record[dataIndex] === 'number') ? 
            {
              required: true,
              message: `${title} is required.`,
            } : 
            {},
            (typeof record[dataIndex] === 'number') ? 
            {
              message: `${title} must be non-negative number with two digit after decimal.`,
              pattern: new RegExp(/^(\d*)\.?(\d{1,2})?$/)
            } : 
            {},
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div className={`${styles.content} ${(children?.toLocaleString() === ',') && styles.empty}`} onMouseEnter={toggleEdit}>
          <span style={{paddingBottom: "2px"}} >
          { typeof record[dataIndex] === 'number'? children : (children?.toLocaleString() === ',' ? `Please enter ${title} here..` : convertTextInChild(children?.toLocaleString() || ',')) }
          </span>
        </div>
      );
    }
  
    return <td {...restProps}>{childNode}</td>;
  };

  const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false} disabled={disabled}>
        <CustomOptionApprovalContext.Provider value={form}>
          <tr {...props} />
        </CustomOptionApprovalContext.Provider>
      </Form>
    );
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const onSelectOperaion = (_: any, record: any) => {
    const [key, value] = record['value'].split('_');
    const newCustomOptions = allCustomOptions.map(co => 
      ( co.key == value ? {...co, approved: (String(key) === 'APPROVE') } : co )
    )
    setAllCustomOptions(newCustomOptions);
    onSetCustomOptions(newCustomOptions);
  }

  const generateKey = () => {
    if (maxKey === -1) {
      let key = -1;
      allCustomOptions.forEach( option => {
        if (Number(option.key) > maxKey) {
          key = Number(option.key);
        }
      })
      setMaxKey(key + 1)
      return key + 1;
    }
    else {
      const newKey = maxKey;
      setMaxKey(maxKey + 1)
      return newKey + 1
    }
  }

  const sorting = (a: CustomOptionType, b: CustomOptionType) => {
    if (a.id! === 0 && b.id! !== 0) return -1;
    if (b.id! === 0 && a.id! !== 0) return 1;
    if (b.id! === 0 && a.id! === 0) return 0;
    if (a.customOptionOrder! > b.customOptionOrder!) return -1;
    if (a.customOptionOrder! < b.customOptionOrder!) return 1;
    return 0;
  }

  const addCustomOption = () => {
    const newData: CustomOptionType = {
      key: String(generateKey()),
      id: 0,
    };
    const newCustomOptions = [...allCustomOptions, newData].sort(sorting);
    setAllCustomOptions(newCustomOptions);
  };

  const onSelectCategory = (info: CustomOptionType, categoryId: number) => {
    const category = categories?.find( c => c.id === categoryId );
    const newCustomOptions = allCustomOptions.map(co => 
      ( co.key === info.key ? {...co, category: category } : co )
    )

    setAllCustomOptions(newCustomOptions);
    onSetCustomOptions(newCustomOptions);
  }
  
  type EditableTableProps = Parameters<typeof Table>[0];
  type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;
  
  const columns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string; })[] = [
    {
      title: 'Custom option content',
      dataIndex: 'content',
      editable: true,
      onCell: (record: CustomOptionType) => (
        canEditCustomOption && record.id === 0 ?
        {
        record,
        editable: true,
        dataIndex: 'content',
        title: 'Content',
        onSave,
        } : {}
      ),
    },
    {
      title: 'MSRP',
      dataIndex: 'price',
      editable: true,
      width: "10rem",
      render: (_, record: any, _index: any) => ( Utils.formatMoney(record?.price || 0.0) ),
      onCell: (record: CustomOptionType) => (
        canEditCustomOption ? 
        {
        record,
        editable: true,
        dataIndex: 'price',
        title: 'price',
        onSave,
        } : {}
      ),
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (_, record: any, _index: any) => 
        (
          record.id !== 0 &&
          ( record.alreadyRejected ? <p style={{marginLeft: '1.5rem', marginBottom: '-0.1rem'}}>REJECTED</p> :
            record.processingStatus !== CustomOptionProcessingStatus.PROCESS_COMPLETED && !isNonCustomOptionChangeOrderSteps ?
            <Select
              data-testid="operation-select"
              style={{ width: '8rem', marginLeft: '1rem' }}
              onSelect={(key, record) => onSelectOperaion(key, record)}
              value={(record.approved != null) ? (record.approved? 'APPROVE' : 'REJECT'):''}
            >
              {Object.values(ApprovalStatus)
                .map(decision => (
                  <Select.Option key={decision} value={decision + '_' + record.key}>
                    {decision}
                  </Select.Option>
                ))}
            </Select> :  <span></span>
          )
        )
    },
    {
      title: 'Category',
      dataIndex: 'category',
      width: "16rem",
      render: (_, record: any, _index: any) => 
      (
        // (record.id === 0) means notSaveAndSubmittedYet
        (record.id === 0 || isEngineeringStep) ?
        <Select
          style={{ width: "14rem", whiteSpace: "normal", wordBreak: "break-all"}}
          optionFilterProp="children"
          placeholder={<span  style={{fontStyle: "italic", color: "grey"}}>Select category</span>}
          value={record.category?.id}
          showSearch
          onSelect={(categoryId) => onSelectCategory(record, categoryId)}
        >
          {categories?.map((cat) => (
            <Select.Option key={cat.id} value={cat.id}>
              {Utils.stripSortingPrefix(cat.name)}
            </Select.Option>
          ))}
        </Select> 
        :
        <span>{Utils.stripSortingPrefix(record.categoryName)}</span>
      )
    },
    {
      title: 'Lead Time (wk.)',
      dataIndex: 'designAndProcurementWeeks',
      width: '7rem',
      render: (_, record: CustomOptionType, _index: any) => <span>{record.designAndProcurementWeeks}</span>
    },
    {
      title: 'Note',
      dataIndex: 'note',
      editable: true,
      width: "12rem",
      onCell: (record: CustomOptionType) => (
        canEditCustomOption ? 
        {
          record,
          editable: true,
          dataIndex: 'note',
          title: 'note',
          onSave,
        } : {}
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record: CustomOptionType, _index: any) => getAction(record)
    },
  ];

  const onSave = (row: CustomOptionType) : CustomOptionType[] => {
    const newData = [...allCustomOptions];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setAllCustomOptions(newData);
    return newData;
  };


  const getAction = (record: CustomOptionType) => {
    return (
      <>
        {record.id === 0 && <Button danger type='primary' shape="circle" icon={<MinusOutlined />} size='small' onClick={() => onRemove(record)}></Button>}
      </>
    )
  }

  const onRemove = (record: CustomOptionType) => {
    if (record.id === 0) {
      setAllCustomOptions(allCustomOptions.filter(option => option.key !== record.key));
    }
  }

  // if ( !allCustomOptions.length ) return <></>;

  if ( isMobile ) {
    return <>
    <MobileCustomOptionApproval
      allCustomOptions={allCustomOptions}
      canEditCustomOption={canEditCustomOption}
      saveCustomOption={(opt) => {
      onSetCustomOptions( onSave( opt ) )
      }}
    />
    </>
  }

  return <>
    {showCustomOptionContent &&
    <>
    {isEngineeringStep && !!invalidCategories?.length && <>
      <Row>
        <span style={{marginRight: "1rem", color: "#1677ff", fontWeight: "bold"}}>Missing Categories: </span>
        {invalidCategories?.map(cat => <Tag color="red"  key={cat.id + "-tag"}>{Utils.stripSortingPrefix(cat.name)}</Tag>)}
      </Row>
      </>
    }
    {isEngineeringStep &&
        <Row style={{fontWeight: 'lighter', marginBottom: ".5rem", marginTop: "1rem"}} gutter={20} justify={"space-between"}>
        <Col>
          {allCustomOptions.some(co => co.id === 0) &&
            <Tooltip title="Save custom option and approve it">
            <Button type="primary"
              onClick={() => onCreateApprovedCustomOptions()}
              disabled={allCustomOptions.filter(co => co.id === 0 && co.content && co.category?.id).length === 0}
            >
              Save new custom options
            </Button>
          </Tooltip>}
        </Col>

        <Col>
          <Tooltip title="Create custom option">
            <Button type="primary" shape="circle" icon={<PlusOutlined />} size='small'onClick={addCustomOption}></Button>
          </Tooltip>
        </Col>
      </Row>
    }
    {showCustomOptionTalbe &&
      <Table
        className="custom-options-approval-table"
        components={components}
        dataSource={allCustomOptions}
        columns={columns as ColumnTypes}
        pagination={{pageSize: 10}}
        locale={{
          emptyText: (
          <span>
            <p style={{color: "black"}}>
              There's no custom options here
            </p>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description=""/>
          </span>)
        }}
      />
    }
    {!disabled && !!allCustomOptions.length &&
      <Alert
        type="info"
        showIcon
        style={{marginBottom: "1rem"}}
        message={
          canEditCustomOption
            ? "Please enter price and note and review the custom options"
            : !!allCustomOptions.length ? "Please review the custom option. You can't modify the custom option detail."
              : "The custom option has been rejected by application engineer."
        }
      />
    }
    </>}
  </>


}

export default CustomOptionApproval;
