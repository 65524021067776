import { Affix, Badge, Col, Descriptions, Divider, Dropdown, Form, Modal, Row, Space, Tabs, Tooltip, notification, Alert, Checkbox, CheckboxProps } from "antd";
import { ConfiguratorContext, CustomOptionsContext, ModelCategoryContext, ModelCategoryContextType } from "../context";
import { AsyncState, useAsyncState } from "../hook/useAsyncState";
import { Assembly, AssemblyBase, BaseCategory, Category, CategoryIdAssembliesIdMap, CustomOptionType, Customer, DealerAdjustment,
  FRAME_SILL_LENGTH, IncentiveProgramInfo, Model, NeedVerifyDash, NonDiscountOption, Performance, Permission, PoNumber, PricingBreakdown, PricingOption, QUOTE_DEFAULTS, Quote,
  QuoteAssemblyException, QuoteShare, Revision, ShippingDestination, User, WorkflowStep, ApprovalAction, SyncStatus, EpicorSyncStatus, SalesTeam, AXIOS_CANCEL_MSG } from "../api/models";
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import useCheckMobileScreen from "../hook/useCheckMobileScreen";
import { NotFoundError } from "../api/errors";
import { NumberParam, useQueryParam } from "use-query-params";
import { useForm } from "antd/es/form/Form";
import { LockState, useQuoteLock } from "../hook/useQuoteLock";
import { useIntl } from "react-intl";
import { SelectedModelInfo } from "../components/Quote/ModelSelectionWizard";
import axios, { CancelTokenSource } from "axios";
import QuoteLock from "../components/Quote/QuoteLock";
import { debounce} from "lodash";
import AssemblySectionMenu from "../components/Quote/AssemblySectionMenu";
import Title from "antd/lib/typography/Title";
import dayjs, { Dayjs } from "dayjs";
import _ from "lodash";
import { WarningFilled, ExclamationCircleFilled, MoreOutlined } from "@ant-design/icons";
import QuoteInfoTab, { isQuotePricingValid } from "../components/Quote/QuoteInfoTab";
import QuoteHistoryTab from "../components/Quote/QuoteHistoryTab";
import Utils from "../util/util";
import { RequestedShipping } from "../components/Quote/CustomerShippingInfo";
import { EngineeringChangeSaveDto, SalesChangeOrderSaveDto, SalesTeamRequest, SaveDto } from "../api";
import Paragraph from "antd/lib/typography/Paragraph";
import RevisionSelector from "../components/Quote/RevisionSelector";
import { DescriptionsItemType } from "antd/lib/descriptions";
import QuoteTruckOptionsTab from "../components/Quote/QuoteTruckOptionsTab";
import QuotePerformanceTab from "../components/Quote/QuotePerformanceTab";
import QuoteDashTab from "../components/Quote/QuoteDashTab";
import QuoteExportsDropDown from "../components/Quote/QuoteExportsDropDown";
import BMButton from "../components/BMButton";
import SubmitQuoteButton from "../components/Quote/SubmitQuoteButton";
import SubmitOrderButton, { AbandonReviseQuoteButton, ReviseQuoteButton } from "../components/Quote/SubmitOrderButton";
import SubmitSalesChangeButton from "../components/Quote/SubmitSalesChangeButton";
import AbandonSalesChangeButton from "../components/Quote/AbandonSalesChangeButton";
import SubmitEngineeringChangeButton from "../components/Quote/SubmitEngineeringChangeButton";
import AbandonEngineeringChangeButton from "../components/Quote/AbandonEngineeringChangeButton";
import { MenuItemType, SubMenuType } from "antd/lib/menu/hooks/useItems";
import FixPricingButtonModal from "../components/Quote/FixPricingButtonModal";
import MoveTrucksButton from "../components/Quote/MoveTrucksButton";
import AssemblyExceptionButtonModal from "../components/Quote/AssemblyExceptionButtonModal";
import BomDetails from "../components/BomDetails";
import DiffRevisionModalButton from "../components/Quote/DiffRevisionModalButton";
import QuoteCopyModal from "../components/QuoteCopyModal";
import SplitOrder from "../components/Quote/SplitOrder";
import GenerateSerialButton from "../components/Quote/GenerateSerialButton";
import ArchiveQuoteSwitch from "../components/Quote/ArchiveButton";
import CancelOrderModule from "../components/Quote/CancelOrderModule";
import CreateEngineeringChangeButton from "../components/Quote/CreateEngineeringChangeOrderButton";
import CreateSalesChangeButton from "../components/Quote/CreateSalesChangeOrderButton";
import {ValidateFields, ValidateErrorEntity} from "rc-field-form/lib/interface";
import UnsavedChangesWarning from "../hook/unsaved_changes_warning";
import PriceViewSwitch from "../components/Quote/PriceViewSwitch";
import { SegmentedValue } from "antd/es/segmented";
import WorkflowProgress from "../components/WorkflowProgress"
import CustomOptionsButtonModal from "../components/Quote/CustomOptionsButtonModal";
import CreatePriceProtectedSalesChangeOrderButton from "../components/Quote/CreatePriceProtectedSalesChangeOrderButton";
import ShareQuoteButtonModal from "../components/Quote/ShareQuoteButtonModal";
import QuoteContextProvider, { useQuoteContext } from "../contexts/QuoteContext";
import QuoteFormContextProvider from "../contexts/QuoteFormContext";
import ConvertReservationButton from "../components/Quote/ConvertReservationButton";
import QuoteAuditView from "../components/Quote/QuoteAuditView";
import UndoQuoteModalButton from "../components/Quote/UndoQuoteModalButton";
import QuoteTrucksButtonModal from "../components/Quote/QuoteTrucksButtonModal";


const NOT_FOUND_MSG = "Quote not found"
export const FORM_PERCENT_DISCOUNT = 'percentDiscount';

export const QUOTE_INFO_PANEL_KEY = "quote-info"
export const PERFORMANCE_PANEL_KEY = "performance-details"
export const DASH_PANEL_KEY = "dash-drawing"
export const HISTORY_PANEL_KEY = "history"
export const ASSEMBLY_PANEL_KEY = "assembly"

const SPEC_ONLY_VIEW = "Spec Only";
const SALES_PRICE_VIEW = "Sales";
const ADMIN_PRICE_VIEW = "Admin";

export interface QuoteAssemblyExceptionContextType {
  quoteAssemblyExceptionLstAsync?: AsyncState<QuoteAssemblyException[]>
  loadQuoteAssemblyExceptions?:(q:string|undefined)=>Promise<QuoteAssemblyException[] | undefined>
}
export const QuoteAssemblyExceptionContext = createContext<QuoteAssemblyExceptionContextType>({});

export interface FormValues {
  copyName?: string
  quoteName: string
  endCustomer: Customer | undefined
  percentDiscount: number
  quantity: number
  shippingDestination: ShippingDestination | undefined
  quoteNotes: string | undefined
  salesRequests: string | undefined
  poNumber: PoNumber | undefined
  modelInfo: SelectedModelInfo | undefined
  gvwrCap: number | undefined
  shippingDate: Dayjs | undefined
  productionDate: Dayjs | undefined
  bmSalesReps: User[] | undefined
  engineer: User | undefined
  incentivePrograms: IncentiveProgramInfo[] | undefined
  allCustomOptions:CustomOptionType[] | undefined
  salesTeam: SalesTeam | undefined
}

interface ComputePricingArgs {
  modelId:number
  options?: CategoryIdAssembliesIdMap
  pricingSnapshotId?: number
  percentDiscount?: number
  shippingDestinationId?: number
  dealerAdjustments?: DealerAdjustment[]
  nonDiscountOptions?: NonDiscountOption[]
  customOptions?: number[]
  incentivePrograms?: string[]
  quoteRevisionId?: number
}

export interface CheckDirtyProps {
  formValues?: FormValues
  selectedAssemblies?: CategoryIdAssembliesIdMap
  selectedCustomOptions?: CustomOptionType[]
  dealerAdjustments?: DealerAdjustment[]
  nonDiscountOptions?: NonDiscountOption[]
}

export function isAssembly(option: AssemblyBase | CustomOptionType | undefined) : boolean {
  if ( !option ) return false;
  return 'bom' in option;
}

const Configurator = () => {

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const history = useHistory();

  const isAdminOrEngineering = configurator.isAdmin() || configurator.isEngineering();
  const isEngineering = configurator.isEngineering();
  const isAdmin = configurator.isAdmin();
  const isSalesDesk = configurator.isSalesDesk();
  const isDealer = configurator.isDealerSales();
  const isFinance = configurator.isFinance();
  const isSales = configurator.isInternalSales();

  const [quoteForm] = useForm();

  const params = useParams<Record<string, string>>();
  const [revisionParam, setRevisionParam] = useQueryParam<number | undefined | null >("rev", NumberParam);
  const [tabKey, setTabKey] = useState<string>(QUOTE_INFO_PANEL_KEY);

  const selectedModel = Form.useWatch<SelectedModelInfo | undefined>('modelInfo', quoteForm);

  const isMobile = useCheckMobileScreen();
  const layoutStyle = isMobile ? { display: "flex" } : {};

  const [quote, quoteAsync] = useAsyncState<Quote>();
  const [_previewQuote, previewQuoteAsync] = useAsyncState<Quote>();
  const [workflow, workflowAsync] = useAsyncState<WorkflowStep[]>();
  const [computedPricingDetails, computedPricingDetailsAsync] = useAsyncState<PricingBreakdown>();
  const [performance, performanceAsync] = useAsyncState<Performance>();
  const [modelCategories, modelCategoriesAsync] = useAsyncState<BaseCategory[]>([]);
  const [computedValid, computedValidAsync] = useAsyncState<CategoryIdAssembliesIdMap>();
  const [quotePricingDetails, quotePricingDetailsAsync] = useAsyncState<PricingBreakdown>();
  const [_computedSelections, computedSelectionsAsync] = useAsyncState<CategoryIdAssembliesIdMap>();
  const [quoteAssemblyExceptionLst, quoteAssemblyExceptionLstAsync] = useAsyncState<QuoteAssemblyException[]>();
  const [epicorSyncStatus, epicorSyncStatusAsync] = useAsyncState<SyncStatus>();
  const [truckGvwr, truckGvwrAsync] = useAsyncState<number>();
  const [hidePrice, setHidePrice] = useState(false);  // Price hide to user ot not
  
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const [selectedOptions, setSelectedOptions] = useState<CategoryIdAssembliesIdMap>();
  const [selectedCustomOptions, setSelectedCustomOptions] = useState<CustomOptionType[] | undefined>();

  const [adminView, setAdminView] = useState<boolean>(isAdminOrEngineering); // AdminView is only for price detail to users
  const [formDirty, setFormDirty] = useState<boolean>(false);
  const [dealerAdjustments, setDealerAdjustments] = useState<DealerAdjustment[]>([]);
  const [nonDiscountOptions, setNonDiscountOptions] = useState<NonDiscountOption[]>([]);
  const [optionNotes, setOptionNotes] = useState<Record<string, string>>({});
  const [filterOptionsQuery, setFilterOptionsQuery] = useState<string>();
  const [disableAutoSelect, setDisableAutoselect] = useState<boolean>(false);
  const [needVerifyDash, setNeedVerifyDash] = useState<NeedVerifyDash>();
  const [truckDescription, setTruckDescription] = useState<string>(quote?.truckDescription || '');
  const [validationAlert, setValidationAlert] = useState<string[]>([]);

  const cancelComputeValidTokenSourceRef = useRef<CancelTokenSource>();
  const cancelComputePricingTokenSourceRef = useRef<CancelTokenSource>();
  const cancelComputedSelectionsTokenSourceRef = useRef<CancelTokenSource>();
  const cancelModelCategoryTokenSourceRef = useRef<CancelTokenSource>();

  const isNewQuote = !quote && quoteAsync.isDone();
  const [lockState, userActivity, retryLock] = useQuoteLock(quoteAsync, isNewQuote, formDirty);

  const selectedRevisionId = quote?.displayRevisionId || 0;
  const statusMessages = [];
  const isCurrentRevision = isNewQuote || Number(quote?.currentRevisionId) === Number(selectedRevisionId);
  const cancelLoadQuoteTokenSourceRef = useRef<CancelTokenSource>();

  const isLocked = [ LockState.LOCKED, LockState.EXCEEDED ].includes(lockState);

  interface ModelCategoryRequestOptions { 
    quoteRevisionId?:number, 
    assemblyFilter?: string, 
    dealerView?: boolean 
  }
  const loadModelCategories = useCallback(debounce( async ( modelCategoriesAsync:AsyncState<BaseCategory[]>, modelId?:number, options?:ModelCategoryRequestOptions) : Promise<BaseCategory[] | undefined> => {
    const id = modelId;
    if (!id) return;

    if ( cancelModelCategoryTokenSourceRef.current ) {
      cancelModelCategoryTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelModelCategoryTokenSourceRef.current = cancelSource;

    const opt = {
      ...options,
    }

    try {
      modelCategoriesAsync.setLoading();
      const resp = await configurator.api.getConfiguratorCategories(id, opt )
      cancelModelCategoryTokenSourceRef.current = undefined;

      const configuratorCategories = resp.data;

      var categories = configuratorCategories
      .filter(cat => cat.name != "Default")
      .sort((a,b) => a.name.localeCompare(b.name));

      modelCategoriesAsync.setDone( categories );
      return categories;

    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        modelCategoriesAsync.setFail( errorMsg );
      }
    }

    return;
  }, 750), []);

  const loadQuote = useCallback(debounce( async (quoteAsync:AsyncState<Quote>, quoteId:string, revision:number | undefined) : Promise<Quote | undefined> => {
    if ( !quoteId ) return;

    if ( cancelLoadQuoteTokenSourceRef.current ) {
      cancelLoadQuoteTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelLoadQuoteTokenSourceRef.current = cancelSource;


    try {
      quoteAsync.setLoading();

      const resp = await configurator.api.getQuoteByRevision(quoteId, revision);
      cancelLoadQuoteTokenSourceRef.current = undefined;

      const quote = resp.data;
      quoteAsync.setDone(quote);

      return quote;
    } catch (e:any) {
      if (e instanceof NotFoundError) {
        quoteAsync.setFail( NOT_FOUND_MSG );
      }
      else {
        const id = e.response?.data?.message || e.message ;
        if ( id !== AXIOS_CANCEL_MSG ) {
          const errorMsg = intl.formatMessage({ id });
          notification.error( { message: "Failed to get quote details. " + errorMsg, duration: 500 });
          quoteAsync.setFail(errorMsg);
        }
      }
    }

    return;
  }, 750, {leading:true}), []);

  const reloadQuote = async (quoteParam?:Quote) :Promise<Quote | undefined> => {

    //bail if no quote id
    const quoteId = params.quoteId;
    if ( !quoteId ) return;

    const revision = revisionParam || undefined;

    //skip reloading the quote object if provided
    const quote = quoteParam || await loadQuote(quoteAsync, quoteId, revision);
    computedPricingDetailsAsync.setInit();

    setSelectedOptions(quote?.selections);

    setOptionNotes(quote?.selectionNotes || {});

    await reloadModelCategories( quote?.model.id, { 
      quoteRevisionId: quote?.displayRevisionId 
    } )

    if ( !quote ) return;

    setDealerAdjustments(quote.dealerAdjustmentList);
    setNonDiscountOptions(quote.nonDiscountOptionList);

    const selections = Object.values(quote.selections || {}).flat()

    const [ customOptionLst ] = await Promise.all([
      loadCustomOptions( quote?.displayRevisionId, selections ),
      loadFullPricingBreakdown( quote.quoteId, revisionParam || undefined ),
      loadPerformance(),
      loadQuoteAssemblyExceptions(quoteId),
      loadEpicorSyncStatus(quoteId)
    ]);

    const selectedCustomOptions = customOptionLst?.filter( co => co.included );
    setSelectedCustomOptions( selectedCustomOptions );

    if ( quote?.model.id ) {
      computeValid( computedValidAsync, quote?.model.id, {
        selections: Object.values( quote?.selections || {} ).flat(),
        customOptions: selectedCustomOptions?.map(co => co.id ) as number[] | undefined,
        quoteRevisionId: quote?.displayRevisionId 
      });
    }

    setFormDirty(false);

    //wait for a render
    setTimeout( () => quoteForm.resetFields(), 100 );

    return quote;
  }

  const [_customOptionLst, customOptionLstAsync] = useAsyncState<CustomOptionType[]>();

  //note: selections is required for pricing
  const loadCustomOptions = async (quoteRevisionId:number | undefined, selections:number[]) : Promise<CustomOptionType[] | undefined> => {
    if ( !quoteRevisionId ) return;

    customOptionLstAsync.setLoading()
    try {
      const resp = await configurator.api.getCustomOptions(quoteRevisionId, selections)
      customOptionLstAsync.setDone(resp.data);
    
      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to fetch custom options. " + errorMsg;
      notification.error( { message: msg });
      customOptionLstAsync.setFail(msg);
    }

    return;
  }
  const reloadCustomOptions = async (): Promise<CustomOptionType[] | undefined> => {
    const lst = await loadCustomOptions(quote?.displayRevisionId, Object.values(selectedOptions || {}).flat() )

    const selectedLst = lst?.filter(co => co.included );
    setSelectedCustomOptions( selectedLst );

    const customOptions = selectedLst?.map(co => co.id ) as number[] | undefined

    recomputePricing( {
      customOptions
    });

    return lst;
  }

  const customOptionsContext = useMemo(() => ({
    customOptionLstAsync, loadCustomOptions: reloadCustomOptions,
  }), [customOptionLstAsync, selectedCustomOptions, quote ]);

  useEffect(() => {
    //reload if locked switch, it's already been loaded once, and notcurrently loading
    const isUnlocked = (lockState === LockState.UNLOCKED);
    const isLoaded = (quoteAsync.isDone() || quoteAsync.isFail());
    if (isUnlocked && isLoaded) {
      reloadQuote();
    }
  }, [isLocked]);

  const verifyDashStyle = async () => {
    try {
      const resp = await configurator.api.verifyDashStyle(quote?.displayRevisionId || 0);
      setNeedVerifyDash(resp.data);
    }
    catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if ( quoteAsync.isDone() ) {
      setValidationAlert([]);
      if (quote?.displayRevisionId) {
        verifyDashStyle();
      }
      if (quote) {
        loadApprovalWorkflow();
      }
      quoteForm.resetFields();
    }
  }, [quote]);

  useEffect( () => {
    reloadQuote();
  }, [ params.quoteId, revisionParam ]);

  useEffect(() => {
    userActivity();

    reloadModelCategories();
  }, [filterOptionsQuery]);

  useEffect(() => {
    reloadModelCategories();
  }, [adminView])

  useEffect(() => {
    updateTruckGvwr(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {

    //reload if locked switch, it's already been loaded once, and notcurrently loading
    const isUnlocked = (lockState === LockState.UNLOCKED);
    const isLoaded = (quoteAsync.isDone() || quoteAsync.isFail());
    if (isUnlocked && isLoaded) {
      reloadQuote();
    }
  }, [isLocked]);

  useEffect(() => {
    if (quote && selectedModel) {
      updateDirty();
    }
  }, [
      selectedCustomOptions?.filter(co => co.included).map(co => co.id ).join("-"),
      Object.values( selectedOptions || {} ).join("-"),
    ]);
 
  const reloadModelCategories = async (modelId?:number, options?:ModelCategoryRequestOptions) : Promise<BaseCategory[] | undefined> => {
    const id = modelId || selectedModel?.modelInfo.id;
    const opts = {
      quoteRevisionId: quote?.displayRevisionId,
      assemblyFilter:filterOptionsQuery, 
      dealerView: !adminView,
      ...options,
    }
    return await loadModelCategories(modelCategoriesAsync, id, opts);
  }
  const modelCategoryContext = useMemo<ModelCategoryContextType>( () => ({
    modelCategoriesAsync, loadModelCategories: reloadModelCategories
  }) , [ modelCategories ]);

  const updateDirty = (props?:CheckDirtyProps) => {
    userActivity();
    setFormDirty( checkDirty(props) );
  }

  const buildDefaultComputePricingArgs = () : ComputePricingArgs | undefined => {
    const values = getFormValues();

    const selectedModelInfo = values.modelInfo;
    const modelId = selectedModelInfo?.modelInfo.id;
    if ( !modelId ) return;

    const customOptions = selectedCustomOptions?.map(co => co.id ) as number[] | undefined;

    return {
      modelId,
      options: selectedOptions,
      pricingSnapshotId: quote?.pricingConfig?.pricingSnapshot?.id,
      percentDiscount: values.percentDiscount,
      shippingDestinationId: values.shippingDestination?.id,
      dealerAdjustments: dealerAdjustments,
      nonDiscountOptions: nonDiscountOptions,
      customOptions,
      incentivePrograms:values.incentivePrograms?.map(ip => ip.id ),
      quoteRevisionId: selectedRevisionId
    }
  }

  const recomputePricing = async (args?:Omit<ComputePricingArgs, 'modelId'> & { modelId?:number | undefined }) : Promise<PricingBreakdown | undefined> => {
    const defaultArgs =  buildDefaultComputePricingArgs();
    if (!defaultArgs ) return;

    return computePricing(
    computedPricingDetailsAsync,
    {
      ...defaultArgs,
      ...args
    });
  }

  const computeValid = useCallback(debounce( async ( computedValidAsync:AsyncState<CategoryIdAssembliesIdMap>, modelId:number, options?:{ selections?:number[], customOptions?:number[], quoteRevisionId?:number } ) : Promise<CategoryIdAssembliesIdMap | undefined> => {

    if ( cancelComputeValidTokenSourceRef.current ) {
      cancelComputeValidTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelComputeValidTokenSourceRef.current = cancelSource;

    try {

      computedValidAsync.setLoading()
      const resp = await configurator.api.computeValid(modelId, options, cancelSource.token );
      cancelComputeValidTokenSourceRef.current = undefined;

      computedValidAsync.setDone( resp.data.selections );
      return resp.data.selections;
    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Failed to load compute valid. " + errorMsg });
        computedValidAsync.setFail(e.message);
      }
    }

    return {};
  }, 750), []);


  const computePricing = useCallback( debounce(async (computedPricingDetailsAsync:AsyncState<PricingBreakdown> , args: ComputePricingArgs): Promise<PricingBreakdown | undefined> => {
    //bail if model isn't seleted
    if (!args.modelId) return;

    if ( cancelComputePricingTokenSourceRef.current ) {
      cancelComputePricingTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelComputePricingTokenSourceRef.current = cancelSource;

    const req = {
      ...args,
      currentSelections: Object.values(args.options || {}).flat()
    }

    try {
      computedPricingDetailsAsync.setLoading();
      const resp = await configurator.api.computePricing(args.modelId, req, cancelSource.token );
      cancelComputeValidTokenSourceRef.current = undefined;

      computedPricingDetailsAsync.setDone( resp.data );
      return resp.data;
    } catch (e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Failed to compute pricing. " + errorMsg });
        computedPricingDetailsAsync.setFail(e.message);
      }
    }

  }, 750, {leading: true}), []);

  useEffect(() => {
    if (selectedCategory) {
      setTabKey(ASSEMBLY_PANEL_KEY);
    }
  }, [selectedCategory]);

  const loadEpicorSyncStatus = async (quoteId:string | undefined) : Promise<SyncStatus | undefined> => {
    if (!(configurator.isAdmin() || configurator.isEngineering())) return;
    if ( !quoteId ) return;

    epicorSyncStatusAsync.setLoading()

    try {
      const resp = await configurator.api.epicorSyncStatus({
        page: 0, size: 1, filter: quoteId
      });
      const syncStatus = resp.data.content?.[0];
      epicorSyncStatusAsync.setDone(syncStatus)
      return syncStatus;
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to load epicor sync status. " + errorMsg });
      epicorSyncStatusAsync.setFail(e.message);
    };

    return;
  }

  const loadQuoteAssemblyExceptions = async (quoteId:string | undefined) : Promise<QuoteAssemblyException[] | undefined> => {
    if ( !quoteId ) return;

    quoteAssemblyExceptionLstAsync.setLoading()

    try {
      const resp = await configurator.api.fetchQuoteAssemblyExceptions(quoteId);
      quoteAssemblyExceptionLstAsync.setDone(resp.data)
      return resp.data;
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to load assembly exceptions. " + errorMsg });
      quoteAssemblyExceptionLstAsync.setFail(e.message);
    };

    return;
  }

  const quoteAssemblyExceptionContext = useMemo(() => ({
    quoteAssemblyExceptionLstAsync, loadQuoteAssemblyExceptions
  }), [quoteAssemblyExceptionLstAsync ]);


  //note: moved to a function to try and avoid useMemo order errors

  const loadQuoteOnly = async () : Promise<Quote | undefined> => {
    //bail if no quote id
    const quoteId = params.quoteId;
    if ( !quoteId ) return;

    const revision = revisionParam || undefined;

    return await loadQuote(quoteAsync, quoteId, revision);
  }


  const {
    isLatestRevision,
    isEffectiveRevision,
    existPendingSplitChange,
    beforeSplitChangeSelfSubmitted,
    isOrder,
    isPendingApproval,
    isDraft,
    isPending,
    canAddChangeOrderToSplit,
    isSplitOrder,
    isSalesChangeOrder,
    isEngineeringChangeOrder,
    isOrderShipped,
    isOrderCancelled,
    isRevisionApproved,
    isReadOnly,
    isEngineeringLocked,
    isInitialLoading,
    isReviseQuote,
  } = Utils.getQuoteState(configurator, quoteAsync, isLocked);

  const isQuoteOwner = quote?.owner?.id === configurator.userInfo?.id;
  const isQuoteSales = quote?.salesTeam?.sales?.some(rep => rep.id === configurator.userInfo?.id);


  const hasShareWritePermission = !!quote?.quoteShares?.filter( s => s.writePermission ).find( s => s.user.id === configurator.userInfo?.id );
  const hasDealerReadPermission = configurator.hasAnyPermission([Permission.DEALER_ADMIN_READ, Permission.DEALER_MANAGEMENT_READ]);
  const hasDealerWritePermission = configurator.hasAnyPermission([Permission.DEALER_ADMIN_WRITE, Permission.DEALER_MANAGEMENT_WRITE]);
  const hasWritePermission = isQuoteOwner || isQuoteSales || isEngineering || isSalesDesk || isAdmin || hasDealerWritePermission || hasShareWritePermission;
  const hasSplitPermission =  configurator.hasPermission(Permission.CHANGE_ORDER_WRITE);
  const hasCancelOrderPermission = configurator.hasPermission(Permission.CANCEL_ORDER_WRITE);
  const isQuoteOnlyPermission = configurator.isQuoteOnly();
  const hasSubmitOrderPermission = (isSales || isDealer) && !isQuoteOnlyPermission;
  const canChangeReadOnly = ( isSalesDesk || isEngineering || isAdmin ) && !isEngineeringLocked;
  const hasSubmitQuotePermission = (isSales || isDealer);

  const pricingDetails = computedPricingDetails || quotePricingDetails;

  const isWorking = quoteAsync.isLoading() ||
    computedSelectionsAsync.isLoading() || 
    computedValidAsync.isLoading() || computedValidAsync.isInitial() ||
    modelCategoriesAsync.isLoading() ||
    previewQuoteAsync.isLoading();

  const updateTruckGvwr = (selectedOptions: CategoryIdAssembliesIdMap | undefined) => {

    const selections = selectedOptions ? Object.values(selectedOptions).flat() : [];
    if (selections.length === 0) return;

    truckGvwrAsync.setLoading();
    try {
      configurator.api.fetchTruckGvwr(selections)
        .then(resp => truckGvwrAsync.setDone(resp.data),
          reason => truckGvwrAsync.setFail(reason));
    }
    catch (e: any) {
      truckGvwrAsync.setFail(e.message);
    }
  }

  const loadApprovalWorkflow = async () => {
    if ( !quote?.id ) return;
    if ( !isPendingApproval ) return;

    workflowAsync.isLoading();
    try {
      const resp = await configurator.api.getApprovalWorkflow(quote.id);
      workflowAsync.setDone(resp.data);
    }
    catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to get workflow. " + errorMsg

      notification.error( { message: msg });
      workflowAsync.isFail();
    }
  }

  const clearCustomOptionCategory = (categoryId:number, customOptionLst:CustomOptionType[] | undefined) : CustomOptionType[] | undefined => {
        return customOptionLst?.filter( customOption => ( customOption.category?.id !== categoryId ) );
  }

  const handleClearSelections = async (category:BaseCategory) => {

    const options = {...selectedOptions, [ category.id ]: [] };

    const customOptionLst = clearCustomOptionCategory(category.id, selectedCustomOptions);

    getTruckDescription(options);

    //CFGR-709
    setSelectedCustomOptions(customOptionLst);
    setSelectedOptions(options);

    if ( selectedModel ) {
      computeValid( computedValidAsync, selectedModel?.modelInfo.id, {
        selections: Object.values( options || {} ).flat(),
        customOptions: customOptionLst?.map(co => co.id ) as number[] | undefined,
        quoteRevisionId: quote?.displayRevisionId,
      } );
    }

    recomputePricing({
      options,
      customOptions: customOptionLst?.map(co => co.id ) as number[] | undefined
    });
  }

  const handleUpdateOptionNotes = (assembly:Assembly | undefined, note:string | undefined) => {
    if ( !assembly ) return;

    setOptionNotes({ ...optionNotes, [assembly.id]:note});
  }

  const selectAssembly = (category:BaseCategory, assembly: Assembly ): number[] => {

    const categoryOptions = [...selectedOptions?.[ category.id ] || []];

    //toggle previously selected option
    if ( categoryOptions.find( id => id === assembly.id ) ) {
        return categoryOptions.filter( id => id !== assembly.id );
    }

    if ( !category.allowMultiple ) {
      return [ assembly.id ];
    }

    return [ ...categoryOptions, assembly.id];
  }

  const selectCustomOption = (category:BaseCategory, customOption:CustomOptionType) : CustomOptionType[] | undefined => {

    const co = selectedCustomOptions?.find(co => co.id === customOption.id );
    if ( co ) {
      //toggle previously selected option
      const lst = selectedCustomOptions?.filter( c => c.id !== co.id );
      return lst;
    }
    else {

      const includedCustomOption = { ...customOption, included: true };
      if ( category.allowMultiple  ) {
        //append new selection
        const lst = [...(selectedCustomOptions || []), includedCustomOption ];
        return lst;
      }
      else {
        //clear previous selections and append new selection
        const lst = [
          ...( selectedCustomOptions?.filter( c => c.category?.id !== category.id ) || []),
          includedCustomOption
        ];
        return lst;
      }
    }
  }

  const handleSelectAssembly = async (category:BaseCategory,  option: AssemblyBase | CustomOptionType) => {
    if ( !category ) return;
    if ( !selectedModel ) return;
    if ( !selectedOptions ) return;

    const assembly = isAssembly(option) ? option as Assembly : undefined;
    const customOption = !isAssembly(option) ? option as CustomOptionType : undefined;;

    let options = {...selectedOptions};
    let customOptionLst = [...(selectedCustomOptions || [])];

    if ( assembly ) {

      //update assemblies
      const lst = selectAssembly( category, assembly );
      options = {...selectedOptions, [ category.id ]: lst };

      //clear any custom options
      if ( !category.allowMultiple ) {
        customOptionLst = clearCustomOptionCategory(category.id, customOptionLst) || [];
      }
    }
    else if ( customOption ) {

      //update custom option
      customOptionLst = selectCustomOption( category, customOption ) || [];

      //clear any assemblies
      if ( !category.allowMultiple ) {
        options = {...selectedOptions, [ category.id ]: [] };
      }
    }

    const customOptions = customOptionLst.map(co => co.id ) as number[] | undefined;

    if ( !disableAutoSelect ) {
      options = await computeAutoSelections( computedSelectionsAsync, selectedModel.modelInfo.id, {
        selections: Object.values( options || {} ).flat(),
        customOptions,
        quoteRevisionId: quote?.displayRevisionId,
        latestAssembly: assembly?.id
      } ) || options;
    }

    getTruckDescription(options);

    //CFGR-709
    setSelectedCustomOptions(customOptionLst);
    setSelectedOptions(options);

    computeValid( computedValidAsync, selectedModel.modelInfo.id, {
      selections: Object.values( options || {} ).flat(),
      customOptions,
      quoteRevisionId: quote?.displayRevisionId,
    });

    recomputePricing({
      options,
      customOptions: customOptionLst?.map(co => co.id ) as number[] | undefined
    });
  }


  const loadPerformance = () => {
    const quoteId = params.quoteId;
    const rev = revisionParam || undefined;
    if ( !quoteId ) return;
    configurator.api.fetchQuotePerformance(quoteId, rev).then(
      (res) => {performanceAsync.setDone(res.data);}, 
    ).catch(e => performanceAsync.setFail(e.message))
  }

  const existPerformanceError = () => {
    return !( !performance?.performanceWeight?.weightsMissing.length 
    && !performance?.performanceWeight?.tareMissing.length 
    && !performance?.performanceWeight?.gvwrMissing.length 
    && !performance?.performanceData?.performanceMissing.length 
    && !performance?.performanceDimension?.dimensionMissing.length );
  }

  const loadFullPricingBreakdown = async (quoteId:string, rev?:number) => {

    quotePricingDetailsAsync.isLoading();
    try {
      //fetch new pricing
      const resp = await configurator.api.fetchFullPricingBreakdownByQuote(quoteId, rev);
      quotePricingDetailsAsync.setDone(resp.data);
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to update pricing. " + errorMsg });
      quotePricingDetailsAsync.setFail(e.message);
    }
  }

  const getSaveDto = (): SaveDto => {
    const values = getFormValues();
    return buildSaveDto( values );
  }

  const buildSalesTeamRequest = (salesTeam:SalesTeam | undefined) : SalesTeamRequest | undefined => {
    if ( !salesTeam ) return;

    return {
      id: salesTeam.id,
      name: salesTeam.name,
      sales: salesTeam.sales?.map( u => u.id ),
      engineers: salesTeam.engineers?.map( u => u.id ),
      support: salesTeam.support?.map( u => u.id ),
    };
  }

  const buildSaveDto = (values:FormValues): SaveDto => {
    const selections = selectedOptions ? Object.values( selectedOptions ).flat() : [];
    const customOptions = selectedCustomOptions?.map( co => co.id ) as number[] | undefined;

    const selectedModelInfo = values.modelInfo;
    const modelId = selectedModelInfo?.modelInfo.id;
    const salesTeam = buildSalesTeamRequest( values.salesTeam );

    return {
      shippingDestination: values.shippingDestination?.id,
      quantity: values.quantity,
      salesRequests: values.salesRequests,
      gvwrCap: values.gvwrCap,
      name: values.quoteName,
      endCustomerId: values.endCustomer?.id,
      percentDiscount: values.percentDiscount,
      notes: values.quoteNotes,
      modelId,
      selections,
      assemblyNotes: optionNotes,
      archived: quote?.archived || false,
      dealerAdjustmentList: dealerAdjustments,
      nonDiscountOptionList: nonDiscountOptions,
      incentivePrograms: values.incentivePrograms?.map(ip => ip.id),
      customOptions,
      salesTeam
    };
  }

  const checkDirty = (props?:CheckDirtyProps): boolean => {

    const formValues = props?.formValues || getFormValues();
    const selectedCustomOptionLst = props?.selectedCustomOptions || selectedCustomOptions;
    const selectedAssemblyLst = props?.selectedAssemblies || selectedOptions;
    const dealerAdjustmentLst = props?.dealerAdjustments || dealerAdjustments;
    const nonDiscountOptionLst = props?.nonDiscountOptions || nonDiscountOptions;

    if (isOrderCancelled || !isEffectiveRevision) return false;

    const quoteSelections = Object.values(quote?.selections || {}).flat().sort();
    const selections = selectedAssemblyLst ? Object.values(selectedAssemblyLst).flat().sort() : [];
    if (!_.isEqual(quoteSelections, selections)) {
      return true;
    }

    const quoteCustomOptionIdLst = customOptionLstAsync.val?.filter(co => co.included).map(co => co.id ).sort() || [];
    const selectedCustomOptionIdLst = selectedCustomOptionLst?.map(co => co.id ).sort() || [];
    if (!_.isEqual(quoteCustomOptionIdLst, selectedCustomOptionIdLst)) {
      return true;
    }

    if (isPricingOptionEqual(dealerAdjustmentLst, quote?.dealerAdjustmentList)) {
      return true;
    }

    if (isPricingOptionEqual(nonDiscountOptionLst, quote?.nonDiscountOptionList)) {
      return true;
    }

    return Object.entries(formValues).some(([k, v]) => {

      switch (k) {
        case 'salesTeam': {
          return !_.isEqual(v, quote?.salesTeam );
        }
        case 'endCustomer': {
          return quote?.endCustomer?.id !== v?.id;
        }
        case 'shippingDestination': {
          return quote?.shippingDestination?.id !== v?.id;
        }
        case 'quoteName': {
          return ( quote?.name?.trim() !== v?.trim() )
        }
        case 'quoteNotes': {
          return ( quote?.notes?.trim() !== v?.trim() )
        }
        case 'quantity':
        case 'gvwrCap':
        case FORM_PERCENT_DISCOUNT:
          {
          if ( quote?.[k] != v ) {
            return true;
          }
          return false;
        }
        case 'modelInfo': {
          if (quote?.model.id !== v?.modelInfo.id ) {
            return true;
          }
          return false;
        }
        case 'productionDate':
        case 'shippingDate':
        case 'bmSalesReps':
        case 'salesSupport':
        case 'copyName':
        case 'salesRequests':
        case 'requestedShipping':
        case 'poNumber':
        case 'engineer':
        case 'owner': {
          return false;
        }
        case 'incentivePrograms': {
          if (!_.isEqual( [...(formValues[k] || [])].sort(), [...(quote?.incentivePrograms || [])].sort() )) { 
            return true;
          }
          return false;
        }
        default:
          // sales requests
          {
          if ((quote == null && formValues[k] != null && formValues[k].trim() !== '') || (quote != null &&
                                                                                        (((k in quote) && (formValues[k].trim() !== quote[String(k)])) || (!(k in quote) && v != null && String(v).trim() !== '')))) {
            return true;
          }
          return false;
        }
      }
    });
  }

  const isPricingOptionEqual = (oldArr: PricingOption[] | undefined, newArr: PricingOption[] | undefined) => {

    const cmp = (a:PricingOption,b:PricingOption) => a.key.localeCompare(b.key);
    const oldList = oldArr?.sort(cmp);
    const newList = newArr?.sort(cmp);

    return !_.isEqual(oldList, newList);
  }

  const getFormValues = () : FormValues => {
    const allValues = quoteForm.getFieldsValue();
    const newAllValues = isAdminOrEngineering ? allValues : { ...allValues };
    return newAllValues
  }

  const handleUpdateDiscountPercent = (discountPercent:number) => {
    quoteForm.setFieldValue( FORM_PERCENT_DISCOUNT, discountPercent  );
    onQuoteValuesChange({[FORM_PERCENT_DISCOUNT]: discountPercent}, quoteForm.getFieldsValue(true));
  }

  
  const onQuoteValuesChange = async (values: Record<string, any>, allValues: {}) => {
    handleQuoteValuesChange( values, allValues );
  }
  const handleQuoteValuesChange = async (values: Record<string, any>, _allValues: {}, disableSave?: boolean) => {

    let isSaveForm = false;
    let isRecomputePricing = false;
    if ( 'modelInfo' in  values ) {
      await onChooseModel( values.modelInfo );
    }

    if ( 'allCustomOptions' in values ) {
      isRecomputePricing = true;
    }

    if ( FORM_PERCENT_DISCOUNT in values  ) {
      isRecomputePricing = true;
    }

    if ( 'incentivePrograms' in  values ) {
      isSaveForm = true;
    }

    if ( 'requestedShipping' in values ) {
      await updateRequestedShipping( quote?.displayRevisionId, values.requestedShipping );
    }


    if ( 'salesTeam' in values ) {
      isSaveForm = true;
    }

    if ( 'endCustomer' in values ) {
      isSaveForm = true;
    }

    if ( 'shippingDestination' in values ) {
      isRecomputePricing = true;
      isSaveForm = true;
    }

    if ( !isNewQuote && !disableSave && isSaveForm ) {

        await saveQuoteForm();
    }
    else {
      updateDirty();
    }

    if ( isRecomputePricing ) {
      await recomputePricing();
    }


  };

  const onChooseModel = async (selectedModelInfo: SelectedModelInfo) : Promise<Model | undefined> => {

    //only update on diffent model ( not model year )
    if ( quote?.model.id === selectedModelInfo?.modelInfo.id )  return;

    const modelId = selectedModelInfo?.modelInfo.id;

    try {
      const resp = await configurator.api.getModelDetail(modelId);
      const modelDetail = resp.data;
      if ( !modelDetail )  return;

      if(modelDetail.initialConcession && !quote) {
        quoteForm.setFieldValue( FORM_PERCENT_DISCOUNT, modelDetail.initialConcession);
      }

      await reloadModelCategories(modelId);

      const options = selectedModelInfo.selections;
      setSelectedOptions( options );
      setSelectedCustomOptions([])

      computeValid( computedValidAsync, modelId, {
        selections: Object.values( options || {} ).flat(),
        quoteRevisionId: quote?.displayRevisionId,
      });

      await recomputePricing({
        options,
      });

      return modelDetail;

    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to get model details. " + errorMsg

      notification.error( { message: msg });
      quoteAsync.setFail(msg);
    }

    return;
  };

  const setQuoteFormValues = async (updated:Quote | undefined, disableSave?: boolean) => {
    if ( !updated ) return;

    quoteForm.setFieldsValue(populateFormValues(updated));

    setSelectedOptions(updated?.selections);

    setOptionNotes(updated?.selectionNotes || {});

    await reloadModelCategories( updated?.model.id, { 
      quoteRevisionId: updated?.displayRevisionId 
    } )

    setDealerAdjustments(updated.dealerAdjustmentList);
    setNonDiscountOptions(updated.nonDiscountOptionList);

    await recomputePricing({
      options: updated.selections,
      dealerAdjustments: updated.dealerAdjustmentList,
      nonDiscountOptions: updated.nonDiscountOptionList,
    });

    if ( updated?.model.id ) {
      await computeValid( computedValidAsync, updated?.model.id, {
        selections: Object.values( updated?.selections || {} ).flat(),
        customOptions: selectedCustomOptions?.map(co => co.id ) as number[] | undefined,
        quoteRevisionId: updated?.displayRevisionId 
      });
    }

    //get changed values
    const diff = _.differenceWith(_.toPairs(updated), _.toPairs(quote), _.isEqual)
    .reduce( (acc, v ) => {
      const key = v[0]; const value = v[1];
      acc[ key ] = value;
      return acc;
    }, {});
    await handleQuoteValuesChange(diff, updated, disableSave );
  }

  const populateFormValues = (quote: Quote | undefined) => {
    if (!quote) return QUOTE_DEFAULTS;

    const productionDate = dateStrToMoment(quote.productionDate );
    const shippingDate = dateStrToMoment(quote.shippingDate );
    const customerShippingDate = dateStrToMoment(quote.customerShippingDate);

    const modelInfo = {
      modelInfo: quote.model,
      modelYear: quote?.modelYear || ( dayjs().year() + 1 ),
      selections: quote.selections
    };

    return {
      quoteName: quote.name,
      quoteNotes: quote.notes,
      salesRequests: quote.salesRequests,
      poNumber: quote.poNumber,
      endCustomer: quote.endCustomer,
      quantity: quote.quantity,
      percentDiscount: quote.percentDiscount,
      shippingDestination: quote.shippingDestination,
      owner: quote.owner,
      gvwrCap: quote.gvwrCap,
      productionDate,
      shippingDate,
      modelInfo: modelInfo,
      incentivePrograms:quote.incentivePrograms,
      requestedShipping: {
        customerShippingDate,
        cadence: quote.cadence,
        truckCustomerShippingDateList: quote.truckCustomerShippingDateList,
      },
      salesTeam: quote.salesTeam,
    };
  }

  const dateStrToMoment = (d:string | undefined ) : Dayjs | undefined => {
    if ( !d ) return;
    return dayjs(d, 'YYYY-MM-DD')
  }

  const updateRequestedShipping = async (quoteRevisionId: number | undefined, requestedShipping: RequestedShipping): Promise<Quote | undefined> => {
    if (!quoteRevisionId) return;

    quoteAsync.setLoading()
    try {

      var resp = await configurator.api.updateRequestedShipping(quoteRevisionId, requestedShipping);
        
      var updatedQuote = resp.data;

      quoteAsync.setDone(updatedQuote);

      notification.success({message: "Requested shipping updated"})

      return updatedQuote;

    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg =  "Failed to update requested shipping. " + errorMsg

      notification.error( { message: msg });
      quoteAsync.setFail(msg);
    }

    return;
  }

  const onTabClick = (key: string) => {
    setTabKey(key);
  }

  const getPriceView = () => {
    if (isDealer) {
      return [SALES_PRICE_VIEW, SPEC_ONLY_VIEW];
    }
    else if (isAdminOrEngineering) {
      return [ADMIN_PRICE_VIEW, SALES_PRICE_VIEW, SPEC_ONLY_VIEW];
    }
    else {
      return [SALES_PRICE_VIEW, SPEC_ONLY_VIEW];
    }
  }

  const setPriceView = (value: SegmentedValue) => {
    if (String(value) === ADMIN_PRICE_VIEW) {
      setAdminView(true);
      setHidePrice(false);
    }
    else if (String(value) === SALES_PRICE_VIEW) {
      setAdminView(false);
      setHidePrice(false);
    }
    else if (String(value) === SPEC_ONLY_VIEW) {
      setAdminView(isAdminOrEngineering);
      setHidePrice(true);
    }
  }

  const getTruckDescription = async (options: CategoryIdAssembliesIdMap) => {
    const selectionIds = Object.values(options).flat() as number[];
    try {
      if (quote) {
        const resp = await configurator.api.getTruckDescription(quote?.displayRevisionId, selectionIds);
        setTruckDescription(resp.data);
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  const setRevision = (revisionId: number | undefined) => {
    const revision = getQuoteRevisionById(quote, revisionId);
    if (revision === undefined) {
      setRevisionParam(undefined);
    }
    else {
      setRevisionParam(revision.revision);
    }
  }

  const getQuoteRevisionById = (quote: Quote | undefined, id: number | undefined): Revision | undefined => {
    return quote?.revisions.find(r => r.id === id);
  };

  const revision = getQuoteRevisionById( quote, quote?.displayRevisionId );

  const updateExpiredQuote = async (pricingSnapshotId?: number | undefined, updateExpiredQuote?: boolean | undefined) => {
    if ( !quote ) return;

    try {

      quoteAsync.setLoading();
      const resp = await configurator.api.updatePricingConfig( quote.id, pricingSnapshotId, updateExpiredQuote );
      quoteAsync.setDone(resp.data);
      notification.success({message: "Successfully update price, please submit quote approval."});

      loadFullPricingBreakdown(quote.quoteId, revisionParam || undefined);

    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to update pricing. " + errorMsg });
      quoteAsync.setFail(e.message);
    }

  }

  const getQuoteDescription = (): DescriptionsItemType[] => {

    if (!quote) return [];
    
    let items = 
    [
      {
        key: "quote",
        label: <div key="quote-label">{"Quote"}
        </div>,
        span: 7,
        children: 
        <Row>
          <Paragraph copyable={{ text: quote.quoteId }} style={{marginRight: ".4rem"}}>
            <Link to={"/configurator/" + encodeURI(quote.quoteId)}>
              <span data-testid="quoteIdStr" style={{whiteSpace: "nowrap"}}>{quote.quoteId}</span>
            </Link>
          </Paragraph>
        </Row>
      },
      {
        key: "quoteStatus",
        label: "Status",
        span: 4,
        children: 
        <span data-testid="quoteStatusStr">
          {Utils.formatQuoteStatus(quote)}
        </span>
      },
      {
        key: "partNumber",
        label: "Part Number",
        span: 4,
        children: 
        <> 
          <span data-testid="partNumberString" >{quote.partNumberString || "NA"}</span>
          {quote.partNumberString != undefined && quote.partNumberString !== "" && <Paragraph copyable={{text: quote.partNumberString}}></Paragraph>}
        </>
      },
      {
        key: "rev",
        label: "Revision",
        span: 6,
        children: 
          <RevisionSelector
            value={quote.displayRevisionId}
            onChange={setRevision}
            currentRevisionId={quote.currentRevisionId}
            revisions={quote.revisions}
            style={{ height: "inherit"}}
          />
      },
    ]

    // const revision = getQuoteRevisionById( quote, quote.displayRevisionId );
    const showSecondRow = quote.trucks?.length || (quote.salesOrderNumber && !isDealer) || (quote.truckDescription);

    if (showSecondRow) {

      items.push({
        key: "truckDescription",
        label: quote.truckDescription ? "Truck Description" : "",
        span: 7,
        children: 
          <Paragraph copyable={{ text: truckDescription || quote?.truckDescription || '' }} style={{marginRight: ".4rem"}}>
            <span>{truckDescription || quote?.truckDescription || ''}</span>
          </Paragraph>
      })

      if ( !!quote.trucks?.length  ) {
        items.push({
          key: "serialNumber",
          label: !(!quote || !quote.trucks?.length || !revision) ? "Serial Number" : "",
          span: 4,
          children: <>
            <div style={{display: 'flex', gap: '.8rem', alignItems: "baseline", flexWrap: "wrap", paddingRight: "1rem" }}>
              <QuoteTrucksButtonModal  />
            </div>
          </>
        });
      }

      if( quote.salesOrderNumber ) {
        items.push({
          key: "salesOrderNumber",
          label: quote.salesOrderNumber && !isDealer ? "Epicor Sales Order" : "",
          span: 4,
          children: quote.salesOrderNumber && !isDealer ? <span>{quote.salesOrderNumber}</span> : <></>
        })
      }

      if (!hidePrice) {
        items.push({
          key: "dealerPrice",
          label: "Dealer Price",
          span: 4,
          children: <span>{Utils.formatMoney(pricingDetails?.dealerPrice)}</span>
        });
      }
    }

    return items;
  }

  const notifyDisabled = (msg:string | undefined) => {

    if ( !!msg ) {
      notification.warning({message: msg });
    }
  }


  const computeAutoSelections = async (computedSelectionsAsync:AsyncState<CategoryIdAssembliesIdMap>, modelId:number, options?:{ selections?:number[], customOptions?:number[], quoteRevisionId?:number, latestAssembly?:number } ) : Promise<CategoryIdAssembliesIdMap | undefined> => {

    if ( cancelComputedSelectionsTokenSourceRef.current ) {
      cancelComputedSelectionsTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelComputedSelectionsTokenSourceRef.current = cancelSource;

    try {
      computedSelectionsAsync.setLoading()
      const resp = await configurator.api.computeAutoSelections(modelId, options, cancelSource.token );
      cancelComputedSelectionsTokenSourceRef.current = undefined;

      computedSelectionsAsync.setDone( resp.data.selections );
      return resp.data.selections;
    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Failed to load compute options. " + errorMsg });
        computedSelectionsAsync.setFail(e.message);
      }
    }

    return {};
  };

  const handleChangePricingView = (pricingDetails: PricingBreakdown) => {

    setDealerAdjustments(pricingDetails.dealerAdjustments);

    setNonDiscountOptions(pricingDetails.nonDiscountOptions);

    updateDirty({
      selectedAssemblies: selectedOptions ,
      selectedCustomOptions,
      dealerAdjustments: pricingDetails.dealerAdjustments,
      nonDiscountOptions: pricingDetails.nonDiscountOptions
    });

    recomputePricing({
      dealerAdjustments: pricingDetails.dealerAdjustments,
      nonDiscountOptions: pricingDetails.nonDiscountOptions,
    });
  }

  const isCategorySelectionValid = (category: BaseCategory) : boolean => {
    const selectedLst = selectedOptions?.[category.id] || [];
    const computedLst = computedValid?.[category.id] || [];
    return !!selectedLst?.length && _.isEqual([...selectedLst].sort(), [...computedLst].sort());
  };

  const allSelectedOrFrameSillLengthIsOnlyUnselectedCategory = () :boolean => {
    const allUnselected = modelCategories?.filter(c => !isCategorySelectionValid(c)) || [];
    //all are selected
    if ( allUnselected.length === 0 ) return true;
    //more than one category is unselected
    if ( allUnselected.length > 1 ) return false;
    //is single unselected category FRAME_SILL_LENGTH?
    return allUnselected[0].name.includes(FRAME_SILL_LENGTH);
  }

  const handleAddException = (quoteAssemblyException:QuoteAssemblyException) => {
    //automatically select an assembly on adding it
    const category = modelCategories?.find( c => c.id === quoteAssemblyException.assembly.categoryId );
    if ( category ) {
      handleSelectAssembly( category, quoteAssemblyException.assembly );
    }
  }
      
  const saveEngineeringLock = async (quoteId:number, lock:boolean) : Promise<Quote | undefined> => {
    if ( !quoteId ) return;

    quoteAsync.setLoading();
    try {
      //fetch new pricing
      const resp = await configurator.api.saveQuoteEngineeringLock(quoteId, lock);
      quoteAsync.setDone(resp.data)

      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to lock quote. " + errorMsg });
      quoteAsync.setFail(e.message);
    }

    return;
  }

  const handleEngineeringLock = async (lock:boolean) => {
    if ( !quote ) return;

    const savedQuote = await saveEngineeringLock( quote.id, lock );

    reloadQuote(savedQuote);
  }

  const getDisabledSplitMsg = () : string | undefined => {

    return formDirty ? "Please save change or create change order before attempting to split."
    : isOrderCancelled? "Order is cancelled."
    : (isSalesChangeOrder && isPending) ? "Change order in progress."
    : (isEngineeringChangeOrder && isPending) ? "Engineering change in progress."
    : (isSplitOrder && isPending) ? "Split order in progress."
    : isPendingApproval ? "Open approvals must be resolved."
    : !isLatestRevision ? "Must be latest revision."
    : undefined;
  }

  const getDisabledSaveMsg = () => {
    return isInitialLoading ? "Please wait for the quote to finish loading."
      : !formDirty ? "There are no changes to save." 
      : quote?.archived ? "Archvived quote cannot be modified."
      : isWorking ? "Please wait till background work has completed."
      : undefined;
  }

  const getDisabledUndoMsg = () => {
    return isInitialLoading ? "Please wait for the quote to finish loading."
    : quote?.archived ? "Archvived quote cannot be modified."
      : isWorking ? "Please wait till background work has completed."
      : undefined;
  }

  const getDisabledAbandonMsg = () => {
    return isInitialLoading ? "The quote has not loaded."
      : formDirty ? "Please save or reset changes before abandoning."
      : existPendingSplitChange ? "Change in split cannot be abandoned."
      : isWorking ? "Please wait till background work has completed."
      : undefined;
  }

  const getDisabledSubmitMsg = () => {
    return isInitialLoading ? "The quote has not loaded."
      : formDirty ? "Please save changes before submitting."
      : !isQuotePricingValid(quote) ? "Please update pricing before submitting."
      : isWorking ? "Please wait till background work has completed."
      : undefined;
  }

  const getDisabledReviseQuoteMsg = () => {
    return isInitialLoading ? "The quote has not loaded."
      : formDirty ? "Please save changes before submitting."
      : isWorking ? "Please wait till background work has completed."
      : undefined;
  }

  const getDisabledSubmitOrderMsg = () => {
    const submitMsg = getDisabledSubmitMsg();
    return !!submitMsg ? submitMsg
            : !quote?.isPricingValid ? "Please update invalid pricing."
            : undefined;
  }

  const getDisabledConvertReservationMsg = () => {
    return isInitialLoading ? "The quote has not loaded."
      : isWorking ? "Please wait till background work has completed."
      : undefined;
  }

  const getDisabledOptionsMsg = () => {
    return isInitialLoading ? "The quote has not loaded."
      //: formDirty ? "These options are not available while there are unsaved changes."
      : undefined;
  }

  const hasCopyPermission = ():boolean => {
    const hasPermission = configurator.hasPermission(Permission.COPY_QUOTE_WRITE);
    return isQuoteOwner || isQuoteSales || hasPermission || hasDealerReadPermission;
  }

  const hasConvertStockPermission = ():boolean => {
    return isAdmin || isSalesDesk;
  }

  const getChangeOrderDisabledMsg = () => {
    return isInitialLoading ? "Please wait for the quote to finish loading."
      : quote?.archived ? "Archvived quote cannot be modified."
      : formDirty ? "Please save changes or reset."
      : isWorking ? "Please wait till background work has completed."
            //: !isQuoteModifiable() ? "This quote cannot be modified."
            //: !isChangeableRevision ? "This revision cannot be modified."
      : undefined;
  }

  const getPriceProtectedChangeOrderDisabledMsg = () => {
    return getChangeOrderDisabledMsg() || 
      !existPendingSplitChange ? "This is only available on split change." 
      : undefined;
  }

  const getConvertToStockDisabledMsg = () => {
    return formDirty ? "Please save change or create change order before attempting to split."
    : isOrderCancelled? "Order is cancelled."
    : (isSalesChangeOrder && isPending) ? "Change order in progress."
    : (isEngineeringChangeOrder && isPending) ? "Engineering change in progress."
    : (isSplitOrder && isPending) ? "Split order in progress."
    : isPendingApproval ? "Open approvals must be resolved."
    : !isLatestRevision ? "Must be latest revision."
    : undefined;
  }
 
  const getDisabledAssemblyExceptionsMsg = () : string | undefined => {

    return isNewQuote ? "Quote must be saved before an exception can be added.."
      : undefined;
  }

  const getEngineeringChangeMsg = () => {
    return getChangeOrderDisabledMsg() || 
      existPendingSplitChange ? "There's pending split change." 
      : undefined;
  }

  const handleValidateForm = async () : Promise<ValidateFields | undefined> => {

    try {
      const values = await quoteForm.validateFields();
      return values;
    }
    catch(e:any) {
      const validationErrors = e as ValidateErrorEntity;
      //notification.error({message: "Please fix validation errors in Quote Info." });
      setValidationAlert(validationErrors.errorFields.map(f => f.errors.join(" ")));
    }

    return;
  }

  const handleReviseQuote = async () => {
    const q = await reviseQuote();
    if ( q ) {
      reloadQuote();
    }
  }
  const reviseQuote = async () : Promise<Quote | void> => {
    if ( !quote ) return;

    quoteAsync.setLoading()
    try {
      const resp = await configurator.api.reviseQuote(quote.id)
      quoteAsync.setDone(resp.data);

      notification.success({message:"Reverted to Quote"});

      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to revert quote. " + errorMsg;
      notification.error( { message: msg });
      quoteAsync.setFail(msg);
    }

    return;
  }

  const saveQuoteForm = async () : Promise<Quote | undefined> => {
    if ( !quote ) return;

    const dto = buildSaveDto( quoteForm.getFieldsValue() );
    const saved = await saveQuote(dto);
    if ( saved ) {
      await reloadQuote(saved);
    }
    return saved;
  }

  const saveQuote = async (dto:SaveDto) : Promise<Quote | undefined> => {
    if ( !quote ) return;

    quoteAsync.setLoading()
    try {
      const resp = await configurator.api.saveQuoteRevision(quote.displayRevisionId, dto)
      quoteAsync.setDone(resp.data);

      notification.success({message:"Quote Saved."});

      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to save quote. " + errorMsg;
      notification.error( { message: msg });
      quoteAsync.setFail(msg);
    }

    return;
  }

  
  const createQuote = async (dto:SaveDto) : Promise<Quote | undefined> => {

    quoteAsync.setLoading()
    try {
      const resp = await configurator.api.createQuote(dto)
      quoteAsync.setDone(resp.data);
      notification.success({message:"Quote Created."});
      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to create quote. " + errorMsg;
      notification.error( { message: msg });
      quoteAsync.setFail(msg);
    }

    return;
  }

  const handleCreateQuote = async () => {

    const dto = buildSaveDto( quoteForm.getFieldsValue() );
    const savedQuote = await createQuote(dto);
    if (savedQuote ) {
      setFormDirty(false); //avoid navigation alert
      setTimeout( () => history.push("/configurator/" + encodeURI(savedQuote.quoteId)), 10 );
    }
  }

  const handleSave = async () => {

    try {
      await quoteForm.validateFields();

      if ( isSalesChangeOrder && isDraft ) {
        await handleSaveSalesChangeOrder();
      }
      else if ( isEngineeringChangeOrder && isDraft ) {
        await handleSaveEngineeringChangeOrder();
      }
      else if ( isNewQuote ) {
        await handleCreateQuote();
      }
      else {
        await saveQuoteForm();
      }
    }
    catch(validationErrors) {
      notification.error({message: "Please fix validation errors." });
    }
  }

  const handleSaveSalesChangeOrder = async () => {
    if ( !quote ) return;

    const saved = await saveSalesChangeOrder( quote.id, {
      ...getSaveDto(),
      changeOrderRevisionId: quote.displayRevisionId,
    }); 

    reloadQuote(saved);
  }

  const saveSalesChangeOrder = async (quoteId: number | undefined, dto: SalesChangeOrderSaveDto): Promise<Quote | undefined> => {
    if (!quoteId) return;

    quoteAsync.setLoading();
    try {
      const resp = await configurator.api.updateChangeOrder(quoteId, dto)
      quoteAsync.setDone(resp.data);
      notification.success({message:"Saved Successfully."});

      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to save engineering change. " + errorMsg;
      notification.error( { message: msg });
      quoteAsync.setFail(msg);
    }

    return;
  }

  const saveEngineeringChangeOrder = async (quoteId: number | undefined, dto: EngineeringChangeSaveDto): Promise<Quote | undefined> => {
    if (!quoteId) return;

    quoteAsync.setLoading();
    try {
      const resp = await configurator.api.updateEngineeringChange(quoteId, dto);
      quoteAsync.setDone(resp.data);
      notification.success({ message: "Saved Successfully." });

      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to save engineering change. " + errorMsg;
      notification.error({ message: msg });
      quoteAsync.setFail(msg);
    }

    return;
  }

  const handleSaveEngineeringChangeOrder = async () => {
    if (!quote ) return;

    const saved = await saveEngineeringChangeOrder( quote.id, {
      ...getSaveDto(),
      changeOrderRevisionId: quote.displayRevisionId,
    }); 

    reloadQuote(saved);
  }

  const handleQuoteShareChange = async (shareLst:QuoteShare[] | undefined) => {
    const updatedQuote = { ...quote, quoteShares: shareLst } as Quote;
    quoteAsync.setDone( updatedQuote );
  }

  const handleReset = () => {
    Modal.confirm( {
      title: "Confirm Reset",
      content: "This will reset to the last saved changes.",
      onOk: () => reloadQuote()
    })
  }

  const optionActionItems = new Array<MenuItemType>();


  if (configurator.isAdmin() ) {

    optionActionItems.push( {
      key: "adminSubMenu",
      label: "Admin",
      children: [
        {
          key: "adminSubMenu-assemblyExceptions",
          label: <FixPricingButtonModal 
            targetPrice={quote?.poNumber?.amount || pricingDetails?.totalPrice}
            revisionId={quote?.displayRevisionId} 
            onChange={reloadQuote} type="text"/>
        },
        {
          key: "adminSubMenu-moveTrucks",
          label: <MoveTrucksButton type="text"/>
        }
      ]
    } as SubMenuType );
  }

  if (configurator.isAdmin() || configurator.isEngineering()) {
    optionActionItems.push( {
      key: "engineeringSubMenu",
      label: "Engineering",
      children: [
        {
          key: "engineeringSubMenu-customOptions",
          label:
            <CustomOptionsButtonModal
              type="text"
              selectedCustomOptions={selectedCustomOptions}
              disabled={!!getDisabledAssemblyExceptionsMsg()}
              onDisabledClick={() => notifyDisabled(getDisabledAssemblyExceptionsMsg())}
              onChange={() => reloadQuote()}
            />
        },
        {
          key: "engineeringSubMenu-assemblyExceptions",
          label:
            <AssemblyExceptionButtonModal
              type="text"
              disabled={!!getDisabledAssemblyExceptionsMsg()}
              onDisabledClick={() => notifyDisabled(getDisabledAssemblyExceptionsMsg())}
              onAdd={handleAddException}
            >
              Assembly Exceptions
              <Badge count={quoteAssemblyExceptionLst?.length} size="default" style={{marginLeft: ".2rem"}} />
            </AssemblyExceptionButtonModal>
        },
        {
          key: "engineeringSubMenu-engineerLock",
          label:
            <div style={{textAlign: "center"}}>
            <label >
              <Checkbox
                onChange={(e) => handleEngineeringLock(e.target.checked)}
                checked={!!quote?.lockedByEngineer}
                style={{padding:0}}
              />
              <span style={{marginLeft: "0.5rem"}}>
              Engineering Lock
              </span>
            </label>
            </div>
        }
      ]
    } as SubMenuType );
  }

  if (hasWritePermission) {
    optionActionItems.push( {
      key: "shareQuote",
      label: <ShareQuoteButtonModal type="text"
        className="ghostBmButton"
        onChange={handleQuoteShareChange}
      >Share Quote</ShareQuoteButtonModal>
    } );
  }

  if ( isSales ) {
    optionActionItems.push( {
      key: "bomDetails",
      label: <BomDetails
        type="text"
        selectedOptions={selectedOptions}
        canAccessAll={isEngineering || isAdmin || isFinance || isSalesDesk}
        pricingSnapshotId={quote?.pricingConfig?.pricingSnapshot?.id}
      />
    } );
  }

  optionActionItems.push( {
    key: "diffRevisions",
    label: <DiffRevisionModalButton />
  });

  if ( quote?.id ) {
    optionActionItems.push( {
      key: "releaseLock",
      label: <BMButton 
        type="text" 
        onClick={() => {
          configurator.api.releaseQuoteLock(quote.id);
          history.push("/quotes");
        }}
      >Release Quote Lock</BMButton>
    });
  }

  if ( !isNewQuote && hasCopyPermission() ) {

    optionActionItems.push( {
      key: "copy",
      label: <QuoteCopyModal
        disabled={isWorking}
        type="text"
        onFinished={(copy) => {
          window.open("/configurator/" + encodeURI(copy.quoteId), "_blank");
        }}
      />});
  }

  if (isEffectiveRevision && !formDirty ) {
    if ( hasSplitPermission && ( ( quote?.quantity || 0 ) > 1 )) {
      optionActionItems.push({
        key: "split",
        label: <SplitOrder
          type="text"
          className="ghostBmButton"
          quote={quote}
          disabled={!!getDisabledSplitMsg()}
          onDisabledClick={() => notifyDisabled(getDisabledSplitMsg())}
        />});
    }

    if ( !isNewQuote && ( isAdmin || isEngineering || isSalesDesk ) && !(isOrderShipped || isOrderCancelled) ) {
      optionActionItems.push({
        key: "generateSerialNumber",
        label: <GenerateSerialButton type="text" />
      });
    }

    if (hasCancelOrderPermission) {
      optionActionItems.push({
        key: "cancelOrder",
        label: <CancelOrderModule type="text" quote={quote}/>
      })
    }
  }

  if ( isOrder && hasConvertStockPermission() ) {
    optionActionItems.push( {
      key: "stock",
      label: <InventoryCheckbox 
        disabled={!!getConvertToStockDisabledMsg()}
      />
    });
  }

  if ( !isNewQuote && ( isAdmin || isEngineering || isSalesDesk || isQuoteOwner ) ) {
    optionActionItems.push({
      key: "archive",
      label: <ArchiveQuoteSwitch />
    });
  }

  const getVerificationStr = () : string => {
    return (statusMessages?.map(msg => intl.formatMessage({id: msg})).join(" ") || "") + " Please refresh page to see current quote."
  }

  const getChangeOrderDropdownItems = () => {
    return [
      { key:"engineeringChange",
        label: <CreateEngineeringChangeButton 
          type="text"
          disabled={!!getEngineeringChangeMsg()}
          onDisabledClick={() => notifyDisabled(getEngineeringChangeMsg())}
          onChange={reloadQuote} />
      },
      { key:"salesChange",
        label: <CreateSalesChangeButton 
          type="text"
          disabled={!!getChangeOrderDisabledMsg()}
          onDisabledClick={() => notifyDisabled(getChangeOrderDisabledMsg())}
          onChange={reloadQuote} >Create Sales Change</CreateSalesChangeButton>
      },
      { key:"priceProtectedSalesChange",
      label: <CreatePriceProtectedSalesChangeOrderButton
        type="text"
        disabled={!!getPriceProtectedChangeOrderDisabledMsg()}
        onDisabledClick={() => notifyDisabled(getPriceProtectedChangeOrderDisabledMsg())}
        onChange={reloadQuote} >Create Change (Price Protection)</CreatePriceProtectedSalesChangeOrderButton>
      },
    ]
  }

  const quoteContext = {
    quoteAsync, userActivity, adminView, loadQuote:reloadQuote, loadQuoteOnly, isLocked, setQuoteFormValues
  };

  const quoteFormContext = {
    selectedModel, selectedOptions, selectedCustomOptions
  };

  const tabLst =[
    {
      key: QUOTE_INFO_PANEL_KEY,
      label: <>
        <span>Quote Info</span>
        {!!validationAlert?.length && <Tooltip title={validationAlert.map(a => <div key={a + "-alert"}>{a}</div>)}>
          <WarningFilled data-testid="quoteInfoAlertIcon" style={{marginLeft: ".5rem", fontSize: "20px",  color: "orange" }} size={5}/>
        </Tooltip>}
      </>,
      children: 
      <QuoteInfoTab
        isWorking={isWorking}
        isLocked={isLocked}
        quoteForm={quoteForm}
        truckGvwr={truckGvwr}
        computedPricingDetailsAsync={computedPricingDetailsAsync}
        quotePricingDetailsAsync={quotePricingDetailsAsync}
        selectedModel={selectedModel}
        selectedCustomOptions={selectedCustomOptions}
        formDirty={formDirty}
        hidePrice={hidePrice}
        getFormValues={getFormValues}
        updateExpiredQuote={updateExpiredQuote}
        handleUpdateDiscountPercent={handleUpdateDiscountPercent}
        onQuoteValuesChange={onQuoteValuesChange}
        populateFormValues={populateFormValues}
        handleChangePricingView={handleChangePricingView}
      />,
      forceRender: true,
    },
    {
      key: ASSEMBLY_PANEL_KEY,
      label: "Truck Options",
      children: 
      <QuoteTruckOptionsTab
        readOnly={isReadOnly}
        loading={isWorking}
        modelId={selectedModel?.modelInfo.id}
        percentDiscount={quoteForm.getFieldValue( FORM_PERCENT_DISCOUNT )}
        selectedCategory={selectedCategory}
        disabled={isReadOnly}
        onSelectOption={handleSelectAssembly}
        filterOptionsQuery={filterOptionsQuery}
        optionNotes={optionNotes}
        onUpdateOptionNotes={handleUpdateOptionNotes}
        onClearSelections={handleClearSelections}
      />
    },
    {
      key: PERFORMANCE_PANEL_KEY,
      label: 
      <>
        <span>Performance</span>
        {existPerformanceError() && <Tooltip title={"Please review errors in Performance Details tab"}>
          <WarningFilled data-testid="performancePanelWarningIcon" style={{marginLeft: ".5rem", fontSize: "20px",  color: "orange" }} size={5}/>
        </Tooltip>}
      </>,
      children: 
      <QuotePerformanceTab performanceAsync={performanceAsync}/>,
      forceRender: true,
    },
    {
      key: DASH_PANEL_KEY,
      label:
      <>
        <span>Dash Drawing</span>
        {(needVerifyDash?.hasUnassignedComponents) && <Tooltip title={"There are errors with the dash layout.  Please review."}>
          <WarningFilled  data-testid="dashDrawingPanelWarningIcon" style={{marginLeft: ".5rem", fontSize: "20px", color: "orange"}} />
        </Tooltip>}
      </>,
      children: 
      <>
        {quote && <QuoteDashTab
          quote={quote}
        />}
      </>,
      forceRender: true,
    },
    {
      key: HISTORY_PANEL_KEY,
      label: "History",
      children: 
      <QuoteHistoryTab
        quote={quote}
        categories={modelCategories}
        tabKey={tabKey}
      />
    },
  ];

  if ( isAdminOrEngineering ) {
    tabLst.push( {
      key: "QuoteAuditTab",
      label: "Audit",
      children: <QuoteAuditView />
    })
  }

  return (    <div>
    <style>
    {`
      .ant-descriptions .ant-descriptions-row > th, .ant-descriptions .ant-descriptions-row > td {
        padding-bottom: 5px;
      }
    `}
    </style>

      <QuoteContextProvider value={quoteContext}>
      <QuoteFormContextProvider value={quoteFormContext}>
      <ModelCategoryContext.Provider value={modelCategoryContext}>
      <CustomOptionsContext.Provider value={customOptionsContext}>
      <QuoteAssemblyExceptionContext.Provider value={quoteAssemblyExceptionContext}>
    <div style={layoutStyle}>
      <AssemblySectionMenu
        loading={isWorking}
        computedOptions={computedValid}
        filterQuery={filterOptionsQuery}
        updateFilterQuery={setFilterOptionsQuery}
        onCategoryChange={setSelectedCategory} 
        onClickCategory={() => {
          setTabKey(ASSEMBLY_PANEL_KEY);
        }}
        onToggleAutoSelect={setDisableAutoselect}
      />
      {process.env.REACT_APP_PRODUCTION_ENV !== 'true' && 
        <Affix style={{  textAlign: "center", backgroundColor: "rgba(0, 0, 0, 0)"}}>
          <div style={{backgroundColor: "yellow",display: "inline-block", padding: ".4rem" }}> This is a non-production environment. </div>
        </Affix>
      }
      <div className="site-layout-background" style={{ marginLeft: isMobile ? "0px" : "75px" }}>
        <Col>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "-2rem" }}>
            <Title level={3}>
              Configurator{" "}
              <PriceViewSwitch
                options={getPriceView()}
                defaultValue={getPriceView()[0]}
                setPriceView={setPriceView}
              />
            </Title>
            {isCurrentRevision && (<div style={{ color: 'green', fontWeight: 'bold', marginTop: ".2rem" }}>Current Revision</div>)}
          </div>
          <div>

            <QuoteLock
              style={{ marginBottom: "20px" }}
              lockState={lockState}
              retryLock={retryLock}
              userActivity={userActivity}
            />
            {isPendingApproval && <div>
              <WorkflowProgress
                workflow={workflow}
                hideDescription={true}
              />
            </div>}
            <Row>
              <Divider orientation="right"/>
              {quote != null && 
              <Descriptions
                layout="vertical"
                column={20}
                items={getQuoteDescription()}
                labelStyle={{fontWeight: "bold", color: "black"}}
              />}
            </Row>
            <Row justify={"space-between"}  style={{marginBottom: "1rem"}} >

              <Col>
                <Space>

                  <QuoteExportsDropDown
                    selectedRevisionId={selectedRevisionId}
                    pendingChanges={formDirty}
                    allSelected={allSelectedOrFrameSillLengthIsOnlyUnselectedCategory()} 
                    disabled={isWorking}
                  />

                  <Dropdown trigger={["click"]}
                    disabled={!optionActionItems.length}
                    menu={{items:optionActionItems}}
                  >
                    {/* this div is to avoid a warning with strict mode */}
                    <div>
                      <BMButton 
                        type="primary" 
                        disabled={!!getDisabledOptionsMsg()}
                        onDisabledClick={() => notifyDisabled(getDisabledOptionsMsg())}
                        icon={<MoreOutlined/>} 
                        data-testid="quote-options-btn"
                      >Options</BMButton>
                    </div>
                  </Dropdown>

                </Space>
              </Col>

              <Col>
                <Space>

                  {(!isReadOnly || beforeSplitChangeSelfSubmitted ) &&
                  <UndoQuoteModalButton
                    disabled={!!getDisabledUndoMsg()}
                    onDisabledClick={() => notifyDisabled(getDisabledUndoMsg())}
                  />
                  }

                  {(!isReadOnly || beforeSplitChangeSelfSubmitted ) &&
                  <BMButton
                    disabled={!!getDisabledSaveMsg()}
                    onDisabledClick={() => notifyDisabled(getDisabledSaveMsg())}
                    onClick={handleReset}
                  >
                    Reset
                  </BMButton>}

                  {(!isReadOnly || beforeSplitChangeSelfSubmitted || canChangeReadOnly ) &&
                  <BMButton
                    type="primary"
                    data-testid="quote-save-button"
                    disabled={!!getDisabledSaveMsg()}
                    onDisabledClick={() => notifyDisabled(getDisabledSaveMsg())}
                    onClick={handleSave}
                  >
                    Save
                  </BMButton>}

                  {/* create change order */}
                  {(isOrder && ((!isPending && hasSubmitOrderPermission) || canAddChangeOrderToSplit)) && <>
                    {( configurator.isEngineering() || configurator.isAdmin() ) 
                      ? 
                      <Dropdown
                        trigger={["click"]}
                        menu={{items:getChangeOrderDropdownItems()}}
                      >
                        {/* this div is to avoid a warning with strict mode */}
                        <div>
                          <BMButton 
                            type="primary" 
                            disabled={!!getChangeOrderDisabledMsg()}
                            onDisabledClick={() => notifyDisabled(getChangeOrderDisabledMsg())}
                            icon={<MoreOutlined/>}
                            data-testid="create-change-dropdown"
                          >Create Change Order</BMButton>
                        </div>
                      </Dropdown>
                      : 
                      <CreateSalesChangeButton 
                        disabled={!!getChangeOrderDisabledMsg()}
                        onDisabledClick={() => notifyDisabled(getChangeOrderDisabledMsg())}
                        onChange={reloadQuote}
                      >
                        Create Change Order
                      </CreateSalesChangeButton>
                      
                    }
                  </>}

                  {(!isReadOnly && isDraft && ( (isEngineering || isAdmin) && isEngineeringChangeOrder ) ) && 
                    <AbandonEngineeringChangeButton 
                      disabled={!!getDisabledAbandonMsg()}
                      onDisabledClick={() => notifyDisabled(getDisabledAbandonMsg())}
                      onChange={reloadQuote}
                  />}

                  {(!isReadOnly && isDraft && ( (isEngineering || isAdmin) && isEngineeringChangeOrder ) ) && 
                    <SubmitEngineeringChangeButton 
                      disabled={!!getDisabledSubmitMsg()}
                      onDisabledClick={() => notifyDisabled(getDisabledSubmitMsg())}
                      onChange={reloadQuote}
                  />}

                  {(!isReadOnly && isDraft && isSalesChangeOrder ) && 
                    <AbandonSalesChangeButton 
                      disabled={!!getDisabledAbandonMsg()}
                      onDisabledClick={() => notifyDisabled(getDisabledAbandonMsg())}
                      onChange={reloadQuote}
                  />}

                  {(!isReadOnly && isDraft && isSalesChangeOrder && hasSubmitOrderPermission ) && 
                    <SubmitSalesChangeButton 
                      disabled={!!getDisabledSubmitMsg()}
                      onDisabledClick={() => notifyDisabled(getDisabledSubmitMsg())}
                      onValidate={handleValidateForm} 
                      onChange={reloadQuote}
                  />}

                  {(!isReadOnly && isDraft && isReviseQuote ) && 
                    <AbandonReviseQuoteButton 
                      disabled={!!getDisabledAbandonMsg()}
                      onDisabledClick={() => notifyDisabled(getDisabledAbandonMsg())}
                      onChange={reloadQuote}
                  />}

                {(isReadOnly && isEffectiveRevision && isRevisionApproved && !isPendingApproval && !isOrder ) && 
                  <>
                    <ReviseQuoteButton
                      disabled={!!getDisabledReviseQuoteMsg()}
                      onDisabledClick={() => notifyDisabled(getDisabledReviseQuoteMsg())}
                      onClick={handleReviseQuote}
                    >
                      Revise Quote
                    </ReviseQuoteButton>

                    {(hasSubmitOrderPermission && isEffectiveRevision && !quote?.reservation ) && 
                      <SubmitOrderButton 
                        disabled={!!getDisabledSubmitOrderMsg()}
                        onDisabledClick={() => notifyDisabled(getDisabledSubmitOrderMsg())}
                        onValidate={handleValidateForm} 
                        onChange={reloadQuote}
                      />}
                  </>}

                  {(isRevisionApproved && !isPendingApproval && !isOrder && hasSubmitOrderPermission && quote?.reservation ) && 
                    <ConvertReservationButton
                      disabled={!!getDisabledConvertReservationMsg()}
                      onDisabledClick={() => notifyDisabled(getDisabledConvertReservationMsg())}
                      onChange={reloadQuote}
                  />}

                  {(!isReadOnly && !isOrder && !isRevisionApproved && !isPendingApproval && hasSubmitQuotePermission ) && 

                    <>
                      {( configurator.isInternalSales() ) 
                        ? <Dropdown
                          trigger={["click"]}
                          menu={{items:[
                            { 
                              key:"submitQuote",
                              label: <SubmitQuoteButton 
                                type="text"
                                disabled={!!getDisabledSubmitMsg()}
                                onDisabledClick={() => notifyDisabled(getDisabledSubmitMsg())}
                                onValidate={handleValidateForm} 
                                onChange={reloadQuote}
                              />
                            },
                            {
                              key:"submitQuoteReservation",
                              label: <SubmitQuoteButton 
                                type="text"
                                disabled={!!getDisabledSubmitMsg()}
                                onDisabledClick={() => notifyDisabled(getDisabledSubmitMsg())}
                                onValidate={handleValidateForm} 
                                onChange={reloadQuote}
                                isReservation={true}
                              />
                            }
                          ]}}
                        >
                          {/* this div is to avoid a warning with strict mode */}
                          <div>
                            <BMButton 
                              type="primary" 
                              disabled={!!getChangeOrderDisabledMsg()}
                              onDisabledClick={() => notifyDisabled(getChangeOrderDisabledMsg())}
                              icon={<MoreOutlined/>}
                            >Submit</BMButton>
                          </div>
                        </Dropdown>
                        : 
                        <SubmitQuoteButton 
                          disabled={!!getDisabledSubmitMsg()}
                          onDisabledClick={() => notifyDisabled(getDisabledSubmitMsg())}
                          onValidate={handleValidateForm} 
                          onChange={reloadQuote}
                        />}
                    </>}

                </Space>
              </Col>

            </Row>

            {isAdminOrEngineering && <>

              {(epicorSyncStatus?.erpSyncStatus == EpicorSyncStatus.FAILURE ) &&
                <Alert type="error"
                  closable={true}
                  message={<>
                    <span>The export to epicor has failed.</span>
                    {!!epicorSyncStatus.erpSyncMessage?.length && <>
                      &nbsp;&nbsp;<span>{epicorSyncStatus?.erpSyncMessage}.</span>
                    </>}
                  </>}
                />
              }
              {(epicorSyncStatus?.erpSyncStatus == EpicorSyncStatus.PENDING ) &&
                <Alert type="error" message="The export to epicor is pending." closable={true} />
              }
            </>}

            {(quote?.latestApproval?.action === ApprovalAction.REJECTED && quote.latestApproval.revisionId === quote.displayRevisionId && !isPendingApproval ) &&
              <Alert type="error" message={<>
                <div>{isOrder ? "These changes have" : "This quote has"} been rejected by {!!quote.latestApproval.actionedBy && <span>{quote.latestApproval.actionedBy.name}</span>}.</div>
                {!!quote.latestApproval.actionNotes?.length && <div style={{padding: ".4rem"}}>Details: {quote.latestApproval.actionNotes}</div> }
                <div>For more information contact your sales engineer or sales desk.</div>
              </>}
              />
            }

            <Tabs 
              activeKey={tabKey}
              onTabClick={(key) => onTabClick(key)}
              items={tabLst}
            />
          </div>
        </Col>
      </div>

    </div>
    </QuoteAssemblyExceptionContext.Provider>
    </CustomOptionsContext.Provider>
    </ModelCategoryContext.Provider>
    </QuoteFormContextProvider>
    </QuoteContextProvider>
    <UnsavedChangesWarning isDirty={formDirty} />
  </div>);
}

const InventoryCheckbox = (props:CheckboxProps) => {

  const { quoteAsync } = useQuoteContext();
  const quote = quoteAsync?.val;

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);

  const convertToStock = async (quoteId:number, enabled:boolean) => {

    quoteAsync?.setLoading();
    try {

      const resp = await configurator.api.convertQuoteToStock(quoteId, enabled);
      quoteAsync?.setDone(resp.data);

    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to convert to stock. " + errorMsg });
      quoteAsync?.setFail(e.message);
    }
  }

  const handleConvertStock = () => {
    if ( !quote ) return;

    if ( !quote.stock ) {
      Modal.confirm({
        title: 'Are you sure?',
        icon: <ExclamationCircleFilled />,
        content: 'This will move the order to inventory where it can be re-sold.',
        onOk: () => convertToStock(quote.id, true),
      });
    }
    else {
      Modal.confirm({
        title: 'Are you sure?',
        icon: <ExclamationCircleFilled />,
        content: 'This will move the order out of inventory.',
        onOk: () => convertToStock(quote.id, false),
      });
    }

  }

  return <div style={{marginLeft: "1rem"}}>
    <label >
      <Checkbox
        {...props}
        checked={quote?.stock}
        onChange={() => handleConvertStock()}
        style={{padding:0}}
      />
      <span style={{marginLeft: "0.5rem"}}>Inventory</span>
    </label>
  </div>
}

export default Configurator;

