import { Col, Row } from "antd";
import { Assembly, BaseCategory, Category, CustomOptionType } from "../../api/models";
import AssemblySelectionTable from "./AssemblySelectionTable";
import Title from "antd/lib/typography/Title";


const QuoteTruckOptionsTab = (props: {
  readOnly?: boolean
  loading?: boolean
  disabled?: boolean
  selectedCategory: Category | undefined
  onSelectOption: (c:BaseCategory, o:Assembly | CustomOptionType)=>void
  onClearSelections: (c:BaseCategory)=>void
  filterOptionsQuery: string | undefined
  optionNotes:Record<number,string>
  onUpdateOptionNotes:(a:Assembly | undefined, note:string | undefined)=>void
  modelId: number | undefined
  percentDiscount: number | undefined
}) => {

  return (
    <>
      <Row>
            <Col span={24}>
              {!!props.selectedCategory 
                ? <Row gutter={16} style={{ paddingTop: "1%" }}>
                    <AssemblySelectionTable
                      {...props}
                    />
                  </Row>
                 : <Row justify={"center"}><Title level={5}>Select category to show assembly table.</Title></Row>
              }
            </Col>
      </Row>
    </>
  );
}

export default QuoteTruckOptionsTab;

