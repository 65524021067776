import "../util/mobile-table.css";
import styles from "./approvals.module.css";
import Title from "antd/lib/typography/Title";
import { Table, Input, Button, Space, TablePaginationConfig, Card, Tooltip, Modal, Select, Checkbox, notification, Row, Col, Form, Collapse, Divider } from "antd";
import { ColumnType } from 'antd/es/table';
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ConfiguratorContext, UserListContext, UsersContext } from "../context";
import { Link } from "react-router-dom";
import dayjs from 'dayjs'
import { ArrayParam, BooleanParam, NumberParam, StringParam, useQueryParam } from "use-query-params";
import Utils from "../util/util";
import { Approval, ApprovalFilter, ApprovalRequest, ApproverRole, AXIOS_CANCEL_MSG, BaseModel, GlobalAccessRole, PAGINATION_MAX_PAGE_SIZE, SortDirection, User } from "../api/models";
import {AsyncState, useAsyncState} from "../hook/useAsyncState";
import {FilterValue, SorterResult} from "antd/lib/table/interface";
import {debounce} from "lodash";
import useCheckMobileScreen from "../hook/useCheckMobileScreen";
import { LeftOutlined, InfoOutlined, PartitionOutlined, RightOutlined } from "@ant-design/icons";
import {useForm} from "antd/es/form/Form";
import {useIntl} from "react-intl";
import UserMultiSelector, {UserMultiSelectorProps} from "../components/UserMultiSelector";
import axios, {CancelTokenSource} from "axios";
import { AdvancedSearchConfig } from "../components/QuoteFilterControls";

type BaseApprovalSort = SorterResult<Approval> | SorterResult<Approval>[]
type TableOnChangeFn = (p: TablePaginationConfig, f: Record<string, FilterValue | null>, s: BaseApprovalSort) => void

const Approvals = () => {
  const isMobile = useCheckMobileScreen();
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  const [_approvalList, approvalListAsync] = useAsyncState<Approval[]>();

  const [showWorkingNoteModal, setShowWorkingNoteModal] = useState<boolean>(false);
  const [workingNote, setWorkingNote] = useState<string>('');
  const [currentId, setCurrentId] = useState<number>(0);

  const [pageSizeQueryParam, setPageSizeQueryParam] = useQueryParam<number | undefined | null>("nr", NumberParam);
  const [currentPageParam, setCurrentPageParam] = useQueryParam<number | undefined | null>("p", NumberParam);

  const [searchFilterParam, setSearchFilterParam] = useQueryParam<string | undefined | null>("search", StringParam);
  const [actionedParam, setActionedParam] = useQueryParam<boolean | undefined | null>("actioned", BooleanParam);
  const [engineersParam, setEngineersParam] = useQueryParam<(string | null)[]|undefined|null>("engineers", ArrayParam);
  const [salesParam, setSalesParam] = useQueryParam<(string | null)[]|undefined|null>("sales", ArrayParam);
  const [requestedByParam, setRequestedByParam] = useQueryParam<(string | null)[]|undefined|null>("requestedBy", ArrayParam);
  const [approvalTypeParam, setApprovalTypeParam] = useQueryParam<(string | null)[]|undefined|null>("approvalType", ArrayParam);
  const [approverRoleParam, setApproverRoleParam] = useQueryParam<(string | null)[]|undefined|null>("approverRole", ArrayParam);
  const [modelsParam, setModelsParam] = useQueryParam<(string | null)[]|undefined|null>("models", ArrayParam);
  const cancelTokenSourceRef = useRef<CancelTokenSource>();

  const [sort, setSort] = useState<BaseApprovalSort>({
    columnKey: 'createdAt',
    order: 'descend'
  });

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    position: ["topLeft", "bottomLeft"],
    pageSize: pageSizeQueryParam == null || pageSizeQueryParam > 500 ? 20 : pageSizeQueryParam,
    current: currentPageParam == null || currentPageParam < 1 ? 1 : currentPageParam,
    showLessItems: isMobile,
  });

  const defaultEngineerLst = ( configurator.isEngineering() && !configurator.isAdmin() ) && configurator.userInfo ? [configurator.userInfo.name] : [];
  const defaultSalesDeskRoleLst = ( configurator.isSalesDesk() && !configurator.isAdmin() ) && [ GlobalAccessRole.SALES_DESK.toUpperCase() ];
  const [filter, setFilter] = useState<ApprovalFilter>({
    actioned: actionedParam || undefined,
    search: searchFilterParam || undefined, //silly fix for null
    approverRole: [approverRoleParam as (string|null) || defaultSalesDeskRoleLst || []].flat(),
    approvalType: [approvalTypeParam as (string|null) || []].flat(),
    engineers: [engineersParam as (string|null) || defaultEngineerLst ].flat(),
    sales: [salesParam as (string|null) || []].flat(),
    requestedBy: [requestedByParam as (string|null) || []].flat(),
    models: [modelsParam as (string|null) || []].flat(),
  });


  useEffect(() => {
    loadQuoteApprovals(approvalListAsync, pagination, filter, sort);
  }, [pagination.pageSize, pagination.current, filter, sort]);

  useEffect(() => {
    setPageSizeQueryParam(pagination.pageSize);
    setCurrentPageParam(pagination.current);
  }, [pagination.pageSize, pagination.current]);


  const loadQuoteApprovals = useCallback(debounce( async (approvalListAsync:AsyncState<Approval[]>,  pagination: TablePaginationConfig, filter: ApprovalFilter, sorter:BaseApprovalSort) => {

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    const sort = [sorter].flat().map( sorter => ({
      field: sorter.columnKey?.toString() || "createdAt",
      direction: ( sorter.order === 'ascend' ? 'asc' : 'desc') as SortDirection,
    }));

    approvalListAsync.setLoading();
    try {
      const resp = await configurator.api.listQuoteApprovals({
          ...filter,
          page: (pagination.current || 1) - 1,
          size: pagination.pageSize || 20,
          sort,
        },
        cancelSource.token
      )
      cancelTokenSourceRef.current = undefined;

      approvalListAsync.setDone(resp.data.content);
      setPagination({ ...pagination, total: resp.data.totalElements });
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Quote approvals failed to load. " + errorMsg });
        approvalListAsync.setFail(e.message);
      }
    }
  }, 400), []);


  const onEditWorkingNote = (id: number) => {
    const approvalList = approvalListAsync.val;
    setCurrentId(id);
    setShowWorkingNoteModal(true);
    setWorkingNote(approvalList?.filter(a => a.id === id)[0].workingNote || '');
  };

  const saveWorkingNote = async () => {
    const resp = await configurator.api.saveWorkingNote(
      currentId,
      { workingNote: workingNote }
    );
    loadQuoteApprovals(approvalListAsync, pagination, filter, sort);
    if (resp) {
      setShowWorkingNoteModal(false);
      setCurrentId(0);
      setWorkingNote('');
    }
  }

  const tableOnChange = (pagination: TablePaginationConfig, _filters: Record<string, FilterValue | null>, sorter: BaseApprovalSort) => {
    setPagination(pagination);

    setSort(sorter);
  };

  const onFilterChange = debounce( (_values: Record<string, any>, filter:ApprovalFilter) => {
    setPagination({ ...pagination, current: 1 });

    setSearchFilterParam(filter.search)
    setActionedParam    (filter.actioned)
    setEngineersParam   (filter.engineers)
    setSalesParam      (filter.sales)
    setRequestedByParam (filter.requestedBy)
    setApprovalTypeParam(filter.approvalType)
    setApproverRoleParam(filter.approverRole)
    setModelsParam(filter.models)

    setFilter(filter);
  }, 300);


  return <>
    <div className="site-layout-background">
      <Title level={2}>Approvals</Title>
      <Space direction="vertical" size="middle"
        style={{ width: "100%" }}
      >
        <FilterControls
          filter={filter}
          onFilterChange={onFilterChange} />

        {isMobile ?
        (<>
          <MobileTable
          approvalListAsync={approvalListAsync}
          tableOnChange={tableOnChange}
          pagination={pagination}
          />
        </>) : 
        (<>
          <DesktopTable
            approvalListAsync={approvalListAsync}
            tableOnChange={tableOnChange}
            pagination={pagination}
            showWorkingNoteModal={showWorkingNoteModal}
            setShowWorkingNoteModal={setShowWorkingNoteModal}
            onEditWorkingNote={onEditWorkingNote}
            saveWorkingNote={saveWorkingNote}
            workingNote={workingNote}
            setWorkingNote={setWorkingNote}
          />
        </>)
        }
      </Space>
    </div>
  </>;
};

const DesktopTable = (props: {
  approvalListAsync: AsyncState<Approval[]>;
  tableOnChange: TableOnChangeFn;
  pagination: TablePaginationConfig;
  showWorkingNoteModal: boolean;
  setShowWorkingNoteModal: (value: boolean) => void;
  onEditWorkingNote: (id: number) => void;
  saveWorkingNote: () => void;
  workingNote: string;
  setWorkingNote: (value: string) => void;
}) => {
  const { approvalListAsync, tableOnChange, pagination, showWorkingNoteModal, setShowWorkingNoteModal, onEditWorkingNote, saveWorkingNote, workingNote, setWorkingNote } = props;
  const approvalList = approvalListAsync.val;

  const columns:ColumnType<Approval>[] = [
    {
      title: "Quote Name",
      key: "revision.name",
      render: (q:Approval) => 
        <Row key={q.quoteId + q.quoteName} gutter={24}>
          <Col span="20">
            <span>{q.quoteName}</span>
            {q.pendingSplit && <Tooltip title="Split"><PartitionOutlined style={{color: "#1677ff", marginLeft: "10px"}}/></Tooltip>}
          </Col>
          <Col span="4" style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
          {
            <div key={`${q.quoteId}-working-note`}>
              <style>
                {`
                  .add-working-note-button:hover {
                    color: white !important;
                    background-color: #1677ff !important;
                  }
                  .add-working-note-button {
                    color: #1677ff !important;
                    border: 2px solid #1677ff !important;
                  }

                  .working-note-button:hover {
                    color: white !important;
                    background-color: orange !important;
                    border: 2px solid orange !important;
                  }
                  .working-note-button {
                    color: orange !important;
                    border: 2px solid orange !important;
                  }
                `}
              </style>
              {q.workingNote ?
                <Tooltip title={q.workingNote}>
                {q.workingNote && String(q.workingNote).trim() !== '' && <Button className="working-note-button" shape="circle" icon={<InfoOutlined /> } onClick={() => onEditWorkingNote(q.id)} size='small'></Button>}
              </Tooltip>
               :
              <Tooltip title={'Edit working note.'}>
                {<Button className="add-working-note-button" shape="circle" icon={<LeftOutlined />} size='small' onClick={() => onEditWorkingNote(q.id)}></Button>}
              </Tooltip>}
            </div>
          }
          </Col>
        </Row>,
      sorter: true,
    },
    {
      title: "Quote",
      key: "quote.quoteId",
      sorter: true,
      render: (q:Approval) => (
        <>
          <div key={`${q.quoteId} - id`}>
            <Link
              target="_blank"
              to={"/configurator/" + encodeURI(q.quoteId)}
            >
              {q.quoteId}
            </Link>
          </div>
          {q.pendingSplit?.partners?.map(p => 
            <div key={`${p.quoteId} - id`}>
              <Link
                target="_blank"
                to={"/configurator/" + encodeURI(p.quoteId)}
              >
                {p.quoteId}
              </Link>
            </div>
          )}
        </>
      ),
    },
    /*
    {
      title: "Model",
      key: "revision.model.name",
      sorter: true,
      render: (q:Approval) => q.model.name
    },
     */
    {
      title: "Approval Required By",
      key: "approverRole",
      sorter: true,
      render: (q:Approval) => Utils.snakeCaseToFirstLetterCapitalized(q.approverRole)
    },
    {
      title: "Requested By",
      key: "requestedBy",
      sorter: true,
      dataIndex: "requestedBy",
      render: (user: User) => user?.name,
    },
    {
      title: "Requested On",
      key: "createdAt",
      sorter: true,
      render: (q:Approval) => (
        <span>{dayjs(q.createdAt).format("MMM Do YY, HH:mm")}</span>
      ),
    },
    {
      title: "Type",
      key: "approvalType",
      sorter: true,
      render: (q:Approval) => Utils.formatApprovalType(q.approvalType),
    },
    /*
    {
      title: "Engineer",
      key: "quote.engineer.name",
      sorter: true,
      render: (q:Approval) => Utils.formatUsername( q.quoteEngineer )
    },
    {
      title: "Sales",
      key: "quote.bmSalesperson.name",
      sorter: true,
      render: (q:Approval) => Utils.formatUsername(q.quoteSales)
    },
     */
    {
      title: "Actions",
      key: "actions",
      render: (q:Approval) => (
        <div>
          <Link to={"/approvals/" + q.id}>
            {!q.action &&
            <Button type="primary">Action</Button>
            }
            {q.action &&
            <Button type="primary">View</Button>
            }
          </Link>
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        loading={approvalListAsync.isLoading()}
        onChange={tableOnChange}
        bordered
        pagination={pagination}
        dataSource={approvalList}
        columns={columns}
        rowKey="id"
        size="small"
      />
      <Modal title="Edit Working Note"
        open={showWorkingNoteModal}
        maskClosable={false}
        onOk={() => saveWorkingNote()}
        onCancel={() => setShowWorkingNoteModal(false)}
        closable={!approvalListAsync.isLoading}
        okText="Save"
        bodyStyle={{ height: 480 }}
        width={590}
      >
        <Input.TextArea rows={4} placeholder={'Please enter working note here.'} value={workingNote} onChange={(e) => setWorkingNote(e.target.value)} />
      </Modal>
    </>
  );
}

const MobileTable = (props:{
  approvalListAsync:AsyncState<Approval[]>
  tableOnChange: TableOnChangeFn
  pagination:TablePaginationConfig
}) => {
  const { approvalListAsync, tableOnChange, pagination } = props;
  const approvalList = approvalListAsync.val;

  const columns:ColumnType<Approval>[] = [
    {
      render: (_val, approval, _ndx) => {
        return <>
          <Link to={`/approvals/${approval.id}`} style={{color:"black"}}>
            <Card 
              hoverable={true}
              title={<>
                <div className={styles["approval-type"]}>{Utils.formatApprovalType(approval.approvalType)}</div>
              </>
              } 
            >
              <div className={styles["quote-name"]}>{approval.quoteName}</div>
              <div className={styles["quote-id"]}>({approval.quoteId})</div>
              <div className="section">
                <div>Requested:</div>
                <div><span>{approval.requestedBy?.name}</span></div>
                <div><span>{dayjs(approval.createdAt).format("MMMM Do YYYY")}</span></div>
              </div>
            </Card>
          </Link>
        </>;
      },
    },
  ];

  return <Table
    loading={approvalListAsync.isLoading()}
    onChange={tableOnChange}
    pagination={pagination}
    dataSource={approvalList}
    columns={columns}
    rowKey="id"
    className="mobile-table"
  />
}

const FilterControls = (props: {
  filter?: ApprovalFilter
  onFilterChange: (_values: Record<string, any>, filter:ApprovalFilter) => void
}) => {
  const { filter } = props;

  const [filterForm] = useForm();
  const showAdvancedPanel = 
    filter?.approvalType?.length ||
    filter?.models?.length ||
    filter?.requestedBy?.length ||
    filter?.approverRole?.length ||
    filter?.sales?.length ||
    filter?.engineers?.length;

  const configurator = useContext(ConfiguratorContext);

  return <>
    <Form 
      initialValues={props.filter}
      form={filterForm} 
      onValuesChange={props.onFilterChange}
      layout="vertical"
    >
      <Row gutter={[16, 8]} align="middle">
        <Col span={18}>
          <Form.Item name="search" >
            <Input
              allowClear
              value={filter?.search}
              placeholder="Search by quote name, ID, and more."
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="actioned"
            valuePropName="checked"
          >
            <Checkbox>including actioned</Checkbox>
          </Form.Item>
        </Col>
      </Row>
        <Collapse
          style={{ width: '100%', marginTop: "-1rem" }} size="small"
          defaultActiveKey={showAdvancedPanel ? "advanced-search" : undefined }
          {...AdvancedSearchConfig}
          items={[{
            key: "advanced-search",
            label: <span style={{color: "#1677FF"}}>Advanced Search</span>,
            forceRender: true,
            children: <Space direction="vertical" style={{ width: '100%' }} size="middle">
              <Row gutter={[16, 8]}>

                {(configurator.isAdmin() || configurator.isSalesDesk() || configurator.isEngineering() || configurator.isReleaseEngineering() ) && 
                <Col span={6}>
                  <Form.Item
                    name="approverRole"
                    label="Approver Role"
                  >
                    <ApproverRoleSelector style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                }

                <Col span={6} >
                  <Form.Item
                    name="models"
                    label="Model"
                  >
                    <ModelSelector style={{ width: '100%' }} />
                  </Form.Item>
                </Col>

                <Col span={6} >
                  <Form.Item
                    name="approvalType"
                    label="Approval Type"
                  >
                    <ApprovalRequestSelector style={{ width: '100%' }} />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    name="requestedBy"
                    label="Requested By"
                  >
                    <UserNameSelector style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 8]} style={{marginTop: "-1rem"}}>
                <Col span={6}>
                  <Form.Item
                    name="sales"
                    label="Sales"
                  >
                    <QuoteSalesSelector  style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="engineers"
                    label="Engineer"
                  >
                    <InternalUserNameSelector  style={{ width: '100%' }} />
                  </Form.Item>
                </Col>

              </Row>
              <Divider/>
            </Space>
          }]}
        />
    </Form>
  </>
}

const ApproverRoleSelector = (props:  {
  value?: string
  onChange?: (role: string) => void
  style?: any
}) => {

  return (
    <Select
      onChange={props.onChange}
      defaultValue={props.value}
      allowClear
      mode="multiple"
      showSearch={true}
      style={props.style}
      optionFilterProp="label"
      options={ Object.values( ApproverRole ).map(role => ({
        value: role, 
        label: Utils.snakeCaseToFirstLetterCapitalized(role)
      }))}
    />
  );
};


const ApprovalRequestSelector = (props:  {
  onSelectQuoteStatus?: (status: string) => void
  value?: string
  onChange?: (statuses: string) => void
  style?: any
}) => {

  return (
    <Select
      onChange={props.onChange}
      defaultValue={props.value}
      allowClear
      mode="multiple"
      showSearch={true}
      style={props.style}
      optionFilterProp="label"
      options={ Object.values( ApprovalRequest ).map(approvalType => ({
        value: approvalType, 
        label: Utils.formatApprovalType(approvalType),
      }))}
    />
  );
};

interface UserSelectorProps extends Omit<UserMultiSelectorProps, "value" | "onChange"> {
  value?:string[]
  onChange?: (users: string[]) => void;
}
const QuoteSalesSelector = (props: UserSelectorProps) => {

  const configurator = useContext(ConfiguratorContext);

  const intl = useIntl();
  const { userLstAsync, loadUserList } = useContext<UserListContext>(UsersContext);
  const userLst = userLstAsync?.val;

  const [bmSalespersonLst, bmSalespersonLstAsync] = useAsyncState<User[]>();

  useEffect(()=> {
    if ( ( userLstAsync?.isInitial() || userLstAsync?.isFail() ) && !userLstAsync.isLoading() )  {
      loadUserList?.();
    }
  }, [ userLstAsync?.state ] );

  useEffect(() => {
    loadBmSales();
  }, [userLst])

  const user = userLst?.find( u => u.id === configurator.userInfo?.id );
  const loadBmSales = async () => {
    if ( !user?.dealerId ) return;

    bmSalespersonLstAsync.setLoading()

    try {
      const resp = await configurator.api.fetchDealerSales()
      bmSalespersonLstAsync.setDone(resp.data)
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to load salesperson. " + errorMsg });
      bmSalespersonLstAsync.setFail(e.message);
    };
  }

  const salesLst = user?.dealerId ? bmSalespersonLst : userLst;

  //transform names to user ids
  const valueSet = new Set(props.value);
  const value = ( salesLst?.filter( s => valueSet.has(s.name) ) || [] );

  return <UserMultiSelector {...props} 
    value={value}
    userLst={salesLst} 
    onChange={(lst) => props.onChange?.( lst?.map( s => s.name ) ) }
  />
};


const InternalUserNameSelector = (props: UserSelectorProps) => {

  const { userLstAsync, loadUserList } = useContext<UserListContext>(UsersContext);

  useEffect(() => {
    if ( ( userLstAsync?.isInitial() || userLstAsync?.isFail() ) && !userLstAsync.isLoading() ) {
      loadUserList?.();
    }
  }, [])

  const internalUserLst = userLstAsync?.val?.filter( u => !u.dealerId );

  //transform names to user ids
  const valueSet = new Set(props.value);
  const value = ( internalUserLst?.filter( s => valueSet.has(s.name)) || [] );

  return <UserMultiSelector {...props} 
    value={value}
    userLst={internalUserLst} 
    onChange={(lst) =>  props.onChange?.( lst.map( s => s.name ) )}
  />
}


const UserNameSelector = (props: UserSelectorProps) => {

  const { userLstAsync, loadUserList } = useContext<UserListContext>(UsersContext);

  useEffect(() => {
    if ( ( userLstAsync?.isInitial() || userLstAsync?.isFail() ) && !userLstAsync.isLoading() ) {
      loadUserList?.();
    }
  }, [])

  const internalUserLst = userLstAsync?.val;

  //transform names to user ids
  const valueSet = new Set(props.value);
  const value = ( internalUserLst?.filter( s => valueSet.has(s.name)) || [] );

  return <UserMultiSelector {...props} 
    value={value}
    userLst={internalUserLst} 
    onChange={(lst) =>  props.onChange?.( lst.map( s => s.name ) )}
  />
}

const ModelSelector = (props:  {
  value?: string 
  onChange?: (statuses: string) => void
  style?: any
}) => {

  const [modelLst, modelLstAsync] = useAsyncState<BaseModel[]>();
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  useEffect(() => {
    loadModels();
  }, []);


  const loadModels = async () : Promise<BaseModel[] | undefined> => {
    modelLstAsync.setLoading();

    try {

      //todo - replace with AbortController
      //https://axios-http.com/docs/cancellation
      const resp = await configurator.api.listModels( {
        size: PAGINATION_MAX_PAGE_SIZE,
        sort: { field: 'name', direction: 'asc' },
      });
      modelLstAsync.setDone(resp.data.content);
      return resp.data.content;
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to load models. " + errorMsg });
      modelLstAsync.setFail(e.message);
    };
    
    return;
  };


  return (
    <Select
      onChange={props.onChange}
      defaultValue={props.value}
      allowClear
      mode="multiple"
      showSearch={true}
      style={props.style}
      optionFilterProp="label"
      options={ modelLst?.map(model => ({
        value: model.id,
        label: model.name
      }))}
    />
  );
};


export default Approvals;
