import { Dayjs } from 'dayjs'
import { plainToClass, plainToClassFromExist } from "class-transformer";
import { BadRequestError, NotFoundError, PermissionError } from "./errors";
import { Assembly, ChangeOrderVerificationRequest, CreateUserRequest, EpicorExportPartsRequest, EpicorExportPartsResponse, ExternalUser, 
  Model, ModelComputeOptionsResponse, ModelYear, Page, PricingConfig, PricingSnapshot, 
  Quote, SyncStatus, SystemConfig, UpdateSystemConfigRequest, User, Approval, Operation, Category, CustomOptionType, BaseQuote, AssemblyInfo, PricingBreakdown,
  Performance, UpdateAssemblySnapshotCostsRequest, EpicorCustomersResponse, EpicorExportSalesOrderRequest, EpicorExportSalesOrderResponse, AssemblyCost, Customer, 
  ShippingDestination, ApprovalHistory, SortRequestParam, RevisionChangeDto, ApprovalDiff, ListUsersRequest, Dealer, RuleSet, DealerAdjustment, NonDiscountOption, 
  DashTemplate, DashComponent, TemplateRelatedQuote, QuoteWithMissingDashComponent, SourcewellLetterParameters, DashDrawingUrl, UpdateRuleRequest, CreateRuleRequest, 
  CreateGlobalExpressionRequest, UpdateGlobalExpressionRequest, DeleteRuleRequest, CreateRuleSetRequest, IncentiveProgram, NeedVerifyDash, CustomOptionProcessingStatus, 
  PoNumber, ComponentType, EpicorSalesOrder, ModelWithBasicAssemblyInfo, QuoteStatusVerification, QuoteAssemblyException, BaseModel, Truck, 
  UploadHistory, AddressDto, DefaultAddress, SplitQuoteDto, BaseCategory, QuoteInfo, ChangeLeadTime, InternalUser, ApprovalAction, FilterOption, WorkflowDto, QuoteComment, CommentTopic, QuoteReview, AssemblyHistory,
  WorkflowStep,
  NewQuoteComment,
  TruckDateFilterType,
  CustomOptionApplyStatus,
  QuoteShare,
  NotificationType,
  CustomWorkReview,
  EngineeringTeam,
  WorkflowStepRequest,
  WorkflowRequest,
  OperationsRemapResponse,
  UserPreference,
  TableFilter,
  OptionFilter,
  AssemblyOperation,
  BtsReport,
  RepresentativeSalesSupport,
  RepresentativeSalesSupportRequest,
  BTS_REPORT_STATUS,
  QuoteAuditInfo,
  QuoteAuditData,
  ListMasterScheduleResponse,
  listMasterScheduleResponse,
  UpdatePilotInspectionRequest,
  SalesTeam,
  PAGINATION_MAX_PAGE_SIZE,
  UserSalesTeam,
  NON_DISCOUNTED_TYPE,
  CategoryMetadata,
  ParentQuoteHistoryDto,
} from "./models";
import axios, {AxiosInstance, AxiosResponse, CancelToken, CancelTokenSource} from "axios";
import Utils from "../util/util";
import {SalesOrderQuote} from "../pages/reports/salesOrdersReport";
import {ApprovalsReportData} from '../pages/reports/approvalsReport';
import {PricingReportData} from '../pages/reports/pricingReport';
import {RequestedShipping} from '../components/Quote/CustomerShippingInfo';
import { TruckReportDto } from '../pages/reports/truckReport';
import { TruckCalendarDto } from '../pages/production/master_schedule_view';

export interface CustomWorkTeamRequest {
  quoteId:number
  categoryId:number
  primaryEngineerId?:string
  engineerIds?:string[]
}

export interface UpdateCategoryRequest {
  metadata: CategoryMetadata[];
  allowMultiple: boolean;
  name: string
  dependencyRules?: string; //CategoryRuleConfig[]
  configuratorSection: string
  quoteSection?: string;
  nonDiscounted: boolean;
  markup?: number;
  nonDiscountedType?: NON_DISCOUNTED_TYPE;
  operationId: string | undefined;
  excludeERPExport: boolean;
  hidden?: boolean;
  authorizedCustomers?: string[];
  stock: boolean
  leadTimeDays: number
  applyDesignProcurementTime?: boolean;
  designTimeWeeks: number
  procurementTimeWeeks: number
  engineeringTeamId?:number | undefined
  primaryEngineerId?:string | undefined
  isCab?: boolean | undefined
}


export interface UpdateDealerRequest {
  name?: string
  primaryContactId?:number
  parentDealerId?: string
  salesTeams?: SalesTeamRequest[]
  externalContacts?: string[]
}

export interface SaveTruckRequest {
    truckSerialNumberStr: string
    notes?: string;
    purchaseOrder?:string
    physicalLocation?:string
}

export interface QuoteAuditInfoRequest {
  quoteId: string
  auditId?: number | undefined
  page: number
  size: number
  sort?:SortRequestParam | SortRequestParam[]
}

export interface BtsReportRequest {
  search?: string
  page: number
  size: number
  sort?:SortRequestParam | SortRequestParam[]
}


interface QuoteReviewOptions { 
  includeAllCustomOptions?:boolean,
  dealerView?:boolean,
  categoryId?:number,
  selectedModelId?:number
  selectedOptions?:number[]
  selectedCustomOptions?:number[]
}

export interface EngineeringTeamRequest {
  id: number
  name?:string | undefined
  categories?: number[] | undefined
  members?: string[] | undefined
}
export type NewEngineeringTeamRequest = Omit<EngineeringTeamRequest, 'id'>;

export interface SalesTeamMemberRequest {
  id: number
  userId:string
  role:string
}
export type NewSalesTeamRequestMember = Omit<SalesTeamMemberRequest, 'id'>;

export interface SalesTeamRequest {
  id: number
  name?:string
  engineers?: string[]
  sales?: string[]
  support?: string[]
}
export type NewSalesTeamRequest = Omit<SalesTeamRequest, 'id'>;

export interface NewQuoteShare { 
  userId:string, writePermission:boolean 
}

interface AssemblyReplacementRequest {
  removeAssemblyId: number
  addAssemblyIdLst: number[]
  reason:string | undefined
}

export interface AssemblyRequest {
  metadata: AssemblyMetadataRequest[] | undefined
  restrictions: number[] | undefined
  dependencies: number[] | undefined
  operations: AssemblyOperation[] | undefined
  dealerExclusives: string[] | undefined
  label?: string | undefined
  dependencyRules: string
  standardMaterialCost?: number | undefined
  obsoleted?: boolean | undefined
  noOption?: boolean | undefined
  selectionRequiresUserInput: boolean
  labelWizard?: string | undefined
  descriptionWizard?: string | undefined
  replacementAssemblyId: number | undefined
  notes?:string | undefined
  changeReason:string
  id?: number | undefined
  bom?: string | undefined
  bomDescription?: string | undefined
  modelIds?: number[] | undefined
}
export interface AssemblyMetadataRequest {
  id?:number
  categoryMetadataId:number
  valueText?:string
  valueBool?: boolean
  valueNumeric?: number
  valueDecimal?: number
}

export interface ReplaceCostsRequest {
  id:number
  replacementCost?:number
}

export interface AssemblyCostFilter {
  query?:string
  showCfOnly?: boolean
}


export interface ListQuotesRequest {
  search?: string
  includingArchived?: boolean
  myQuotes?: boolean
  incentivePrograms?: string[]
  page: number
  size: number
  quoteStatus?: string[]
  dealerLst?: string[]
  ordersOnly?: boolean
  sort?:SortRequestParam | SortRequestParam[]
  filterAssemblies?: number[]
  includeSelectionSummary?: boolean
  selectionCategories?: number[]
  salespersons?: string[]
  engineers?: string[]
  filterStartDate?: Dayjs
  filterEndDate?: Dayjs
  endCustomerId?: number[]
  shippingDestinationId?: number[]
  truckDescription?: string
  customerEntityTypes?: string[]
  review?:boolean
  rowPerUnit?: boolean
  hasDraftRevision?: boolean
  primarySalesId?: string
  stock?:boolean
}

export interface ListQuotesResponse extends BaseQuote {
  trucks: Truck[] | undefined;
  endCustomer?: Customer;
  dealerPrice?: number;
  shippingDestination?: ShippingDestination;
}

const listParam = (lst:any[]|undefined) => lst?.length ? lst?.join(',') : undefined;
const formatListQuoteRequestFilter = (request:ListQuotesRequest) => ({
  ...request,
  filterAssemblies      : listParam( request.filterAssemblies),
  salespersons          : listParam( request.salespersons),
  engineers             : listParam( request.engineers),
  endCustomerId         : listParam( request.endCustomerId),
  shippingDestinationId : listParam( request.shippingDestinationId),
  selectionCategories   : listParam( request.selectionCategories),
  incentivePrograms     : listParam( request.incentivePrograms),
  dealerLst             : listParam( request.dealerLst),
  customerEntityTypes   : listParam( request.customerEntityTypes),
  quoteStatus           : listParam( request.quoteStatus),
})

export interface ListTruckRequest {
  sort?:SortRequestParam | SortRequestParam[]
  search?: string
  page: number
  size: number
  dateFilterType?: TruckDateFilterType
  dateFilterStart?: Dayjs
  dateFilterEnd?: Dayjs
  entityTypeList?: string[]
  export?: boolean
  dealerLst?:string[]
}

export interface ListModelsRequest {
  search?: string
  incentivePrograms?: string[]
  assemblies?: number[]
  cabStyles?: string[]
  fuelTypes?: string[]
  inactive?: boolean
  page?: number
  size?: number
  sort?:SortRequestParam | SortRequestParam[]
  deleted?: boolean
  cancelToken?: CancelTokenSource
}
const formatListModelsRequestFilter = (request:ListModelsRequest | undefined) => ({
  ...request,
  assemblies      : listParam( request?.assemblies),
  incentivePrograms     : listParam( request?.incentivePrograms),
  cabStyles     : listParam( request?.cabStyles),
  fuelTypes     : listParam( request?.fuelTypes),
})



export interface ComputePricingRequest {
  currentSelections?: number[]
  shippingDestinationId?: number
  pricingSnapshotId?: number
  percentDiscount?: number
  dealerAdjustments?: DealerAdjustment[]
  nonDiscountOptions?: NonDiscountOption[]
  customOptions?: number[]
}

export interface SaveDto {
  name: string
  modelId: number | undefined
  selections: number[]
  quantity: number
  shippingDestination?: number
  salesRequests?: string
  gvwrCap?: number | undefined
  endCustomerId?: number
  percentDiscount?: number 
  notes?: string
  assemblyNotes?: Record<string, string>
  archived?: boolean
  dealerAdjustmentList?: DealerAdjustment[]
  nonDiscountOptionList?: NonDiscountOption[]
  incentivePrograms?: string[]
  customOptions?: number[] | undefined
  salesTeam?: SalesTeamRequest | undefined
}

export interface ChangeOrderRequestDto extends SaveDto {
  customerSerialNumber?: string;
  customerNote?: string;
}

export interface EngineeringChangeSaveDto extends SaveDto {
  changeOrderRevisionId: number
}

export interface SalesChangeOrderSaveDto extends SaveDto {
  changeOrderRevisionId: number
}

export interface AssemblyFilterOptions {
  imported?: boolean
  isDashAssembly?: boolean
  page?: number
  size?: number
  hideObsolete?: boolean
  categoryId?: number
  categoryIdStr?: string
  modelId?:number
  filterQuery?: string
  filterMetadataQuery?: string
  sortFields?: string
  sortDirections?: string
  sortColumnValues?: string
  filterFields?: string,
  filterValueNumbers?: string,
  filterValues?: string,
  filterValueColumns?: string,
}

export interface QuoteStatusDto {
  quoteStatus?: string;
  isChangeOrderPending?: boolean,
  isEngineeringChangePending?: boolean,
  isSplitOrderPending?: boolean,
  isPendingApproval?: boolean,
  latestRevision?: number,
}

export interface ListApprovalsRequest {
  search?: string
  page: number
  size: number
  sort?:SortRequestParam | SortRequestParam[]
  requestedBy?: string[] | undefined
  requiredBy?: string[] | undefined
  engineers?: string[] | undefined
  sales?: string[] | undefined
  approvalType?: string[] | undefined
  approverRole?: string[] | undefined
  actioned?: boolean | undefined
  models?: string[] | undefined
}
const formatListApprovalRequestFilter = (request:ListApprovalsRequest) => ({
  ...request,
  requestedBy: listParam( request.requestedBy ),
  requiredBy: listParam( request.requiredBy ),
  engineers: listParam( request.engineers ),
  sales: listParam( request.sales ),
  approvalType: listParam( request.approvalType ),
  approverRole: listParam( request.approverRole ),
  models: listParam( request.models ),
})

export interface CustomOptionRequest {
  id?:number
  key:string
  content?: string
  quoteRevisionId:number | undefined
  included?:boolean
  categoryId: number | undefined
  applyStatus?: CustomOptionApplyStatus
  appliedAssemblies?: string
  price?: number
  note?:string

  standardMaterialCost?: number
  laborHours?: number
  metadata?: AssemblyMetadataRequest[]
}

export interface CustomOptionMetadataRequest {
  id?:number
  categoryMetadataId:number
  valueText?:string
  valueBool?: boolean
  valueNumeric?: number
  valueDecimal?: number
}


export interface QuoteCommentRequestOptions {
  showHidden?:boolean, 
  topic?: CommentTopic[]
}
class ConfiguratorAPI {
  baseUrl = "";
  axios: AxiosInstance;


  constructor(baseUrl:string) {
    this.baseUrl = baseUrl;
    this.axios = axios.create({
      withCredentials: true,
    });
    this.axios.interceptors.response.use(undefined, async (err) => {
      if (err && !err.response) {
        throw err;
      }
      if (err && err.response.status == 401) {

        Utils.saveNavigation();

        window.location.href = this.baseUrl + "/oauth2/authorization/auth0";
        return;
      }
      if (err && err.response.status == 404) {
        throw new NotFoundError();
      }
      if (err && err.response.status == 403) {
        throw new PermissionError();
      }
      if (err && err.response.status == 400) {

        //handle blob errors
        let errorData = err.response.data;
        if ( errorData instanceof Blob ) {
            errorData = JSON.parse( await err.response.data.text() );
        }

        throw new BadRequestError(
          errorData ? errorData.code : "",
          errorData ? errorData.message : ""
        );
      } else {
        throw err;
      }
    });
  }

  getAssemblyCosts( opt: AssemblyCostFilter & {
      page: number | undefined,
      size: number | undefined,
    },
    cancelToken?: CancelToken
  ) : Promise<AxiosResponse<Page<AssemblyCost>>> {
    return this.axios.get( this.baseUrl + "/v1/assembly/pendingCosts", { 
      params: opt,
      cancelToken
    }
    );
  }

  listStatuses(includingCancelledOrder: boolean): Promise<AxiosResponse<string[]>> | undefined {
    return this.axios.get(this.baseUrl + '/v1/quotes/statuses', {
      params: {
        includingCancelledOrder,
      },
    })
  }

  listSalesperson(): Promise<AxiosResponse<User[]>> {
    return this.axios.get(this.baseUrl + '/v1/quotes/salesperson');
  }

  acceptAssemblyCosts(idList:number[]) : Promise<AxiosResponse<AssemblyCost[]>> {
    return this.axios.post( this.baseUrl + "/v1/assembly/acceptCosts",
        {
        idList
        }
    );
  }

  rejectAssemblyCosts(idList:number[]) : Promise<AxiosResponse<AssemblyCost[]>>  {
    return this.axios.post( this.baseUrl + "/v1/assembly/rejectCosts",
        {
        idList
        }
    );
  }

  replaceAssemblyCosts(replacementLst:ReplaceCostsRequest[]) : Promise<AxiosResponse<AssemblyCost[]>>  {
    return this.axios.post( this.baseUrl + "/v1/assembly/replaceCosts",
        {
        replacementList: replacementLst
        }
    );
  }

  getImportedAssemblies= (cancelToken?: CancelTokenSource): Promise<AxiosResponse<Page<AssemblyInfo>>>  => {
    return this.axios.get(this.baseUrl + "/v1/assembly/allImportedAssemblies",
    {
      params: {
        page: 0,
        size: 9999999,
      },
      cancelToken: cancelToken?.token,
    }
  );
  }

  getPendingAssemblies(
    page?: number,
    size?: number,
    filterQuery?: string,
    cancelToken?: CancelToken
  ): Promise<AxiosResponse<Page<Assembly>>> {
    return this.getFilteredAssemblies({
      imported: false,
      isDashAssembly: false,
      page,
      size,
      categoryId: undefined,
      filterQuery,
    },
      cancelToken
    );
  }

  getFilteredAssemblies( params: AssemblyFilterOptions, cancelToken?:CancelToken): Promise<AxiosResponse<Page<Assembly>>> {
    return this.fetchFilteredAssemblies(params, cancelToken);
  }

  fetchFilteredAssemblies(params:AssemblyFilterOptions, cancelToken?: CancelToken): Promise<AxiosResponse<Page<Assembly>>> {
    return this.axios.get(this.baseUrl + "/v1/assembly",
      { params:{
        ...params,
        imported: params.imported ?? true //default to filtering out imported
      }, cancelToken, timeout: 30000 }
    );
  }

  getAssemblyDynamicFilterOptions(params: AssemblyFilterOptions, categoryId: number, metadataIds: number[], cancelToken?: CancelToken): Promise<AxiosResponse<FilterOption[]>> {
    return this.axios.get(this.baseUrl + `/v1/assembly/${categoryId}/getDynamicFilterOptions`,
      {
        params: {
          ...params,
          metadataIds: listParam(metadataIds),
          imported: params.imported ?? true
        }, cancelToken, timeout: 30000
      }
    );
  }

  epicorSyncStatus(opt: { page: number, size: number, filter?: string }) : Promise<AxiosResponse<Page<SyncStatus>>> {
    return this.axios.get( this.baseUrl + "/v1/epicor/sync-status", { 
      params: opt
    });
  }

  getAssemblyInCategory(categoryId: number | undefined, size?: number,  cancelToken?: CancelToken) : Promise<AxiosResponse<Page<AssemblyInfo>>> {
    return this.axios.get(this.baseUrl + "/v1/assembly/basicAssembly", {
      params: {
        categoryId,
        size,
      }, 
      cancelToken, 
      timeout: 30000
    }  )
  }

  resetPassword(userId:string, cancelToken?: CancelToken) : Promise<AxiosResponse<any>> {
    return this.axios.put( `${this.baseUrl}/v1/user-management/users/${encodeURIComponent(userId)}/resetPassword`, undefined, { cancelToken } );
  }

  async createUser(request: CreateUserRequest): Promise<void> {
    return this.axios.post(`${this.baseUrl}/v1/user-management/users`, request);
  }

  async updateUser(userId: string, request: CreateUserRequest): Promise<void> {
    return this.axios.put(`${this.baseUrl}/v1/user-management/users/${userId}`, request);
  }

  async enableUser(userId: string): Promise<void> {
    this.axios.post(this.baseUrl + '/v1/user-management/users/' + encodeURIComponent(userId) + '/enable');
  }

  async disableUser(userId: string): Promise<void> {
    this.axios.post(this.baseUrl + '/v1/user-management/users/' + encodeURIComponent(userId) + '/disable');
  }

  async listUsers(page: number, pageSize: number, request?: ListUsersRequest): Promise<Page<ExternalUser>> {
    const params: any = {
      page: page,
      size: pageSize,
    };

    if(request?.dealerId) params.dealerId = request.dealerId;
    if(request?.filter) params.filter = request.filter;

    const resp = await this.axios.get(this.baseUrl + '/v1/user-management/users', {
      params: params
    });

    return plainToClassFromExist(new Page<ExternalUser>(ExternalUser), resp?.data);
  }

  async epicorExportRevision(revisionId: number): Promise<void> {
    return this.axios.post(
      this.baseUrl +
        "/v1/epicor/export-revision/" + encodeURIComponent(revisionId)
    );
  }

  async epicorExportParts(request: EpicorExportPartsRequest): Promise<EpicorExportPartsResponse> {
    const resp = await this.axios.post(
      this.baseUrl +
        "/v1/epicor/export-parts", request
    );
    return plainToClass(EpicorExportPartsResponse, resp?.data);
  }

  async saveFlowStep(flow, step) {
    const resp = await this.axios.put(
      this.baseUrl +
        "/v1/configuration-admin/flows/" +
        encodeURIComponent(flow.flowId) +
        "/steps/" +
        encodeURIComponent(step.id),
      step
    );
    return resp?.data;
  }

  async createFlowStep(flow, step) {
    const resp = await this.axios.post(
      this.baseUrl +
        "/v1/configuration-admin/flows/" +
        encodeURIComponent(flow.flowId) +
        "/steps",
      step
    );
    return resp?.data;
  }

  async updatePackage(pkg) {
    const resp = await this.axios.put(
      this.baseUrl +
        "/v1/configuration-admin/packages/" +
        encodeURIComponent(pkg.id),
      pkg
    );
    return resp?.data;
  }

  async createPackage(pkg) {
    const resp = await this.axios.post(
      this.baseUrl + "/v1/configuration-admin/packages",
      pkg
    );
    return resp?.data;
  }

  async getPackages() {
    const resp = await this.axios.get(
      this.baseUrl + "/v1/configuration-admin/packages"
    );
    return resp?.data;
  }

  async getFlows() {
    const resp = await this.axios.get(
      this.baseUrl + "/v1/configuration-admin/flows"
    );
    return resp?.data;
  }

  async getUserInfo() {
    const resp = await this.axios.get(this.baseUrl + "/v1/user/info");
    return resp?.data;
  }

  getModelYears(page: number, pageSize: number): Promise<AxiosResponse<Page<ModelYear>>> {
    return this.axios.get(this.baseUrl + '/v1/models/years', {
      params: {
        page, size: pageSize
      }});
  }


  buildSortParam( sort?:SortRequestParam | SortRequestParam[]) : string {

    return this.buildSortParamBody(sort)
    .map( s => "sort=" + s ).join("&" );
  }

  buildSortParamBody( sort?:SortRequestParam | SortRequestParam[]) : string[] {

    return ( Array.isArray( sort ) ? sort : [ sort ] )
    .filter( v => v ) //remove undefined
    .map( s => [ s!.field, s!.direction ].join(",") )
  }

  getShippingDestinations(opt: {
    page?: number,
    size?: number,
    sort?:SortRequestParam | SortRequestParam[] | undefined,
    filter?:string
    },
    cancelToken?: CancelToken,
  ): Promise<AxiosResponse<Page<ShippingDestination>>> {

    const sortParam = this.buildSortParam( opt.sort );
    const url = this.baseUrl + "/v1/shipping-destination" + (sortParam.length ? "?" + sortParam : "") 

    return this.axios.get(url, {
      params: {
        ...opt,
        sort: undefined
      },
      cancelToken
    });
  }

  getShippingDestination(id:number) : Promise<AxiosResponse<ShippingDestination>>{
    return this.axios.get( this.baseUrl + "/v1/shipping-destination/"  + id);
  }

  updateShippingDestination(id:number, obj:any) : Promise<AxiosResponse<ShippingDestination>> {
    return this.axios.post( this.baseUrl + "/v1/shipping-destination/" + id,
      obj
    );
  }

  previewUpdateShippingDestination(id:number) : Promise<AxiosResponse<Array<BaseQuote>>> {
    return this.axios.get( `${this.baseUrl}/v1/shipping-destination/${id}/preview`);
  }

  deleteShippingDestination(id:number) : Promise<AxiosResponse<void>> {
    return this.axios.delete( this.baseUrl + "/v1/shipping-destination/" + encodeURIComponent(id));
  }

  async getDealers(cancelToken?: CancelToken) : Promise<Dealer[]>{
    const resp = await this.fetchDealerList({size: PAGINATION_MAX_PAGE_SIZE}, cancelToken)
    return resp.data.content;
  }

  fetchDealerList(
    request?:{
      page?: number,
      size?: number,
      sort?:SortRequestParam | SortRequestParam[],
      search?: string
      primarySalesId?: string
    },
    cancelToken?:CancelToken
  ): Promise<AxiosResponse<Page<Dealer>>> {

    const sort = this.buildSortParamBody( request?.sort ).join(',');

    return this.axios.get(`${this.baseUrl}/v1/dealer`, {
      params:{
        ...request,
        sort
      },
      cancelToken
    });
  }


  fetchDealer(id:string): Promise<AxiosResponse<Dealer>> {
    return this.axios.get( this.baseUrl + "/v1/dealer/" + encodeURIComponent(id));
  }

  createDealer(obj: UpdateDealerRequest) : Promise<AxiosResponse<Dealer>>  {
    return this.axios.post( this.baseUrl + "/v1/dealer", obj);
  }

  saveDealer(id:string, obj: UpdateDealerRequest) : Promise<AxiosResponse<Dealer>> {
    return this.axios.put( this.baseUrl + "/v1/dealer/" + id, obj);
  }

  deleteDealer(id:string) : Promise<AxiosResponse<void>> {
    return this.axios.delete( this.baseUrl + "/v1/dealer/"  + encodeURIComponent(id) );
  }

  async createShippingDestination(obj) {
    return this.axios.post(
      this.baseUrl + "/v1/shipping-destination",
      obj
    );
  }

  async epicorExportSalesOrder(request: EpicorExportSalesOrderRequest): Promise<EpicorExportSalesOrderResponse> {
    const resp = await this.axios.post(
      this.baseUrl + '/v1/quotes/epicor/export-sales-order', request
    );
    return plainToClassFromExist(new EpicorExportSalesOrderResponse(), resp.data);
  }

  async getEpicorSalesOrder(quoteId: number): Promise<EpicorSalesOrder> {
    const resp = await this.axios.get(
      this.baseUrl + '/v1/quotes/' + encodeURIComponent(quoteId) + '/epicor-sales-order'
    );
    return plainToClassFromExist(new EpicorSalesOrder(), resp.data);
  }

  async getParentQuotes(quoteId: number): Promise<AxiosResponse<ParentQuoteHistoryDto[]>> {
    return this.axios.get(
      this.baseUrl + '/v1/quotes/' + encodeURIComponent(quoteId) + '/parentQuoteHistory'
    );
  }

  async getEpicorCustomers(filter: string): Promise<EpicorCustomersResponse> {
    const resp = await this.axios.get(
      this.baseUrl + '/v1/epicor/customers?filter=' + encodeURIComponent(filter)
    );
    return plainToClassFromExist(new EpicorCustomersResponse(), resp.data);
  }

  fetchCustomer(id:number) : Promise<AxiosResponse<Customer>> {
    return this.axios.get(this.baseUrl + "/v1/customer/" + id );
  }

  getCustomers(cancelToken?:CancelToken) : Promise<AxiosResponse<Customer[]>> {
    return this.axios.get(this.baseUrl + "/v1/customer", {cancelToken});
  }

  createCustomer(customer:Customer) : Promise<AxiosResponse<Customer>> {
    return this.axios.post(this.baseUrl + "/v1/customer", customer);
  }

  updateCustomer(customer:Customer) : Promise<AxiosResponse<Customer>> {
    return this.axios.put( this.baseUrl + "/v1/customer/" + encodeURIComponent(customer.id), customer);
  }

  getAllOperations() : Promise<AxiosResponse<Operation[]>> {
    return this.axios.get(this.baseUrl + "/v1/operations");
  }
  
  remapOperations(request) : Promise<AxiosResponse<OperationsRemapResponse>> {
    return this.axios.post(this.baseUrl + "/v1/operations/remap", request);
  }

  fetchAssemblyOperations(cancelToken?: CancelToken) : Promise<AxiosResponse<Operation[]>> {
    return this.axios.get(this.baseUrl + "/v1/assembly/operations", {cancelToken, timeout: 10000});
  }

  getCategories(cancelToken?:CancelToken) : Promise<AxiosResponse<Category[]>> {
    return this.axios.get(this.baseUrl + "/v1/assembly/categories", {
      cancelToken
    });
  }

  getBasicCategories() : Promise<AxiosResponse<BaseCategory[]>> {
    return this.axios.get(this.baseUrl + "/v1/assembly/basicCategories");
  }

  listModels(request?: ListModelsRequest, cancelToken?: CancelToken): Promise<AxiosResponse<Page<BaseModel>>> {

    const sort = this.buildSortParamBody( request?.sort ).join(',');

    return this.axios.get( this.baseUrl + "/v1/models", {
      cancelToken,
      params: { 
        ...formatListModelsRequestFilter(request),
        sort,
      } ,
    });
  }

  getApprovalHistory(id: number): Promise<AxiosResponse<ApprovalHistory>> {
    return this.axios.get(
      this.baseUrl +
      "/v1/quotes/" +
      id + "/approvalHistory"
    );
  }

  async updateSystemConfig(property: string, request: UpdateSystemConfigRequest): Promise<SystemConfig> {
    const resp = await this.axios.post(
      this.baseUrl + '/v1/system-config/' + encodeURIComponent(property),
      request);

      return plainToClass(SystemConfig, resp.data)
  }

  getSystemConfig(): Promise<AxiosResponse<SystemConfig[]>> {
    return this.axios.get( this.baseUrl + '/v1/system-config');
  }

  createQuote(obj:SaveDto) : Promise<AxiosResponse<Quote>> {
    return this.axios.post(this.baseUrl + "/v1/quotes", obj);
  }

  copyQuoteRevision( revisionId:number, options?:{ name?:string, refresh?:boolean}) : Promise<AxiosResponse<Quote>> {
    return this.axios.post(`${this.baseUrl}/v1/quotes/revision/${revisionId}/copy`, options );
  }

  previewCopyQuote( revisionId:number) : Promise<AxiosResponse<ApprovalDiff>> {
    return this.axios.get(`${this.baseUrl}/v1/quotes/revision/${revisionId}/copy/preview` );
  }

  saveQuoteRevision(quoteRevisionId:number, obj:SaveDto): Promise<AxiosResponse<Quote>> {
    return this.axios.put( `${this.baseUrl}/v1/quotes/revisions/${quoteRevisionId}` , obj);
  }

  reviseQuote(quoteId:number): Promise<AxiosResponse<Quote>> {
    return this.axios.post( this.baseUrl + "/v1/workflow/" + quoteId + "/revise");
  }

  submitOrder(quoteId:number): Promise<AxiosResponse<Quote>> {
    return this.axios.post( this.baseUrl + "/v1/workflow/" + quoteId + "/submit");
  }

  submitQuote(quoteId:number, obj:any): Promise<AxiosResponse<Quote>> {
    return this.axios.post( this.baseUrl + "/v1/workflow/" + quoteId + "/submit", obj);
  }

  submitQuoteApproval(quoteId:number, options?:{ reservation?:boolean }) : Promise<AxiosResponse<Quote>> {
    return this.axios.post( this.baseUrl + "/v1/workflow/" + quoteId + "/submitQuoteApproval", {
      ...options,
      action: ApprovalAction.APPROVED,
    });
  }

  abandonQuoteApproval(quoteId:number) : Promise<AxiosResponse<Quote>> {
    return this.axios.post( this.baseUrl + "/v1/workflow/" + quoteId + "/submitQuoteApproval", {
      action: ApprovalAction.REJECTED
    });
  }

  createChangeOrder(quoteId:number, priceProtected?: boolean,  obj?:any) : Promise<AxiosResponse<Quote>> {
    return this.axios.post( this.baseUrl + "/v1/workflow/" + quoteId + "/changeOrder", obj, {
      params: {
        priceProtected
      }
    } );
  }

  updateChangeOrder(quoteId:number, obj:SalesChangeOrderSaveDto) : Promise<AxiosResponse<Quote>> {
    return this.axios.post( this.baseUrl + "/v1/workflow/" + quoteId + "/updateChangeOrder", obj);
  }

  abandonChangeOrder(quoteId:number) : Promise<AxiosResponse<Quote>>  {
    return this.axios.post( this.baseUrl + "/v1/workflow/submitChangeOrder", {
      quoteId,
      action: ApprovalAction.REJECTED
    });
  }

  submitChangeOrder(quoteId: number, dealerRequest: string): Promise<AxiosResponse<Quote>> {
    return this.axios.post(this.baseUrl + "/v1/workflow/submitChangeOrder", {
      quoteId,
      action: ApprovalAction.APPROVED,
      dealerRequest,
    });
  }

  verifyRevisionChange(obj: ChangeOrderVerificationRequest): Promise<AxiosResponse<RevisionChangeDto>> {
    return this.axios.post( this.baseUrl + "/v1/workflow/verifyRevisionChange", obj);
  }

  diffRevisions(quoteId:string, revision?:number, previous?: number): Promise<AxiosResponse<ApprovalDiff>> {
    return this.axios.get( this.baseUrl + "/v1/quotes/" + encodeURI(quoteId) + "/diff", {
      params: {
        revision,
        previous
      }
    });
  }

  diffArbitraryRevisions(quoteIdA:string, revisionA?:number | undefined, quoteIdB?: string | undefined, revisionB?:number | undefined): Promise<AxiosResponse<ApprovalDiff>> {
    return this.axios.get( this.baseUrl + "/v1/quotes/diff", {
      params: {
        quoteIdA,
        quoteIdB,
        revisionA,
        revisionB
      }
    });
  }



  getAllRevisionsChange(id: number) {
    return this.axios.get(
      this.baseUrl + "/v1/workflow/"  + encodeURIComponent(id) + "/getAllRevisionsChange"
    );
  }

  verifyChangeOrderRequest(revId: number, obj: ChangeOrderRequestDto) : Promise<AxiosResponse<any>> {
    return this.axios.post( this.baseUrl + "/v1/workflow/" + revId + "/verifyChangeOrderRequest", obj );
  }


  listQuoteApprovals(request: ListApprovalsRequest, cancelToken?:CancelToken): Promise<AxiosResponse<Page<Approval>>> {

    const sort = this.buildSortParamBody( request.sort ).join(',');

    return this.axios.get( this.baseUrl + "/v1/approvals", {
      cancelToken,
      params: { 
        ...formatListApprovalRequestFilter(request),
        sort,
      } ,
    });

  }

  async saveWorkingNote(id, obj) {
    return this.axios.post(
      this.baseUrl + "/v1/approvals/" + encodeURI(id) + "/workingNote",
      obj
    )
  }

  getQuoteApproval(id:number | string) : Promise<AxiosResponse<Approval>> {
    return this.axios.get(
      this.baseUrl + "/v1/approvals/" + id
    );
  }

  getApprovalWorkflow(quoteId: number) : Promise<AxiosResponse<WorkflowStep[]>> {
    return this.axios.get(
      this.baseUrl + "/v1/quotes/" + quoteId + "/workflowStep"
    );
  }

  approvalAction(id:number, obj:Record<string, any>) : Promise<AxiosResponse<Approval>>{
    return this.axios.post( this.baseUrl + "/v1/approvals/" + id,
      obj
    );
  }

  getQuote(quoteId:string, revisionId?:number) : Promise<AxiosResponse<Quote>> {
    return this.axios.get(this.baseUrl + "/v1/quotes/" + encodeURI(quoteId) , {
      params: {
        revisionId,
      }});
  }

  getQuoteByRevision(quoteId:string, rev:number | undefined, cancelToken?:CancelToken) : Promise<AxiosResponse<Quote>> {
    return this.axios.get(this.baseUrl + "/v1/quotes/" + encodeURI(quoteId), {
      cancelToken,
      params: { 
        rev,
      } ,
    });
  }

  listCustomWork(request: ListQuotesRequest, cancelToken?:CancelToken): Promise<AxiosResponse<Page<CustomWorkReview>>> {

    const sort = this.buildSortParamBody( request.sort ).join(',');

    return this.axios.get( this.baseUrl + "/v1/quotes/customWorkReview", {
      cancelToken,
      params: { 
        ...formatListQuoteRequestFilter(request),
        sort,
      }
    });

  }

  fetchCustomWorkReview(quoteRevisionId: number, options?:{ dealerView?:boolean, categoryId?:number}): Promise<AxiosResponse<QuoteReview>> {
    return this.axios.get( `${this.baseUrl}/v1/quotes/${quoteRevisionId}/customWorkReview`, { params: options });
  }

  listQuotes(request: ListQuotesRequest, cancelToken?:CancelToken): Promise<AxiosResponse<Page<ListQuotesResponse>>> {

    const sort = this.buildSortParamBody( request.sort ).join(',');

    return this.axios.get( this.baseUrl + "/v1/quotes", {
      cancelToken,
      params: { 
        ...formatListQuoteRequestFilter(request),
        sort,
      },
    });

  }

  listQuoteInfo(request: ListQuotesRequest): Promise<AxiosResponse<Page<QuoteInfo>>> {

    const sort = this.buildSortParamBody( request.sort ).join(',');

    return this.axios.get( this.baseUrl + "/v1/quotes/info", {
      params: { 
        ...formatListQuoteRequestFilter(request),
        sort,
      } ,
    });

  }

  async createModel(obj) {
    return this.axios.post(this.baseUrl + "/v1/models/", obj);
  }

  createCategory(obj) : Promise<AxiosResponse<void>> {
    return this.axios.post(this.baseUrl + "/v1/assembly/categories", obj);
  }

  getCategory(id:number) : Promise<AxiosResponse<Category>> {
    return this.axios.get( this.baseUrl + "/v1/assembly/categories/" + id );
  }

  updateCategory(id:number, obj:UpdateCategoryRequest) : Promise<AxiosResponse<Category>> {
    return this.axios.post( this.baseUrl + "/v1/assembly/categories/" + id, obj );
  }

  updateCategoryEngineeringTeam(id:number, engineeringTeamId:number | undefined) : Promise<AxiosResponse<Category>> {
    return this.axios.put( `${this.baseUrl}/v1/assembly/categories/${id}/engineeringTeam`, {
      engineeringTeamId
    });
  }


  computePricing(modelId: number, request: ComputePricingRequest, cancelToken?: CancelToken): Promise<AxiosResponse<PricingBreakdown>> {
    return this.axios.post( this.baseUrl + "/v1/configurator/models/" + modelId + '/compute-pricing', { 
      ...request, 
    }, { cancelToken });
  }

  computeValid(modelId: number, options?: { selections?: number[], customOptions?:number[],  rulesV2?: boolean, quoteRevisionId?: number }, cancelToken?: CancelToken): Promise<AxiosResponse<ModelComputeOptionsResponse>> {
    return this.axios.post( this.baseUrl + "/v1/configurator/models/" + modelId + '/compute-valid', { 
      rulesV2: options?.rulesV2 || true,
      quoteRevisionId: options?.quoteRevisionId,
      currentSelections: options?.selections,
      customOptions: options?.customOptions,
    }, { cancelToken });
  }

  computeAutoSelections(modelId: number, options?: { selections?: number[], customOptions?:number[],  rulesV2?: boolean, quoteRevisionId?: number, latestAssembly?:number,  }, cancelToken?: CancelToken): Promise<AxiosResponse<ModelComputeOptionsResponse>> {
    return this.axios.post( this.baseUrl + "/v1/configurator/models/" + modelId + '/compute-auto-selections', { 
      latestAssembly: options?.latestAssembly,
      rulesV2: options?.rulesV2 || true,
      quoteRevisionId: options?.quoteRevisionId,
      currentSelections: options?.selections,
      customOptions: options?.customOptions,
    }, { cancelToken });
  }


  fetchCategoryOptions(modelId: number, categoryId: number, options:{ selections?: number[], customOptions?:number[], rulesV2?: boolean, quoteRevisionId?: number, percentDiscount?:number, debugRules?:boolean, }, cancelToken?: CancelToken): Promise<AxiosResponse<ModelComputeOptionsResponse>> {
    return this.axios.post( this.baseUrl + "/v1/configurator/models/" + modelId + '/compute-options', { 
      categoryId,
      currentSelections: options.selections,
      debugRules: options.debugRules,
      quoteRevisionId: options.quoteRevisionId,
      rulesV2: options.rulesV2 || true,
      percentDiscount: options.percentDiscount,
      customOptions: options?.customOptions,
    }, { cancelToken });
  }

  getConfiguratorCategories(id:number, options?:{ quoteRevisionId?:number, assemblyFilter?: string, dealerView?: boolean }  ): Promise<AxiosResponse<BaseCategory[]>> {
    return this.axios.get( `${this.baseUrl}/v1/configurator/models/${id}/categories`, {
      params: options
    });
  }

  getInvalidCategoriesInQuoteRevision(revId: number): Promise<AxiosResponse<Category[]>> {
    return this.axios.get( this.baseUrl + "/v1/quotes/" + revId + '/getInvalidCategories', { params: {
      includeAllCustomOptions: false
    }});
  }

  getModel(id:number): Promise<AxiosResponse<BaseModel>> {
    return this.axios.get( `${this.baseUrl}/v1/models/${id}`);
  }

  getModelDetail(id:number): Promise<AxiosResponse<Model>> {
    return this.axios.get( `${this.baseUrl}/v1/models/${id}/detail`);
  }

  getModelWithBasicAssembly(id:number, cached?:boolean, noAssemblies?: boolean): Promise<AxiosResponse<ModelWithBasicAssemblyInfo>> {
    return this.axios.get( this.baseUrl + "/v1/models/" + encodeURIComponent(id) + "/basic", {
      params: {
        cached: (cached === undefined ) ? true : cached,
        noAssemblies: ( noAssemblies === undefined ) ? true : noAssemblies
      }
    });
  }

  async deleteModel(id) {
    return this.axios.delete(
      this.baseUrl + "/v1/models/" + encodeURIComponent(id)
    );
  }

  async updateModel(id, body) {
    return this.axios.post(
      this.baseUrl + "/v1/models/" + encodeURIComponent(id),
      body
    );
  }

  getAssembly(id:number) : Promise<AxiosResponse<Assembly>> {
    return this.axios.get( this.baseUrl + "/v1/assembly/" + id );
  }

  checkBomAvailablility(bom: string) : Promise<AxiosResponse<Boolean>> {
    return this.axios.get( this.baseUrl + "/v1/assembly/checkAvailability/" + bom );
  }

  createAssembly(request: AssemblyRequest) {
    return this.axios.post(this.baseUrl + "/v1/assembly", {...request} );
  }

  createEngineeringChange(id, obj?): Promise<AxiosResponse<Quote>> {
    return this.axios.post(
      this.baseUrl + "/v1/workflow/" + encodeURIComponent(id) + "/createEngineeringChange",
      obj
    );
  }

  updateEngineeringChange(quoteId:number, obj:EngineeringChangeSaveDto) : Promise<AxiosResponse<Quote>> {
    return this.axios.post(
      this.baseUrl + "/v1/workflow/" + quoteId + "/updateEngineeringChange",
      obj
    );
  }

  abandonEngineeringChange(quoteId:number, changeSummary:string) : Promise<AxiosResponse<Quote>> {
    return this.axios.post(
      this.baseUrl + "/v1/workflow/submitEngineeringChange", {
        quoteId,
        action: ApprovalAction.REJECTED,
        changeSummary
      });
  }

  submitEngineeringChange(quoteId:number, changeSummary:string, forceErpRevision:boolean) : Promise<AxiosResponse<Quote>> {
    return this.axios.post(
      this.baseUrl + "/v1/workflow/submitEngineeringChange", {
        quoteId,
        action: ApprovalAction.APPROVED,
        changeSummary,
        forceErpRevision,
      });
  }

  updateQuoteArchived(id: number, archived:boolean): Promise<AxiosResponse<boolean>> {
    return this.axios.post( this.baseUrl + "/v1/quotes/" + id + "/updateArchived",
      { archived }
    );
  }

  updateSnapshotCosts(assemblyId: number, request: UpdateAssemblySnapshotCostsRequest) {
    return this.axios.post(this.baseUrl + '/v1/assembly/' + encodeURIComponent(assemblyId) + '/updateSnapshotCosts', request);
  }

  updatePricing(newPricing:PricingConfig):Promise<AxiosResponse<PricingConfig>> {
    return this.axios.post(this.baseUrl + "/v1/pricing", newPricing);
  }

  getActivePricingConfig():Promise<AxiosResponse<PricingConfig>> {
    return this.axios.get(this.baseUrl + "/v1/pricing/active");
  }


  getLatestPricing():Promise<AxiosResponse<PricingConfig>> {
    return this.axios.get(this.baseUrl + "/v1/quotes/latestPricing");
  }

  async setQuoteVIN(quoteId, vin) {
    return await this.axios.post(
      this.baseUrl + "/v1/quotes/" + encodeURIComponent(quoteId) + "/vin",
      {
        vin: vin,
      }
    );
  }

  updateAssembly(id: number, asm:AssemblyRequest ):Promise<AxiosResponse<Assembly>> {
    return this.axios.post( this.baseUrl + "/v1/assembly/" + encodeURIComponent(id), asm );
  }

  resetAssemblyPending(id) :Promise<AxiosResponse<Assembly>> {
    return this.axios.post( this.baseUrl + "/v1/assembly/" + encodeURIComponent(id) + "/reset-pending" );
  }

  importAssembly(asm:AssemblyRequest, id?: number ):Promise<AxiosResponse<Assembly>> {
    return this.axios.post( this.baseUrl + "/v1/assembly/import/v2", {...asm, id} );
  }

  fetchUserList(): Promise<AxiosResponse<User[]>> {
    return this.axios.get( this.baseUrl + "/v1/user/names" );
  }

  async getBomImportStatus() {
    return this.axios.get( this.baseUrl + "/v1/assembly/bomImportStatus");
  }

  getImportAllianceBomUrl() {
    return this.baseUrl + "/v1/assembly/importAllianceBom";
  }

  splitQuote(obj: SplitQuoteDto): Promise<AxiosResponse<Quote>> {
    return this.axios.post(this.baseUrl + "/v1/workflow/split", obj);
  }

  getUploadModelImageUrl(modelId:string) {
    return `${this.baseUrl}/v1/models/${modelId}/image`;
  }

  fetchIsLocked(quoteId:number) {
    return this.axios.post(`${this.baseUrl}/v1/quotes/${quoteId}/isLocked`);
  }

  createCustomOption(obj: CustomOptionRequest, approvalId?:number)  : Promise<AxiosResponse<CustomOptionType | undefined>> {
    if ( approvalId ) {
      return this.axios.post(`${this.baseUrl}/v1/approvals/${approvalId}/customOption`, obj);
    }
    else {
      return this.axios.post(this.baseUrl + "/v1/workflow/createCustomOption", obj);
    }
  }

  updateCustomOption(obj: CustomOptionRequest) : Promise<AxiosResponse<CustomOptionType | undefined>> {
    return this.axios.post(this.baseUrl + "/v1/workflow/updateCustomOption", obj);
  }

  deleteCustomOption(customOptionid: number): Promise<AxiosResponse<void>> {
    return this.axios.delete(
      this.baseUrl + "/v1/workflow/deleteCustomOption/"  + encodeURIComponent(customOptionid),
    );
  }

  //selections are necessary for upgrade pricing on custom option
  getCustomOptions(revId: number, selections?:number[]) : Promise<AxiosResponse<CustomOptionType[]>>  {

    return this.axios.get( this.baseUrl + "/v1/workflow/getCustomOption/" + revId, {
      params: { selections: selections?.join(",") } ,
    });
  }

  getProcessingCustomOptions(id: number, statusList: CustomOptionProcessingStatus[]) : Promise<AxiosResponse<CustomOptionType[]>>  {
    return this.axios.get(
      this.baseUrl + "/v1/workflow/getProcessingCustomOption/" + id,
      {
        params: {
          statusList: statusList.join(",")
        }
      }
    );
  }

  retrieveProcessingCustomOptions(quoteId: number, customOptionIds: number[]) : Promise<AxiosResponse<CustomOptionType[]>>  {
    return this.axios.post(
      this.baseUrl + "/v1/workflow/retrieveProcessingCustomOptions",
      {
        quoteId,
        customOptionIds,
      }
    );
  }

  completeStuckCustomOptions(quoteId: number, customOptionIds: number[]) : Promise<AxiosResponse<CustomOptionType[]>>  {
    return this.axios.post(
      this.baseUrl + "/v1/workflow/completeStuckCustomOptions",
      {
        quoteId,
        customOptionIds,
      }
    );
  }

  resetAllCustomOptions(id: number) {
    return this.axios.post(
      this.baseUrl + "/v1/workflow/resetAllCustomOptions/"  + encodeURIComponent(id),
    );
  }

  async getCustomOptionForApproval(id: number) {
    return this.axios.get(
      this.baseUrl + "/v1/workflow/getCustomOptionForApproval/"  + encodeURIComponent(id),
    );
  }

  async fetchTruckGvwr(selections:number[]): Promise<any> {

    //send selections as string of comma separated numbers
    return this.axios.get( this.baseUrl + "/v1/quotes/truckGvwr", {
      params: { selections: selections.join(",") } ,
    });
  }

  reassignQuoteOwner(quoteId:number, userId: string, salesTeam:SalesTeamRequest | undefined) : Promise<AxiosResponse<Quote>> {
    return this.axios.post(`${this.baseUrl}/v1/quotes/${quoteId}/owner`, {
      userId,
      salesTeam,
    });
  }

  assignQuoteEngineer(quoteId:number, userId: string ) : Promise<AxiosResponse<Quote>> {
    return this.axios.put(`${this.baseUrl}/v1/quotes/${quoteId}/engineer`, {
      userId
    });
  }

  assignDealerEngineer(dealerId:string, userId: string ) : Promise<AxiosResponse<Dealer>> {
    return this.axios.put(`${this.baseUrl}/v1/dealer/${dealerId}/engineer`, {
      userId
    });
  }

  fetchSelectionInfo(options?:{selections?:number[], pricingSnapshotId?: number, quoteRevisionId?:number}): Promise<AxiosResponse<AssemblyInfo[]>> {
    const selections = options?.selections?.join(",");

    //send selections as string of comma separated numbers
    return this.axios.get( this.baseUrl + "/v1/quotes/selectionInfo", {
      params: {
        ...options,
        selections
    }});
  }

  fetchDealerSales(dealerId?: string, source?:CancelTokenSource) :Promise<AxiosResponse<User[]>> {

    if ( dealerId ) {
      return this.axios.get(`${this.baseUrl}/v1/dealer/${encodeURIComponent(dealerId)}/sales`, {
        cancelToken: source?.token
      });
    }
    else {
      return this.axios.get(`${this.baseUrl}/v1/dealer/sales`, {
        cancelToken: source?.token
      });
    }
  }

  getShippingDestinationsExportUrl() {
    return this.baseUrl + "/v1/shipping-destination/export";
  }
  getShippingDestinationsImportUrl() {
    return this.baseUrl + "/v1/shipping-destination/import";
  }

  fetchPricingSnapshots(source?:CancelTokenSource) :Promise<AxiosResponse<PricingSnapshot[]>> {
    return this.axios.get(`${this.baseUrl}/v1/pricing/snapshots`, {
      cancelToken: source?.token
    });
  }

  fetchPricingConfigBySnapshotId(id:number, source?:CancelTokenSource):Promise<AxiosResponse<PricingConfig>> {
    return this.axios.get(`${this.baseUrl}/v1/pricing/snapshot/${id}`,{
      cancelToken: source?.token
    });
  }

  createSnapshot(name:string):Promise<AxiosResponse<PricingConfig>> {
    return this.axios.post(`${this.baseUrl}/v1/pricing/snapshot`,{ 
      snapshotName: name
    });
  }

  getEpicorExportUrl(revId: number) : string {
    return this.baseUrl + "/v1/quotes/" + revId + "/epicorExport";
  }

  getAssemblyWeightExportUrl() : string {
    return this.baseUrl + "/v1/assembly/weightAndCg";
  }

  getLinesetTicketPdfUrl(quoteId: string, revisionId?: number) : string {
    return this.baseUrl + '/v1/quotes/' + encodeURIComponent(quoteId) + '/lineset_ticket.pdf'
    + ( revisionId ? '?revision=' + encodeURIComponent(revisionId) : '');
  }

  getExportQuotePdfUrl(revisionId: number, options?: { inventory:boolean }) : string {
    const url = `${this.baseUrl}/v1/quotes/${revisionId}/pdfExport`;

    if (options) {
      const params = new URLSearchParams(
        Object.entries(options).reduce((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {} as Record<string, string>)
      ).toString();
  
      return `${url}?${params}`;
    }
    return url;
  }

  generateConfirmationLetterUrl(quoteId: string, customerId: number) : string {
    return this.baseUrl + '/v1/quotes/' + encodeURIComponent(quoteId) + '/orderConfirmationLetter/' + customerId;
  }

  getDealerAddressOption(id: number): Promise<AxiosResponse<AddressDto[]>> {
    return this.axios.get(this.baseUrl + '/v1/customer/getDealerAddressOption/' + id);
  }

  setDefaultAddress(dealerId: string, address: DefaultAddress[]): Promise<AxiosResponse<any>> {
    return this.axios.post(`${this.baseUrl}/v1/customer/` + encodeURIComponent(dealerId) + "/setDefaultAddress", {
      updateAddressRequest: address,
    });
  }

  getApprovalCount() {
    return this.axios.get( this.baseUrl + "/v1/approvals/count");
  }

  downloadCsv(url:string, filename?:string, onProgressPercent?:(p:number)=>void) : Promise<boolean> {
    const mimeType = 'text/csv';
    return this.downloadFile(url, {
      mimeType, filename, onProgressPercent 
    });
  }
  downloadPdf(url:string, filename?:string, onProgressPercent?:(p:number)=>void) : Promise<boolean> {
    const mimeType = 'application/pdf';
    return this.downloadFile(url, {
      mimeType, filename, onProgressPercent 
    });
  }
  downloadZip(url:string, filename?:string, onProgressPercent?:(p:number)=>void) : Promise<boolean> {
    const mimeType = 'application/zip';
    return this.downloadFile(url, {
      mimeType, filename, onProgressPercent 
    });
  }
  downloadPdfOpts(url:string, opts:Record<string,any>) : Promise<boolean> {
    const mimeType = 'application/pdf';
    return this.downloadFile(url, {
      ...opts,
      mimeType, 
    });
  }

  downloadFile(url:string, opts?: {
    mimeType?:string, 
    filename?:string, 
    onProgressPercent?:(p:number)=>void,
  }) : Promise<boolean> {

    const onDownloadProgress = opts?.onProgressPercent && ((progressEvent:any) => {
        const total = progressEvent.total;
        const current = progressEvent.loaded;

        let percentCompleted = Math.floor(current / total * 100)
        opts.onProgressPercent?.( percentCompleted );
      });

      //delete axios parameters
      const params = {...opts, mimeType: undefined, filename:undefined, onProgressPercent:undefined};
      const rslt = new Promise<boolean>( (resolve, reject ) => {
        this.axios.get(url, {
          onDownloadProgress,
          responseType: 'blob', 
          params:params
        })
        .then( (resp:AxiosResponse<Blob>) => {

          var data = new Blob([resp.data], {type: opts?.mimeType});
          var csvURL = window.URL.createObjectURL(data);
          const link = document.createElement('a');
          link.href = csvURL;

          const suggestedFilename = resp.headers[ "x-suggested-filename" ];
          if ( opts?.filename ) {
            link.download = opts?.filename;
          }
          else if ( suggestedFilename ) {
            link.download = suggestedFilename;
          }
          link.click();

          resolve(true);
        },
        (e:any) => reject( e ) );

      });


      return rslt;
  }

  fetchFullPricingBreakdownByQuote(quoteId:string, rev?:number ): Promise<AxiosResponse<PricingBreakdown>> {
    return this.axios.get( `${this.baseUrl}/v1/quotes/${encodeURIComponent(quoteId)}/fullPricing`,
    {
      params: { 
        rev
      } 
    });
  }

  fetchSelectionsByQuoteId(quoteId:string, params?:{ hideNoOption: boolean }): Promise<AxiosResponse<AssemblyInfo[]>> {
    const url = `${this.baseUrl}/v1/quotes/${encodeURIComponent(quoteId)}/selections`;
    return this.axios.get( url, { params });
  }

  fetchModelByQuoteId(quoteId:string): Promise<AxiosResponse<BaseModel>> {
    const url = `${this.baseUrl}/v1/quotes/${encodeURIComponent(quoteId)}/model`;
    return this.axios.get( url );
  }

  getAssemblyCategoryCSVImportUrl( categoryId: number ) : string {
    return this.baseUrl + "/v1/assembly/categories/" + categoryId + "/assemblyCSVImport";
  }

  getAssemblyCategoryCSVExportUrl( categoryId: number ) : string {
    return this.baseUrl + "/v1/assembly/categories/" + categoryId + "/assemblyCSVExport";
  }

  getImportAssemblyCategoryCSVUrl() : string {
    return this.baseUrl + "/v1/assembly/categories/import/csv";
  }

  fetchQuotePerformance(id: string, rev:number | undefined): Promise<AxiosResponse<Performance>> {
    return this.axios.get( this.baseUrl + "/v1/quotes/" + id + "/performance", 
    {
      params: { 
        rev,
      } ,
    });
  }

  saveTruckSerials( quoteId:number, revisionId:number, snLst:number[], exportToEpicor:boolean ): Promise<AxiosResponse<Quote>> {
    return this.axios.post(`${this.baseUrl}/v1/quotes/${quoteId}/truckSerialNumbers`, {
      revisionId,
      serialNumbers: snLst,
      exportToEpicor
    });
  }
  getDashComponents(type: string | undefined): Promise<AxiosResponse<DashComponent[]>> {
    return this.axios.get(
      this.baseUrl + "/v1/dash-component",
      {
        params: {
          type: type || 'ALL',
        }
      }
    );
  }

  getAssemblyWithDashComponent(id: number): Promise<AxiosResponse<Assembly>> {
    return this.axios.get(
      this.baseUrl + "/v1/dash-component/" + encodeURIComponent(id) + "/assembly",
    );
  }

  getSingleDashComponent(id: number): Promise<AxiosResponse<DashComponent>>  {
    return this.axios.get(
      this.baseUrl + "/v1/dash-component/" + encodeURIComponent(id),
    );
  }

  createDashComponent(obj: DashComponent): Promise<AxiosResponse<DashComponent>> {
    return this.axios.post(
      this.baseUrl + "/v1/dash-component",
      obj,
    );
  }

  updateSingleDashComponent(obj: DashComponent): Promise<AxiosResponse<DashComponent>>  {
    return this.axios.post(
      this.baseUrl + "/v1/dash-component/update",
      obj
    );
  }

  saveAssemblyWithDashComponent(assemblyId: number, components: DashComponent[]) {
    return this.axios.post(
      this.baseUrl + "/v1/dash-component/assignment",
      {
        assemblyId: assemblyId,
        dashComponents: components,
      }, 
    );
  }

  getDashTemplates(descFilter: string | undefined, dashInstrFilterList: number[] | undefined): Promise<AxiosResponse<DashTemplate[]>> {

    const dashInstrFilter = dashInstrFilterList?.join(',');
    return this.axios.get(
      this.baseUrl + "/v1/dash-component/template",
      {
        params: {
          descFilter, dashInstrFilter,
        }
      }
    );
  }

  getDashTemplateExportUrl() : string {
    return this.baseUrl + "/v1/dash-component/allDashTemplates";
  }

  getSingleDashTemplate(id: number): Promise<AxiosResponse<DashTemplate>> {
    return this.axios.get(
      this.baseUrl + "/v1/dash-component/template/" + encodeURIComponent(id),
    );
  }

  getDashTemplateRelatedQuotes(): Promise<AxiosResponse<TemplateRelatedQuote[]>> {
    return this.axios.get(
      this.baseUrl + "/v1/dash-component/getQuoteQuantityOnTemplate",
    );
  }

  getQuoteWithMissingDashComponent(templateId: string): Promise<AxiosResponse<QuoteWithMissingDashComponent[]>> {
    return this.axios.get(
      this.baseUrl + "/v1/dash-component/getQuoteWithMissingDashComponent/" + Number(templateId),
    );
  }

  createDashTemplate(obj: DashTemplate): Promise<AxiosResponse<DashTemplate>> {
    return this.axios.post(
      this.baseUrl + "/v1/dash-component/template",
      obj,
    );
  }

  updateSingleDashTemplate(obj: DashTemplate): Promise<AxiosResponse<DashTemplate>>  {
    return this.axios.post(
      this.baseUrl + "/v1/dash-component/template/update",
      obj
    );
  }

  getComponentTypes(): Promise<AxiosResponse<ComponentType[]>> {
    return this.axios.get(this.baseUrl + '/v1/dash-component/componentType');
  }

  getDashDrawingAssemblies(): Promise<AxiosResponse<AssemblyInfo[]>> {
    return this.axios.get(this.baseUrl + '/v1/assembly/dashTemplateCategories');
  }

  getDashDrawing(id: number, revisionId: number, regenerate: boolean): Promise<AxiosResponse<DashDrawingUrl>>  {
    return this.axios.post(
      this.baseUrl + "/v1/dash-component/dashDrawing",
      {
        id,
        revisionId,
        regenerate,
      }
    );
  }

  verifyDashStyle(revId: number): Promise<AxiosResponse<NeedVerifyDash>> {
    return this.axios.get(this.baseUrl + `/v1/dash-component/${revId}/dashVerification`);
  }

  fetchSalesOrdersReport(request: ListQuotesRequest): Promise<AxiosResponse<Page<SalesOrderQuote>>> {

    const sortParam = this.buildSortParam( request.sort );

    return this.axios.get( this.baseUrl + "/v1/reporting/salesReport" + (sortParam.length ? "?" + sortParam : ""), {
      params: { 
        ...formatListQuoteRequestFilter(request),
      } ,
    });

  }

  updatePricingConfig( quoteId:number, pricingSnapshotId: number | undefined, updateExpiredQuote: boolean | undefined ): Promise<AxiosResponse<Quote>> {
    return this.axios.post(`${this.baseUrl}/v1/quotes/${quoteId}/updatePricingConfig`, {
      pricingSnapshotId,
      updateExpiredQuote,
    });
  }

  moveTrucks( truckSnLst:number[], dstRevisionId:number ) : Promise<AxiosResponse<Quote>>{
    return this.axios.post(`${this.baseUrl}/v1/quotes/moveTrucks`, {
      truckSnLst, dstRevisionId
    });
  }

  fetchSourcewellLetterDefaults( quoteId:string ) : Promise<AxiosResponse<SourcewellLetterParameters>>{
    return this.axios.get( `${this.baseUrl}/v1/quotes/${encodeURIComponent(quoteId)}/SourcewellLetterDefaults`);
  }

  async getActiveRuleSet(cancelToken?: CancelToken): Promise<RuleSet> {
    const response = await this.axios.get(`${this.baseUrl}/v1/rules/rule-sets/active`);
    return plainToClassFromExist(new RuleSet(), response.data);
  }

  async getRuleSet(ruleSetId: number, cancelToken?: CancelToken): Promise<RuleSet> {
    const response = await this.axios.get(`${this.baseUrl}/v1/rules/rule-sets/${encodeURIComponent(ruleSetId)}`);
    return plainToClassFromExist(new RuleSet(), response.data);
  }

  async activateRuleSet(ruleSetId: number): Promise<void> {
    await this.axios.post(`${this.baseUrl}/v1/rules/rule-sets/${encodeURIComponent(ruleSetId)}/activate`);
  }

  async updateGlobalExpression(ruleSetId: number, request: UpdateGlobalExpressionRequest): Promise<void> {
    const response = await this.axios.post(`${this.baseUrl}/v1/rules/rule-sets/${encodeURIComponent(ruleSetId)}/update-global-expression`, request);
  }

  async createGlobalExpression(ruleSetId: number, request: CreateGlobalExpressionRequest): Promise<void> {
    const response = await this.axios.post(`${this.baseUrl}/v1/rules/rule-sets/${encodeURIComponent(ruleSetId)}/create-global-expression`, request);
  }

  async updateRule(ruleSetId: number, request: UpdateRuleRequest): Promise<void> {
    const response = await this.axios.post(`${this.baseUrl}/v1/rules/rule-sets/${encodeURIComponent(ruleSetId)}/update-rule`, request);
  }

  async deleteRule(ruleSetId: number, request: DeleteRuleRequest): Promise<void> {
    const response = await this.axios.post(`${this.baseUrl}/v1/rules/rule-sets/${encodeURIComponent(ruleSetId)}/delete-rule`, request);
  }

  async createRule(ruleSetId: number, request: CreateRuleRequest): Promise<void> {
    const response = await this.axios.post(`${this.baseUrl}/v1/rules/rule-sets/${encodeURIComponent(ruleSetId)}/create-rule`, request);
  }

  async createRuleSet(request: CreateRuleSetRequest): Promise<void> {
    await this.axios.post(`${this.baseUrl}/v1/rules/rule-sets`, request);
  }

  async listRuleSets(page: number, size: number, cancelToken?: CancelToken): Promise<Page<RuleSet>> {
    const response = await this.axios.get(`${this.baseUrl}/v1/rules/rule-sets`, {
      cancelToken,
      params: {
        page, size,
      }
    });
    return plainToClassFromExist(new Page<RuleSet>(RuleSet), response.data);
  }

  generateSerialNumbers( quoteId:string ) : Promise<AxiosResponse<Quote>>{
    return this.axios.put( `${this.baseUrl}/v1/quotes/${encodeURIComponent(quoteId)}/generateSerialNumbers` );
  }

  fetchIncentivePrograms(modelId?:number) : Promise<AxiosResponse<IncentiveProgram[]>> {
    return this.axios.get( `${this.baseUrl}/v1/assembly/incentivePrograms`, {
      params: {
        modelId
      }
    });
  }


  fetchApprovalsReport(request: ListQuotesRequest, cancelToken?:CancelToken): Promise<AxiosResponse<Page<ApprovalsReportData>>> {

    const sortParam = this.buildSortParam( request.sort );

    return this.axios.get( this.baseUrl + "/v1/reporting/approvalsReport" + (sortParam.length ? "?" + sortParam : ""), {
      params: { 
        ...formatListQuoteRequestFilter(request),
      }, 
      cancelToken 
    });

  }

  getUploadPoDocumentUrl(quoteId:string) : string {
    return `${this.baseUrl}/v1/quotes/${encodeURIComponent(quoteId)}/poDocument`;
  }

  savePoNumber(revisionId:number, poNumber:PoNumber) : Promise<AxiosResponse<Quote>> {
    return this.axios.post( `${this.baseUrl}/v1/quotes/poNumber`, {
      revisionId,
      poNumber
    });
  }

  deletePoNumber(poNumberId:number) : Promise<AxiosResponse<Quote>> {
    return this.axios.delete( `${this.baseUrl}/v1/quotes/poNumber/${poNumberId}`);
  }

  fetchQuotePoDocumentUrls(poNumberId:number) : Promise<AxiosResponse<Record<string,string>>> {
    return this.axios.get( `${this.baseUrl}/v1/quotes/poNumber/${poNumberId}/poDocument/url`);
  }

  isObsolete(selections:number[]) : Promise<AxiosResponse<number[]>> {
    return this.axios.get( `${this.baseUrl}/v1/assembly/isObsolete`, {
      params: { 
        selections: selections.join(",")
      },
    });
  }

  getTruckDescription(revId: number, selections: number[]): Promise<any> {
    return this.axios.get( this.baseUrl + `/v1/quotes/${revId}/getTruckDescription`, {
      params: { selections: selections.join(",") } ,
    });
  }

  cancelOrder(id: number, request: {cancelledSerials?: number[], cancelledMessage}): Promise<AxiosResponse<Quote>> {
    return this.axios.post(`${this.baseUrl}/v1/workflow/cancelOrder`, {id, ...request});
  }

  verifyConcessionReview(quoteId: number): Promise<AxiosResponse<true>> {
    return this.axios.get(`${this.baseUrl}/v1/workflow/concessionReview/${quoteId}`);
  }

  resetCancelledOrder(quoteId: number): Promise<AxiosResponse<Quote>> {
    return this.axios.post(`${this.baseUrl}/v1/workflow/${quoteId}/resetCancelledOrder`);
  }


  varifyRevisionStatus(quoteId: number,  quoteStatusDto: QuoteStatusDto): Promise<AxiosResponse<QuoteStatusVerification>> {
    return this.axios.get(`${this.baseUrl}/v1/workflow/status/${quoteId}`,       
    {params:
      {
        ...quoteStatusDto,
      },
    });
  }

  fetchQuoteAssemblyExceptions(quoteId:string, categoryId?:number | undefined): Promise<AxiosResponse<QuoteAssemblyException[]>> {
    return this.axios.get(`${this.baseUrl}/v1/quotes/${quoteId}/assemblyExceptions`, { params: { categoryId }});
  }

  saveQuoteAssemblyException(quoteId:string, exception:QuoteAssemblyException): Promise<AxiosResponse<QuoteAssemblyException>> {
    return this.axios.post(`${this.baseUrl}/v1/quotes/${quoteId}/assemblyException`, exception);
  }

  deleteQuoteAssemblyException(quoteId:string, id:number): Promise<AxiosResponse<void>> {
    return this.axios.delete(`${this.baseUrl}/v1/quotes/${quoteId}/assemblyException/${id}`);
  }

  convertQuoteToStock(quoteId:number, enabled:boolean): Promise<AxiosResponse<Quote>> {
    return this.axios.put(`${this.baseUrl}/v1/quotes/${quoteId}/stock`, { enabled });
  }

  priceMatchRevision( revisionId:number, target:number ) : Promise<AxiosResponse<void>>{
    return this.axios.put( `${this.baseUrl}/v1/quotes/${revisionId}/priceMatch`, {
      target 
    });
  }

  getStageAssemblyReplacementCSVUrl() : string {
    return this.baseUrl + "/v1/assembly/replace/csv"
  }

  replaceAssembly(id:number, req:AssemblyReplacementRequest ) : Promise<AxiosResponse<Quote>> {
    return this.axios.post( `${this.baseUrl}/v1/quotes/${id}/replaceAssembly`, req );
  }


  fetchPricingReport(request: ListQuotesRequest): Promise<AxiosResponse<Page<PricingReportData | undefined>>> {

    const sortParam = this.buildSortParam( request.sort );

    return this.axios.get( this.baseUrl + "/v1/reporting/pricingReport" + (sortParam.length ? "?" + sortParam : ""), {
      params: { 
        ...formatListQuoteRequestFilter(request),
      } ,
    });

  }

  getTrucks(revId: number): Promise<AxiosResponse<Truck[]>> {
    return this.axios.get( `${this.baseUrl}/v1/quotes/${revId}/trucks`);
  }

  getMasterScheduleUploadHistory(): Promise<AxiosResponse<UploadHistory[]>> {
    return this.axios.get(`${this.baseUrl}/v1/production/uploadHistory`);
  }

  releaseQuoteLock( quoteId:number ) : Promise<AxiosResponse<void>>{
    return this.axios.put( `${this.baseUrl}/v1/quotes/${quoteId}/releaseLock`);
  }

  //this does not save
  previewQuote(obj:SaveDto, quoteId?:number | undefined): Promise<AxiosResponse<Quote>> {
    if ( quoteId ) {
      return this.axios.post( `${this.baseUrl}/v1/quotes/${quoteId}/preview`, obj);
    }
    else {
      return this.axios.post( `${this.baseUrl}/v1/quotes/preview`, obj);
    }
  }

  updateRequestedShipping(quoteRevisionId: number, obj: RequestedShipping) : Promise<AxiosResponse<Quote>> {
    return this.axios.post(`${this.baseUrl}/v1/quotes/revision/${quoteRevisionId}/requestedShipping`, obj );
  }

  getQuotesByAssemblyIdAndPricingSnapshotId(assemblyId: number, pricingSnapshotIdLst: number[], approved?:boolean): Promise<AxiosResponse<string[]>> {
    const pricingSnapshotIds = listParam( pricingSnapshotIdLst );
    return this.axios.get(this.baseUrl + '/v1/assembly/' + encodeURIComponent(assemblyId) + '/snapshot/quotes', { params: {
      pricingSnapshotIds,
      approved
    }});
  }

  async submitSplitWithoutChange(quoteId: number): Promise<void> {
    return this.axios.post(
      this.baseUrl +
        "/v1/workflow/" + quoteId + "/submitSplitWithoutChange"
    );
  }

  getLeadTime(quoteId?:number): Promise<AxiosResponse<ChangeLeadTime>> {
    return this.axios.get(this.baseUrl + '/v1/quotes/lead-time', { params: {
      quoteId
    }});
  }

  loadInternalUsers(filter?: string, dealerId?: string, cancelToken?: CancelToken): Promise<AxiosResponse<User[]>> {
    return this.axios.get(this.baseUrl + '/v1/user/internalUsers', 
      {
        cancelToken,
        params: {
          filter,
          dealerId
        }
      });
  }

  getInternalUser(userId: string): Promise<AxiosResponse<InternalUser>> {
    return this.axios.get(this.baseUrl + '/v1/user/internalUser/' + encodeURIComponent(userId));
  }

  updateInternalUser(internalUser: InternalUser, cancelToken?: CancelToken) : Promise<AxiosResponse<any>> {
    return this.axios.post( `${this.baseUrl}/v1/user/updateInternalUser`, 
    internalUser, { cancelToken } );
  }

  getAllWorkflows(cancelToken?: CancelToken): Promise<AxiosResponse<WorkflowDto[]>> {
    return this.axios.get(`${this.baseUrl}/v1/workflow/allWorkflows`, {cancelToken});
  }

  getUploadCommentDocumentUrl(quoteId:string ) : string {
    return `${this.baseUrl}/v1/quotes/${encodeURIComponent(quoteId)}/comment/document`;
  }

  fetchQuoteComments(quoteId:string, options?:QuoteCommentRequestOptions, cancelToken?:CancelToken): Promise<AxiosResponse<QuoteComment[]>> {
    const  topic = [ options?.topic || [] ].flat().join(",");
    return this.axios.get(`${this.baseUrl}/v1/quotes/${quoteId}/comment`, { 
      cancelToken,
      params:{
      ...options,
      topic
    }} );
  }

  createQuoteComment(quoteId:string, comment:NewQuoteComment): Promise<AxiosResponse<QuoteComment>> {
    return this.axios.post(`${this.baseUrl}/v1/quotes/${quoteId}/comment`, comment);
  }

  updateQuoteComment(quoteId:string, comment:QuoteComment): Promise<AxiosResponse<QuoteComment>> {
    return this.axios.put(`${this.baseUrl}/v1/quotes/${quoteId}/comment/${comment.id}`, comment);
  }

  hideQuoteComment(quoteId:string, commentId:number): Promise<AxiosResponse<QuoteComment>> {
    return this.axios.put(`${this.baseUrl}/v1/quotes/${quoteId}/comment/${commentId}`, { hidden:true});
  }

  showQuoteComment(quoteId:string, commentId:number): Promise<AxiosResponse<QuoteComment>> {
    return this.axios.put(`${this.baseUrl}/v1/quotes/${quoteId}/comment/${commentId}/show`);
  }

  fetchQuoteCommentDocumentUrls(quoteId:string, options?:QuoteCommentRequestOptions) : Promise<AxiosResponse<Record<string,string>>> {
    const topic = [ options?.topic || [] ].flat().join(",");
    return this.axios.get( `${this.baseUrl}/v1/quotes/${quoteId}/comment/document/url`, { params:{
      ...options,
      topic,
    }} );
  }

  fetchQuoteReview(quoteRevisionId: number, options?:QuoteReviewOptions): Promise<AxiosResponse<QuoteReview>> {
    const selectedOptions = listParam( options?.selectedOptions );
    const selectedCustomOptions = listParam( options?.selectedCustomOptions );
    return this.axios.get( `${this.baseUrl}/v1/quotes/${quoteRevisionId}/review`, { params: {
    ...options,
      selectedCustomOptions,
      selectedOptions
    }});
  }

  getAssemblyHistory(assemblyId: number): Promise<AxiosResponse<AssemblyHistory[]>> {
    return this.axios.get( `${this.baseUrl}/v1/assembly/${assemblyId}/getAssemblyHistory` );
  }
  saveQuoteEngineeringLock(quoteId:number, lock:boolean): Promise<AxiosResponse<Quote>> {
    return this.axios.put(`${this.baseUrl}/v1/quotes/${quoteId}/engineeringLock`, { lock });
  }

  saveUserSelection(quoteRevisionId:number, selection:{ assemblyId?:number, customOptionId?:number}): Promise<AxiosResponse<void>> {
    return this.axios.post( `${this.baseUrl}/v1/quotes/revision/${quoteRevisionId}/userSelection`, selection );
  }
  getCustomerExportUrl() {
    return this.baseUrl + "/v1/customer/export";
  }
  getCustomerImportUrl() {
    return this.baseUrl + "/v1/customer/import";
  }

  saveTruckPurchaseOrder(truckId: React.Key, purchaseOrder: string | undefined) {
    return this.axios.post( `${this.baseUrl}/v1/truck/${truckId}/poNumber`, {
      purchaseOrder,
    });
  }

  fetchTruckReport(request: ListTruckRequest): Promise<AxiosResponse<Page<TruckReportDto>>> {

    const sort = this.buildSortParamBody( request.sort ).join(',');

    return this.axios.get( this.baseUrl + "/v1/reporting/truckReport", {
      params: { 
        ...request,
        dealerLst: listParam( request.dealerLst),
        sort,
      } ,
    });

  }

  fetchQuoteShares(quoteId:number): Promise<AxiosResponse<QuoteShare[]>> {
    return this.axios.get(`${this.baseUrl}/v1/quotes/${quoteId}/share`);
  }

  createQuoteShare(quoteId:number, share:NewQuoteShare): Promise<AxiosResponse<QuoteShare>> {
    return this.axios.post(`${this.baseUrl}/v1/quotes/${quoteId}/share`, share );
  }

  deleteQuoteShare(quoteId:number, shareId:number) : Promise<AxiosResponse<void>> {
    return this.axios.delete( `${this.baseUrl}/v1/quotes/${quoteId}/share/${shareId}` ) 
  }

  createNotification(notification: NotificationType) {
    return this.axios.post(`${this.baseUrl}/v1/notification/addNotification`, notification);
  }

  updateNotification(notification: NotificationType) {
    return this.axios.put(`${this.baseUrl}/v1/notification/${notification.id}`, notification);
  }

  getAllNotificationType() {
    return this.axios.get(`${this.baseUrl}/v1/notification`);
  }
  fetchEngineeringTeamList(
    request:{
      page?: number,
      size?: number,
      sort?:SortRequestParam | SortRequestParam[],
      search?: string
    },
    cancelToken?:CancelToken
  ): Promise<AxiosResponse<Page<EngineeringTeam>>> {

    const sort = this.buildSortParamBody( request.sort ).join(',');

    return this.axios.get(`${this.baseUrl}/v1/engineeringTeam`, {
      cancelToken,
      params:{
      ...request,
      sort
    }});
  }

  fetchEngineeringTeam(engineeringTeamId:number): Promise<AxiosResponse<EngineeringTeam>> {
    return this.axios.get(`${this.baseUrl}/v1/engineeringTeam/${engineeringTeamId}` );
  }

  createEngineeringTeam(engineeringTeam:NewEngineeringTeamRequest): Promise<AxiosResponse<EngineeringTeam>> {
    return this.axios.post(`${this.baseUrl}/v1/engineeringTeam`, engineeringTeam );
  }

  updateEngineeringTeam(engineeringTeam:EngineeringTeamRequest): Promise<AxiosResponse<EngineeringTeam>> {
    return this.axios.put(`${this.baseUrl}/v1/engineeringTeam/${engineeringTeam.id}`, engineeringTeam );
  }

  deleteEngineeringTeam(engineeringTeamId:number) : Promise<AxiosResponse<void>> {
    return this.axios.delete(`${this.baseUrl}/v1/engineeringTeam/${engineeringTeamId}` );
  }

  updateCustomWorkTeam(customWorkTeam:CustomWorkTeamRequest): Promise<AxiosResponse<CustomWorkReview>> {
    return this.axios.put(`${this.baseUrl}/v1/quotes/${customWorkTeam.quoteId}/customWorkTeam/category/${customWorkTeam.categoryId}`, customWorkTeam  );
  }

  saveQuoteHot(quoteId:number, hot:boolean): Promise<AxiosResponse<Quote>> {
    return this.axios.put(`${this.baseUrl}/v1/quotes/${quoteId}/engineeringStatusHot`, { hot });
  }

  updateWorkflowStep(workflowStepId: number, request: WorkflowStepRequest) {
    return this.axios.post(`${this.baseUrl}/v1/workflow/editWorkflowStep/${workflowStepId}`, request );
  }

  insertWorkflowStep(workflowStepId: number, request: WorkflowStepRequest) {
    return this.axios.post(`${this.baseUrl}/v1/workflow/insertWorkflowStep/${workflowStepId}`, request );
  }

  createWorkflow(obj: WorkflowRequest) {
    return this.axios.post(`${this.baseUrl}/v1/workflow`, obj );
  }

  updateWorkflowNotes(obj: WorkflowRequest, cancelToken?:CancelToken) {
    return this.axios.post(`${this.baseUrl}/v1/workflow/notes`, obj, {cancelToken} );
  }

  getBendixParFileExportUrl(revId: number, truckId: number) {
    return this.baseUrl + `/v1/quotes/${revId}/exportPar/${truckId}`;
  }

  getUserPreference() : Promise<AxiosResponse<UserPreference>> {
    return this.axios.get(`${this.baseUrl}/v1/preference` );
  }

  getTableFilters() : Promise<AxiosResponse<TableFilter[]>> {
    return this.axios.get(`${this.baseUrl}/v1/preference/tableFilters` );
  }

  saveTableFilter(obj: TableFilter) : Promise<AxiosResponse<any>> {
    return this.axios.post(`${this.baseUrl}/v1/preference/saveTableFilter`, obj);
  }

  deleteTableFilter(queryId: number) : Promise<AxiosResponse<any>> {
    return this.axios.delete(`${this.baseUrl}/v1/preference/deleteQuery/${queryId}`);
  }

  getOptionFilters() : Promise<AxiosResponse<OptionFilter[]>> {
    return this.axios.get(`${this.baseUrl}/v1/preference/optionFilters` );
  }

  saveOptionFilter(obj: OptionFilter) : Promise<AxiosResponse<any>> {
    return this.axios.post(`${this.baseUrl}/v1/preference/saveOptionFilter`, obj);
  }

  deleteOptionFilter(optionFilterId: number) : Promise<AxiosResponse<any>> {
    return this.axios.delete(`${this.baseUrl}/v1/preference/deleteOptionFilter/${optionFilterId}`);
  }

  convertReservation(quoteId:number) : Promise<AxiosResponse<Quote>> {
    return this.axios.put( `${this.baseUrl}/v1/quotes/${quoteId}/reservation`, {
        reservation:false
    });
  }

  fetchBtsReport(params:BtsReportRequest, cancelToken?:CancelToken) : Promise<AxiosResponse<Page<BtsReport>>> {
    const sort = this.buildSortParamBody( params.sort ).join(',');
    return this.axios.get( `${this.baseUrl}/v1/bts/report`, {
      cancelToken,
      params: {
      ...params,
      sort
    }});
  }

  updateBtsReportStatus(quoteId:string, status:BTS_REPORT_STATUS) : Promise<AxiosResponse<void>> {
    return this.axios.post( `${this.baseUrl}/v1/bts/report/${quoteId}/status`, {
      status
    });
  }
  
  getSalesSupports() : Promise<AxiosResponse<RepresentativeSalesSupport[]>> {
    return this.axios.get(`${this.baseUrl}/v1/quotes/SalesSupports` );
  }

  createSalesSupport(obj: RepresentativeSalesSupportRequest) : Promise<AxiosResponse<any>> {
    return this.axios.post(`${this.baseUrl}/v1/quotes/createSalesSupport`, obj);
  }

  updateSalesSupport(obj: RepresentativeSalesSupportRequest) : Promise<AxiosResponse<any>> {
    return this.axios.post(`${this.baseUrl}/v1/quotes/updateSalesSupport`, obj);
  }

  assignSalesSupport(quoteId: number, salesSupportId: string) {
    return this.axios.post( `${this.baseUrl}/v1/quotes/${quoteId}/assignSalesSupport/${encodeURIComponent(salesSupportId)}`, {
      reservation:false
  });
  }

  fetchQuoteAudit(request:QuoteAuditInfoRequest, cancelToken?:CancelToken): Promise<AxiosResponse<Page<QuoteAuditInfo>>> {
    const sort = this.buildSortParamBody( request.sort ).join(',');
    return this.axios.get( `${this.baseUrl}/v1/quotes/${request.quoteId}/audit`, {
      cancelToken,
      params: {
      ...request,
      sort
    }});
  }
  
  fetchQuoteByAudit( auditId:number, cancelToken?:CancelToken): Promise<AxiosResponse<QuoteAuditData>> {
    return this.axios.get( `${this.baseUrl}/v1/quotes/audit/${auditId}`, { cancelToken });
  }

  fetchQuoteAuditDiff( auditIdA:number | undefined, auditIdB?:number | undefined, cancelToken?:CancelToken): Promise<AxiosResponse<ApprovalDiff>> {
    return this.axios.get( `${this.baseUrl}/v1/quotes/audit/diff`, { 
      params: {
        auditIdA,
        auditIdB
      },
      cancelToken 
    });
  }

  async listMasterSchedule(listQuotesRequest: ListQuotesRequest, cancelToken?:CancelToken): Promise<ListMasterScheduleResponse> {
    const params = {
      ...formatListQuoteRequestFilter(listQuotesRequest),
    }
    const response = await this.axios.get(`${this.baseUrl}/v1/production/masterSchedule`, {
      cancelToken,
      params,
    });

    return listMasterScheduleResponse.parse(response.data);
  }

  listMasterScheduleCalendar(listQuotesRequest: ListQuotesRequest, cancelToken?:CancelToken): Promise<AxiosResponse<Page<TruckCalendarDto>>> {
    const params = {
      ...formatListQuoteRequestFilter(listQuotesRequest),
    }
    return this.axios.get(`${this.baseUrl}/v1/production/masterSchedule/calendar`, {
      cancelToken,
      params,
    });
  }

  async updatePilotInspection(truckId: number, request: UpdatePilotInspectionRequest) {
    await this.axios.post(`${this.baseUrl}/v1/production/${encodeURIComponent(truckId)}/pilotInspection`, request, {
      params: {truckId}
    });
  }

  saveTruck(truckId:React.Key, dto:SaveTruckRequest) : Promise<AxiosResponse<Truck>> {
    return this.axios.post( `${this.baseUrl}/v1/truck/${truckId}`, dto );
  }

  fetchUserSalesTeamList(
    request:{
      page?: number,
      size?: number,
      sort?:SortRequestParam | SortRequestParam[],
      search?: string,
    },
    cancelToken?:CancelToken
  ): Promise<AxiosResponse<Page<UserSalesTeam>>> {

    const sort = this.buildSortParamBody( request.sort ).join(',');

    return this.axios.get(`${this.baseUrl}/v1/salesTeam/users`, {
      params:{
        ...request,
        isUserSalesTeam: true,
        sort
      },
      cancelToken
    });
  }

  fetchSalesTeamList(
    request:{
      page?: number,
      size?: number,
      sort?:SortRequestParam | SortRequestParam[],
      search?: string,
      dealerId?:string,
      isUserSalesTeam?:boolean
      isDealerSalesTeam?:boolean
    },
    cancelToken?:CancelToken
  ): Promise<AxiosResponse<Page<SalesTeam>>> {

    const sort = this.buildSortParamBody( request.sort ).join(',');

    return this.axios.get(`${this.baseUrl}/v1/salesTeam`, {
      params:{
        ...request,
        sort
      },
      cancelToken
    });
  }

  fetchSalesTeam(salesTeamId:number): Promise<AxiosResponse<SalesTeam>> {
    return this.axios.get(`${this.baseUrl}/v1/salesTeam/${salesTeamId}` );
  }

  createUserSalesTeam(userId:string): Promise<AxiosResponse<SalesTeam>> {
    return this.axios.post(`${this.baseUrl}/v1/salesTeam/users/${encodeURIComponent(userId)}` );
  }

  updateSalesTeam(salesTeam:SalesTeamRequest): Promise<AxiosResponse<SalesTeam>> {
    return this.axios.put(`${this.baseUrl}/v1/salesTeam/${salesTeam.id}`, salesTeam );
  }

  deleteUserSalesTeam(userId:string) : Promise<AxiosResponse<void>> {
    return this.axios.delete(`${this.baseUrl}/v1/salesTeam/users/${encodeURIComponent(userId)}` );
  }

  refreshDealerQuoteSalesTeams(dealerId:string, quotes:number[] | undefined) : Promise<AxiosResponse<void>> {
    return this.axios.put(`${this.baseUrl}/v1/dealer/${dealerId}/quotes/salesTeams`, {
      quotes,
    });
  }

  refreshDealerSalesTeam(primarySalesId:string, dealers:string[] | undefined) : Promise<AxiosResponse<void>> {
    return this.axios.put(`${this.baseUrl}/v1/dealer/salesTeams`, {
      primarySalesId,
      dealers,
    });
  }

  refreshQuoteSalesTeam(primarySalesId:string, quotes:number[] | undefined) : Promise<AxiosResponse<void>> {
    return this.axios.put(`${this.baseUrl}/v1/quotes/salesTeams`, {
      primarySalesId,
      quotes,
    });
  }

  fetchSales(source?:CancelTokenSource) :Promise<AxiosResponse<User[]>> {
    return this.axios.get(`${this.baseUrl}/v1/user/sales`, {
      cancelToken: source?.token
    });
  }

  fetchEngineers(source?:CancelTokenSource) :Promise<AxiosResponse<User[]>> {
    return this.axios.get(`${this.baseUrl}/v1/user/engineers`, {
      cancelToken: source?.token
    });
  }

  fetchSupport(source?:CancelTokenSource) :Promise<AxiosResponse<User[]>> {
    return this.axios.get(`${this.baseUrl}/v1/user/support`, {
      cancelToken: source?.token
    });
  }

  cloneTemplate(template: DashTemplate) :Promise<AxiosResponse<number>> {
    return this.axios.post(`${this.baseUrl}/v1/dash-component/cloneTemplate`,  template);
  }


}

export default ConfiguratorAPI;
