import Title from "antd/lib/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import { Table, Button, notification, Drawer, DrawerProps, Form, Input, Select, SelectProps, Badge, Collapse, Space, Modal } from "antd";
import  { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { InfoCircleTwoTone, ExclamationOutlined } from "@ant-design/icons";
import { ConfiguratorContext, UserListContext, UsersContext, } from "../context";
import { Link } from "react-router-dom";
import dayjs from 'dayjs'
import { ArrayParam, NumberParam, StringParam, useQueryParam } from "use-query-params";
import Utils from "../util/util";
import {
  AXIOS_CANCEL_MSG,
  BaseQuote,
  CategoryInfo,
  CommentTopic,
  CustomWorkReview,
  EngineeringTeam,
  PAGINATION_MAX_PAGE_SIZE,
  Quote,
  QuoteComment,
  User
} from "../api/models";
import { ColumnType, FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import useCheckMobileScreen from "../hook/useCheckMobileScreen";
import {debounce} from "lodash";
import {AsyncState, useAsyncState} from "../hook/useAsyncState";
import { getCSVRow } from "../helpers/csv";
import {useIntl} from "react-intl";
import {SortOrder} from "antd/es/table/interface";
import CommentActivityList from "../components/CommentActivityList";
import {FormProps, useForm} from "antd/es/form/Form";
import SelectEngineeringTeamsButtonModal from "../components/Quote/SelectEngineeringTeamsButtonModal";
import { useCategoryContext } from "../contexts/CategoryContext";
import axios, {CancelTokenSource} from "axios";
import { AdvancedSearchConfig } from "../components/QuoteFilterControls";
import BMButton, { BMButtonProps } from "../components/BMButton";
import UserMultiSelector from "../components/UserMultiSelector";
import { CustomWorkTeamRequest } from "../api";
import {ValidateErrorEntity} from "rc-field-form/lib/interface";
import UserSingleSelector from "../components/widgets/UserSingleSelector";
import QuoteEngineerSelector from "../components/QuoteEngineerSelector";

type  SortResult<T> = SorterResult<T> | SorterResult<T>[]

const getDueDateStr = (date: Date ) => {
  const days = date ? dayjs(date).diff(dayjs(), 'day') : undefined
  return days !== undefined ? days + " days" : 'Not Available';
};

const reviewFilterDefaults = {
  includingArchived: false,
  ordersOnly: true,
  review: true,
}

interface CustomWorkFilter {
  search?: string
  categoryId?: number
  teamId?:number
  engineers?:string[]
}


const CommentList = (props: {quoteId:string|undefined}) => <CommentActivityList {...props} topic={CommentTopic.VfdReview} />

const DEFAULT_PAGE_SIZE = 10;
const CustomWorkReviewPage = () => {
  const isMobile = useCheckMobileScreen();
  const intl = useIntl();

  const configurator = useContext(ConfiguratorContext);

  const defaultEngineer = ( !configurator.isAdmin() && configurator.isEngineering() && !!configurator.userInfo?.name) ? [configurator.userInfo.name] : [];
 
  const [customWorkLst, customWorkLstAsync] = useAsyncState<CustomWorkReview[]>([]);
  const [searchFilterParam, setSearchFilterParam] = useQueryParam<string|undefined|null>("search", StringParam);
  const [teamIdParam, setTeamIdParam] = useQueryParam<number|undefined|null>("team", NumberParam);
  const [categoryIdParam, setCategoryIdParam] = useQueryParam<number|undefined|null>("category", NumberParam);
  const [engineersParam, setEngineersParam] = useQueryParam<Array<string | null> | undefined | null>("engineers", ArrayParam);
  const [pageSizeQueryParam, setPageSizeQueryParam] = useQueryParam<number|undefined|null>("nr", NumberParam);
  const [currentPageParam, setCurrentPageParam] = useQueryParam<number|undefined|null>("p", NumberParam);
  const [sortFieldQueryParam, setSortFieldQueryParam] = useQueryParam<string|undefined|null>("sf", StringParam);
  const [sortDirectionQueryParam, setSortDirectionQueryParam] = useQueryParam<string|undefined|null>("sd", StringParam);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [filter, setFilter] = useState<CustomWorkFilter>({
    search: searchFilterParam || undefined, //silly fix for null
    teamId: teamIdParam || undefined, //silly fix for null
    categoryId: categoryIdParam || undefined, //silly fix for null
    engineers: (engineersParam || undefined) as (string[] | undefined) || defaultEngineer, //silly fix for null
  });
  const [quote, setQuote] = useState<BaseQuote | undefined>();
  const [isCommentsOpen, setIsCommentsOpen] = useState<boolean>(false);
  const [quoteCommentCnt, setQuoteCommentCnt] = useState<Record<number,number>>();
  const cancelTokenSourceRef = useRef<CancelTokenSource>();

  const defaultSort = {
    columnKey: 'dueDate',
    order: 'ascend' as SortOrder
  };
  const [sort, setSort] = useState<SortResult<CustomWorkReview>>({
    columnKey: sortFieldQueryParam || defaultSort.columnKey,
    order: sortDirectionQueryParam as ( SortOrder | undefined ) || defaultSort.order
  });

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    position: ["bottomLeft"],
    pageSize: pageSizeQueryParam == null || pageSizeQueryParam > 500 ? DEFAULT_PAGE_SIZE : pageSizeQueryParam,
    current: currentPageParam == null || currentPageParam < 1 ? 1 : currentPageParam,
    showLessItems: isMobile,
  });

  //change in filters should trigger refetch
  useEffect(() => {
    reloadCustomWorkOrders()
  }, [ filter, pagination.pageSize, pagination.current, sort ]);

  useEffect(() => {
    setPageSizeQueryParam(pagination.pageSize);
    setCurrentPageParam(pagination.current);
  }, [pagination.pageSize, pagination.current ]);

  useEffect(() => {
    if ( customWorkLstAsync.isDone() ) {
        Promise.all(customWorkLst?.map( cw => loadQuoteComments(cw.quote).then( commentLst => ({[ cw.quote.id ]: commentLst?.length || 0 }))) || [])
        .then( qc => {
          const quoteCommentCnt = qc.reduce( ( acc, v ) => ({...acc, ...v}), {}) ;
          setQuoteCommentCnt( quoteCommentCnt );
        });
    }
  }, [ customWorkLstAsync ]);


  const reloadCustomWorkOrders = () => loadCustomWorkOrders( customWorkLstAsync, pagination, filter , sort );
  const loadCustomWorkOrders = useCallback(debounce( async (customWorkLstAsync:AsyncState<CustomWorkReview[]>, pagination: TablePaginationConfig, filter: CustomWorkFilter, sorter:SortResult<CustomWorkReview> ) => {

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    const sort = [ sorter ].flat()[0];
    customWorkLstAsync.setLoading();
    try {
      const resp = await configurator.api.listCustomWork({
          ...filter,
          ...reviewFilterDefaults,
          page: ( pagination.current || 1 ) - 1,
          size: pagination.pageSize || DEFAULT_PAGE_SIZE,
          sort: {
            field: sort.columnKey?.toString() ||  defaultSort.columnKey,
            direction: sort.order == 'ascend' ? 'asc' : 'desc',
          }
        },
        cancelSource.token,
      )
      cancelTokenSourceRef.current = undefined;

      customWorkLstAsync.setDone( resp.data.content );
      setPagination({ ...pagination, total: resp.data.totalElements });
    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error({message:"Quotes failed to load. " + errorMsg});
        customWorkLstAsync.setFail( e.message );
      }
    }

  }, 700 ), [] );

  const loadQuoteComments = async (quote:BaseQuote) : Promise<QuoteComment[] | undefined> => {
    if ( !quote ) return;

    try {
      const topic = [ CommentTopic.VfdReview ]
      const resp = await configurator.api.fetchQuoteComments(quote.quoteId, {topic});
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch comments " + errorMsg });
    }
    return;
  }
  
  const handleAssignTeam = async (engineeringTeam:EngineeringTeam | undefined, category: CategoryInfo ) : Promise<EngineeringTeam | undefined> => {
    if ( !engineeringTeam ) return;

    try {
      const resp = await configurator.api.updateCategoryEngineeringTeam(category.id, engineeringTeam?.id);

      reloadCustomWorkOrders();
      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to assign Engineering Team. " + errorMsg;
      notification.error( { message: msg });
    }
  }

  const handleShowComments = (quote:BaseQuote) => {
    setQuote( quote );
    setIsCommentsOpen(true);
  }

  const tableOnChange =  (pagination:TablePaginationConfig, _filters:Record<string, FilterValue | null>, sorter: SortResult<CustomWorkReview>) => {
    setPagination(pagination);

    const firstSort = [ sort ].flat()[0];
    setSortFieldQueryParam( firstSort.columnKey?.toString() );
    setSortDirectionQueryParam( firstSort.order );
    setSort(sorter);
  };

  const handleFilterChange = ( filter:CustomWorkFilter ) => {
    updateFilter({
      search: filter.search,
      ...filter,
    });
  }
  const handleSearchChange = ( search:string ) => {
    updateFilter({
      ...filter,
      search,
    });
  }
  const updateFilter = ( filter:CustomWorkFilter ) => {
    setSearchFilterParam(filter.search);
    setTeamIdParam(filter.teamId);
    setCategoryIdParam(filter.categoryId);
    setEngineersParam(filter.engineers);
    setPagination({ ...pagination, current: 1 });

    setFilter(filter);
  };

  const exportCustomWork = async () => {
    try {
      setIsExporting(true);
      const resp = (await configurator.api.listCustomWork({
        ...filter,
        ...reviewFilterDefaults,
        page: 0,
        size: PAGINATION_MAX_PAGE_SIZE, 
        sort: {
          direction: 'asc',
          field: 'dueDate',
        }
      })).data;

      var csv = [[
        'Hot',
        'Category',
        'PrimaryEngineer',
        'Team',
        'PartNo',
        'Status',
        'Model',
        'ProductionDate',
        'DueDate',
      ].join(',')];

      resp.content.forEach((cw: CustomWorkReview) => {
        csv.push(getCSVRow([
          String(cw.quote.engineeringStatusHot),
          Utils.stripSortingPrefix(cw.categoryInfo.name),
          cw.primaryEngineer?.name || "",
          cw.engineeringTeam?.name || "",
          cw.quote?.partNumberString || "",
          Utils.formatQuoteStatusStr(intl, cw.quote) || "", 
          cw.quote.model.name,
          cw.quote.productionDate || "",
          dayjs(cw.dueDate).format("MM/DD/YYYY")
        ]));
      });
      var blob = new Blob([csv.join('\n')], {type: 'text/csv;charset=utf-8'});
      var url = URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = 'custom-work-export-' + (new Date()) + '.csv';
      document.body.appendChild(a);
      a.click();
    }
    finally {
      setIsExporting(false);
    }
  };

  const saveEngineeringHot = async (quoteId:number, hot:boolean) : Promise<Quote | undefined> => {
    if ( !quoteId ) return;

    try {
      const resp = await configurator.api.saveQuoteHot(quoteId, hot);

      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to set order as hot. " + errorMsg });
    }

    return;
  }

  const toggleQuoteHot = async (quote:BaseQuote) => {
    await saveEngineeringHot( quote.id, !quote.engineeringStatusHot );

    reloadCustomWorkOrders();
  }

  const firstSort = Array.isArray(sort) ? sort[0] : sort;

  let columns:ColumnType<CustomWorkReview>[] = [
    {
      title: "Hot",
      key: "quote.engineeringStatusHot",
      sorter: true,
      render: (cw:CustomWorkReview) => {
        return configurator.isAdmin() 
          ? <Button onClick={() => toggleQuoteHot(cw.quote)} type="text" shape="circle" icon={<>{cw.quote.engineeringStatusHot && <ExclamationOutlined style={{color: "red"}} />}</>} />
          : cw.quote.engineeringStatusHot && <div style={{textAlign: "center"}}><ExclamationOutlined style={{color: "red"}} /></div> }
    },
    {
      title: "Category",
      key: "category.name",
      sorter: true,
      render: (cw:CustomWorkReview) => 
          <Link to={"/categories/" + cw.categoryInfo.id} target="_blank">{Utils.stripSortingPrefix( cw.categoryInfo?.name )}</Link>
    },
    {
      title: "Engineer",
      key: "primaryEngineer",
      sorter: true,
      render: (cw:CustomWorkReview) => 
        <EditCustomWorkTeamButtonModal 
          value={cw}
          onChange={reloadCustomWorkOrders} />
    },
    {
      title: "Team",
      key: "engineeringTeam",
      sorter: true,
      render: (cw:CustomWorkReview) => {
        if ( !cw.engineeringTeam ) {
          return <SelectEngineeringTeamsButtonModal category={cw.categoryInfo} onChange={(engTeam) => handleAssignTeam(engTeam, cw.categoryInfo )} />
        }
        return cw.engineeringTeam.name;
      }
    },
    {
      title: "Part No.",
      key: "partNumberMinor",
      sorter: true,
      render: (cw:CustomWorkReview) => {
        return <>
          <Link to={"/configurator/" + encodeURI(cw.quote.quoteId)} target="_blank"><span style={{whiteSpace: "nowrap"}}>{cw.quote.partNumberString}</span></Link>
          <Button onClick={() => handleShowComments(cw.quote)} 
            shape="circle" 
            type="text"
            icon={<Badge count={quoteCommentCnt?.[cw.quote.id]} size="small" >
              <InfoCircleTwoTone />
            </Badge>} />
        </>
      }
    },
    {
      title: "Model",
      key: "model.name",
      sorter: true,
      render: (cw:CustomWorkReview) => cw.quote.model.name
    },
    {
      title: "Status",
      key: "quote.status",
      sorter: true,
      render: (cw:CustomWorkReview) => Utils.formatQuoteStatus(cw.quote),
    },
    {
      title: "Notes",
      key: "notes",
      sorter: true,
      render: (cw:CustomWorkReview) => 
        <Paragraph
          style={{maxWidth: "20rem"}}
          ellipsis={{ rows: 1, expandable: true}}
        >
          {cw.notes}
        </Paragraph>
    },
    {
      title: "Production",
      key: "productionDate",
      sorter: true,
      render: (cw:CustomWorkReview) => dayjs(cw.quote.productionDate).format("MM/DD/YYYY")
    },
    {
      title: "Due",
      key: "dueDate",
      sorter: true,
      defaultSortOrder: firstSort.order,
      render: (cw:CustomWorkReview) => getDueDateStr(cw.dueDate),
    },
  ];

  const datasourceAsync = customWorkLstAsync;
  const datasource = datasourceAsync.val;

  return <div className="site-layout-background">

    <Space direction="vertical" style={{width:"100%"}} >

    <div style={{width: "100%", display: "flex", justifyContent:"space-between" }}>
        <Title level={2}>Custom Work Review</Title>
        <Button type="primary" loading={isExporting} onClick={exportCustomWork}>Export</Button>
    </div>

    <div>
      <Input value={filter.search} onChange={(e) => handleSearchChange(e.target.value)} placeholder="Search keywords" allowClear />
    </div>

    <CollapseCustomWorkFilter 
      filter={filter}
      onFilterChange={handleFilterChange}
    />

   <Table
      loading={datasourceAsync.isLoading()}
      onChange={tableOnChange}
      bordered
      pagination={pagination}
      dataSource={datasource}
      columns={columns}
      rowKey={(r) => r.quote.quoteId + r.categoryInfo.id }
    />

    </Space>

    <CommentsDrawer quote={quote} 
      open={isCommentsOpen && !!quote}
      onClose={() => setIsCommentsOpen(false)}
    />

    </div>;
};

const CommentsDrawer = (props:DrawerProps & { quote:BaseQuote | undefined }) => {

  const { quote, ...drawerProps } = props;

  return <>
    <Drawer
      {...drawerProps}
      title={<>
        <div style={{display:"flex", justifyContent: "space-between", alignItems:"center"}} >
          <div>{quote?.partNumberString} Comment(s)</div>
        </div>
      </>}
    >
      <CommentList quoteId={quote?.quoteId} />
    </Drawer>
  </>
}

const CollapseCustomWorkFilter = (props: {
  filter?: CustomWorkFilter
  onFilterChange: (filter:CustomWorkFilter) => void
}) => {

  const showAdvancedPanel = Object.values({ ...props.filter, search:undefined } ).some( v => v !== undefined );

  const [filterForm] = useForm();

  return <Collapse
    size="small"
    defaultActiveKey={showAdvancedPanel ? "advanced-search" : undefined }
    {...AdvancedSearchConfig}
    items={[{
      key: "advanced-search",
      label: <span style={{color: "#1677FF"}}>Advanced Search</span>,
      forceRender: true,
      children: <>
        <Form 
          form={filterForm} 
          initialValues={props.filter}
          onValuesChange={(_values: Record<string, any>, filter:CustomWorkFilter) => {
            props.onFilterChange(filter)}
          }
          layout="vertical"
        >
          <Form.Item name="categoryId" >
            <CategorySelector 
              placeholder="Search category"
            />
          </Form.Item>
          <Form.Item name="teamId" >
            <TeamSelector 
              placeholder="Search team"
            />
          </Form.Item>
          <Form.Item name="engineers" >
            <QuoteEngineerSelector
              placeholder="Search engineers"
            />
          </Form.Item>

        </Form>
      </>
    }]} 
  />

}

const CategorySelector = ( props: SelectProps<number> ) => {
  const {categoriesAsync, loadCategories} = useCategoryContext();

  useEffect(() => {
    if ( ( categoriesAsync?.isInitial() || categoriesAsync?.isFail() ) && !categoriesAsync.isLoading() ) {
      loadCategories?.();
    }
  }, [])

  const { ...selectProps} = props; 
  const categoryLst = categoriesAsync?.val;
  const options = categoryLst?.sort((a,b) => a.name.localeCompare(b.name)).map(c => ({label: c.name, value: c.id}));

  return <Select 
    {...selectProps}
    showSearch
    optionFilterProp="label" 
    allowClear
    options={options}
  />

};


const TeamSelector = (props: SelectProps<number> ) => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const [teamLst, teamLstAsync] = useAsyncState<EngineeringTeam[]>([]);
  const cancelTokenSourceRef = useRef<CancelTokenSource>();

  useEffect(() => {
    if( teamLstAsync.isInitial() ) {
      handleSearch();
    }
  }, []);


  const handleFocus = () => {
    if( teamLstAsync.isInitial() ) {
      handleSearch();
    }
  }

  const handleSearch = async (s?:string) => {
    loadEngineeringTeams( teamLstAsync, s );
  };

  const loadEngineeringTeams = useCallback(debounce( async (engineeringTeamLstAsync:AsyncState<EngineeringTeam[]>, search:string | undefined ) => {

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    engineeringTeamLstAsync.setLoading();
    try {
      const resp = await configurator.api.fetchEngineeringTeamList({
          search
        },
        cancelSource.token
      );
      cancelTokenSourceRef.current = undefined;

      engineeringTeamLstAsync.setDone( resp.data.content );
    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error({message:"Engineering Teams failed to load. " + errorMsg});
        engineeringTeamLstAsync.setFail( e.message );
      }
    }

  }, 700 ), [] );

  return <Select
    {...props}
    showSearch
    allowClear
    onFocus={handleFocus}
    optionFilterProp="label"
    onSearch={handleSearch}
    loading={teamLstAsync?.isLoading()}
    options={teamLst?.map(team => ({label:team.name, value:team.id}))}
  />

};


const EditCustomWorkTeamButtonModal = (props: Omit<BMButtonProps, "id" | "value" | "onChange" > & {
  value:CustomWorkReview
  onChange?: (v:CustomWorkReview | undefined) => void
}) => {

  const {value:a, onChange:b, ...btnProps} = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [form] = Form.useForm();

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const {categoriesAsync} = useCategoryContext();

  const [selectedCustomWorkReview, selectedCustomWorkReviewAsync] = useAsyncState<CustomWorkReview>();
  const [engineeringTeam, engineeringTeamAsync] = useAsyncState<EngineeringTeam>();
  const [initialValues, setInitialValues] = useState<CustomWorkTeamFormValues>();

  useEffect( () => {
    //clear engineering team
    engineeringTeamAsync.setInit();
  }, [props.value.engineeringTeam?.id])

  useEffect( () => {
    form.resetFields();
  }, [initialValues])

  const getEngineeringTeam = async (id:number | undefined) : Promise<EngineeringTeam | undefined> => {
    if (engineeringTeamAsync.isInitial() || engineeringTeamAsync.isFail() ) {
      return await loadEngineeringTeam(id);
    }
    return engineeringTeam;
  }
  const loadEngineeringTeam = async (id:number | undefined) : Promise<EngineeringTeam | undefined> => {
    if( !id ) return;

    engineeringTeamAsync.setLoading()
    try {
      const resp = await configurator.api.fetchEngineeringTeam(id);
      engineeringTeamAsync.setDone(resp.data);
      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to load Engineering Team. " + errorMsg;
      notification.error( { message: msg });
      engineeringTeamAsync.setFail(msg);
    }

    return;
  }

  const saveCustomWorkTeam = async (customWorkTeam:CustomWorkTeamRequest) : Promise<CustomWorkReview | undefined> => {

    selectedCustomWorkReviewAsync.setLoading()
    try {
      const resp = await configurator.api.updateCustomWorkTeam(customWorkTeam)
      selectedCustomWorkReviewAsync.setDone(resp.data);
      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to edit engineering Team. " + errorMsg;
      notification.error( { message: msg });
      selectedCustomWorkReviewAsync.setFail(msg);
    }

    return;
  }

  const handleAfterOpen = async (open:boolean) => {
    if (open) {

      //if primary or engineers are set, use them
      //else load them from category and team defaults
      if ( props.value.primaryEngineer || props.value.engineers?.length ) {
        setInitialValues({
          primaryEngineer: props.value.primaryEngineer,
          engineers: props.value.engineers,
        });
      }
      else {

        const category = categoriesAsync?.val?.find( c => c.id === props.value.categoryInfo.id );
        const primaryEngineer = category?.primaryEngineer;
        const engineers = ( await getEngineeringTeam(category?.engineeringTeam?.id) )?.members

        setInitialValues({
          primaryEngineer,
          engineers,
        });
      }
    }
  }

  const handleCancel = () => {
    setIsOpen(false);
  }

  const handleOk = async () => {
    try {
      const formValues:CustomWorkTeamFormValues = await form.validateFields();
      const req = {
        quoteId: props.value.quote.id,
        categoryId: props.value.categoryInfo.id,
        primaryEngineerId: formValues.primaryEngineer?.id,
        engineerIds: formValues.engineers?.map(e => e.id ),
      }
      const customWorkTeam = await saveCustomWorkTeam(req);
      if( customWorkTeam ) {
        props.onChange?.( {
          ...selectedCustomWorkReview,
          ...customWorkTeam,
        });
        setIsOpen(false);
      }
    }
    catch(e:any) {
      const validationErrors = e as ValidateErrorEntity;
      const errs = validationErrors.errorFields.map( f => f.errors ).flat().map( (msg, ndx) => <li key={`validationError-${ndx}`}>{msg}</li> );
      const errorMsg = !errs.length ? "Please fix validation errors." : <ul>{errs}</ul>;
      notification.error({message: errorMsg });
    }
  }
  const handleReset = async () => {
      const req = {
        quoteId: props.value.quote.id,
        categoryId: props.value.categoryInfo.id,
      }
      const customWorkTeam = await saveCustomWorkTeam(req);
      if( customWorkTeam ) {
        props.onChange?.( {
          ...selectedCustomWorkReview,
          ...customWorkTeam,
        });
        setIsOpen(false);
      }
  }

  const btnStyle = {borderBottom: "1px solid black"};

  const engineers = [props.value.primaryEngineer, props.value.engineers || []].flat().filter(v => v);
  const btnTitle = engineers.length 
    ? <ul style={{ listStyle: "none", padding: 0, margin: 0, textAlign: "left" }} >{engineers?.map(e => e?.name).map(n => (<li style={{paddingBottom: ".4rem"}}><span style={btnStyle}>{n}</span></li>))}</ul>
    : <span style={btnStyle}>None</span>;

  return <>
    <BMButton {...btnProps}
              type="text"
              className="ghostBmButton"
              onClick={() => setIsOpen(true)}
    >{btnTitle}</BMButton>
    <Modal
      open={isOpen}
      width="50rem"
      title="Edit Custom Work Team"
      onCancel={handleCancel}
      afterOpenChange={handleAfterOpen}
      footer={ <div style={{display: "flex", gap: ".5rem", justifyContent: "space-between", padding: "1rem .3rem .3rem .3rem" }}>
        <div>
          <Button danger onClick={handleReset}>Reset</Button>
        </div>
        <div>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button type="primary" onClick={handleOk}>Save</Button>
        </div>
      </div> }
    >
      <div key="formStep">
        <CustomWorkTeamForm form={form} initialValues={initialValues} />
      </div>
    </Modal>
  </>;
}


export interface CustomWorkTeamFormValues {
  primaryEngineer: User | undefined
  engineers:User[] | undefined
}

export const CustomWorkTeamForm = (props:FormProps ) =>  {

  const { ...formProps} = props;

  const {userLstAsync, loadUserList} = useContext<UserListContext>(UsersContext);

  useEffect(() => {
    if ( ( userLstAsync?.isInitial() || userLstAsync?.isFail() ) && !userLstAsync.isLoading() ) {
      loadUserList?.();
    }
  }, [])

  const internalUserLst = useMemo(() => userLstAsync?.val?.filter(u => !u.dealerId ), [userLstAsync])

  return <Form 
    {...formProps}
    layout="vertical"
  >
    <Form.Item name="primaryEngineer" 
      label="Primary Engineer"
    >
      <UserSingleSelector userLst={internalUserLst}/>
    </Form.Item>
    <Form.Item name="engineers" 
      label="Additional Engineers"
    >
      <UserMultiSelector userLst={internalUserLst}/>
    </Form.Item>
  </Form>
}


export default CustomWorkReviewPage;
