import {Button, Checkbox, Form, Modal, notification, Result, Spin} from "antd";
import Title from "antd/lib/typography/Title";
import {useForm} from "antd/es/form/Form";
import {useContext, useState} from "react";
import {useIntl} from "react-intl";
import { OrderSubmissionLegalPhrase, Quote, QuoteReview} from "../../api/models";
import {ConfiguratorContext} from "../../context";
import BMButton, {BMButtonProps} from "../BMButton";
import {ValidateFields} from "rc-field-form/lib/interface";
import ModalWizard from "../ModalWizard";
import QuoteReviewDetail from "../QuoteReviewDetail";
import {useAsyncState} from "../../hook/useAsyncState";
import Utils from "../../util/util";
import { useQuoteContext } from "../../contexts/QuoteContext";


export const AbandonReviseQuoteButton = (props:Omit<BMButtonProps, 'onChange' | 'onClick'> & {
  onChange?: (q:Quote | undefined) => void
}) => {

  const { onChange, ...btnProps } = props;

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const {quoteAsync} = useQuoteContext();
  const quote = quoteAsync?.val;

  const abandonRevision = async () : Promise<Quote|undefined> => {
    if ( !quote ) return;

    try {

      quoteAsync.setLoading();
      const resp = await configurator.api.abandonQuoteApproval(quote.id );
      quoteAsync.setDone(resp.data);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      const msg = "Failed to abandon quote. " + errorMsg;

      notification.error( { message: msg });
      quoteAsync.setFail(e.message);
    }

    return;
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = async (e:any) => {
    await abandonRevision();
    props.onChange?.(quoteAsync?.val);
    setIsOpen(false);
  }

  return <>
    <Button
      {...btnProps} 
      onClick={() => setIsOpen(true)}
    >Abandon</Button>
    <Modal
      open={isOpen}
      okText="Confirm"
      onOk={handleClick}
      onCancel={() => setIsOpen(false)}
      confirmLoading={quoteAsync?.isLoading()}
      centered
    >
      <Result
        status="info"
        title="This will abandon changes and revert to the previous revision."
      />
    </Modal>
  </>
}


export const ReviseQuoteButton = (props:BMButtonProps) =>  {

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (e:any) => {
    props.onClick?.(e);
    setIsOpen(false);
  }

  return <>
    <BMButton
      {...props} 
      onClick={() => setIsOpen(true)}
    />
    <Modal
      open={isOpen}
      okText="Confirm"
      onOk={handleClick}
      onCancel={() => setIsOpen(false)}
      centered
    >
      <Result
        status="warning"
        title="Changes will require approval."
      />
    </Modal>
  </>
}



const SubmitSalesChangeButton = (props:Omit<BMButtonProps, 'onChange'> & {
  onChange?: (q:Quote) => void
  onValidate?: () => Promise<ValidateFields | undefined>
}) => {

  const { onChange:a, onValidate:b, ...btnProps } = props;

  const {quoteAsync} = useQuoteContext();
  const quote = quoteAsync?.val;
  const configurator = useContext(ConfiguratorContext);
  const [review, reviewAsync] = useAsyncState<QuoteReview>();
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [form] = useForm();
  const confirmSubmit = Form.useWatch<boolean>('confirmSubmit', form);

  const submit = async () : Promise<Quote|undefined> => {
    if (!quote) return;

    quoteAsync.setLoading();
    try {
      const resp = await configurator.api.submitOrder(quote.id);
      quoteAsync.setDone(resp.data);

      notification["success"]({
        message: "Submitted",
        description: "Your order has been submitted for approval",
      });

      return resp.data;
    } catch (e: any) {

      const errorMsg = intl.formatMessage({ id: e.message });
      const msg = "Failed to submit order. " + errorMsg
      notification.error( { message: msg, duration: 0 });
    }

    return;
  };


  const loadReview = async (quoteRevisionId:number | undefined) : Promise<QuoteReview | undefined> => {
    if ( !quoteRevisionId ) return;

    reviewAsync.setLoading();
    try {
      const resp = await configurator.api.fetchQuoteReview(quoteRevisionId, {dealerView: false});
      reviewAsync.setDone(resp.data);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch review. " + errorMsg });
      reviewAsync.setFail(e.message);
    }
    return;
  }

  const handleConfirmSubmit = async () => {

    const isValid = await props.onValidate?.();
    if ( !isValid ) return;

    await loadReview(quote?.displayRevisionId);

    setIsOpen( true );
  }

  const handleSubmit = async () => {

    const quote = await submit();
    if ( quote ) {
      props.onChange?.(quote);

      setIsOpen(false);
    }
  }
 
  return <>
    <BMButton
      type="primary"
      onClick={handleConfirmSubmit}
      {...btnProps}
      loading={props.loading || quoteAsync?.isLoading() || reviewAsync.isLoading()}
    >
      Submit Order
    </BMButton>
    <ModalWizard title="Order Submission"
      showSteps={false}
      centered
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      width={'50rem'}
      steps={[
        {
            key:1,
          body: (nav) => <Result status="warning" 
            title={"The following issues with the order have been identified."}
            subTitle={<Title level={5}>Continue to submit for approval?</Title> }
          >
            <QuoteReviewDetail 
              review={review} 
              ignoreDashDrawing={true} 
            />
          </Result>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" onClick={nav.nextStep} >Next</Button>
            <Button key="back" onClick={() => setIsOpen(false)} >Cancel</Button>
          </div>,
          hidden: !Utils.reviewHasErrors(review)
        },
        {
            key:2,
          body: (nav) => 
            <Spin spinning={quoteAsync?.isLoading()} key="modal-wizard-spin">
              <Result status="info">
              <Form form={form}>
                <Form.Item name="confirmSubmit" valuePropName="checked" style={{marginBottom: "0px"}}>
                  <Checkbox data-testid="confirmSubmitOrder" />
                </Form.Item>
                <div dangerouslySetInnerHTML={{ __html: OrderSubmissionLegalPhrase || '' }}></div>
              </Form>
              </Result>
            </Spin>,
            footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
              <Button key="next" type="primary" disabled={!confirmSubmit} onClick={handleSubmit} >Submit</Button>
              <Button key="back" onClick={nav.prevStep} >Back</Button>
            </div>,
        }
      ]}    
    />
  </>
}

export default SubmitSalesChangeButton;
